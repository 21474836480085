export default {
  t_welcome: "Bienvenue", //Login
  t_copyright: "Tous droits réservés ©️ 2023 ver3.1.0",
  t_copy: "Copier",
  t_userLogin: "Connexion Utilisateur",
  t_userName: "Nom d'utilisateur",
  t_password: "Mot de passe",
  t_login: "Connexion",
  t_hello: "Bonjour",
  t_usernameOrPasswordIsInvalid: "Nom d'utilisateur ou mot de passe invalide.",
  t_successfullyLoggedIn: "Connexion réussie.",
  t_pleaseSelectQueueAndClassification: "Veuillez sélectionner la file d'attente et la classification",
  t_PleaseSelectProjetAndQueues: "Veuillez sélectionner le projet et la file d'attente",
  t_pleaseSingIn: "Veuillez vous connecter",
  t_rememberMe: "Se souvenir de moi",
  t_signIn: "Connexion",
  t_changeStatusAfterTheCall: "Changer l'état après l'appel", //LoyoutContentRedererDefault.vue
  t_remainingTime: "Temps restant",
  t_continue: "Continuer",
  t_hold: "Mettre en attente",
  t_microphoneOn: "Microphone Activé",
  t_microphoneOff: "Microphone Désactivé",
  t_inputList: "Liste des touches",
  t_showInputs: "Afficher les saisies",
  t_chatPanel: "Panneau de chat",
  t_showDetail: "Afficher les détails",
  t_connected: "Connecté", // Navbar.vue
  t_disconnected: "Déconnecté",
  t_dayMode: "Mode Jour",
  t_nightMode: "Mode Nuit",
  t_agentList: "Liste des agents",
  t_logOut: "Déconnexion", // AgentTargets
  t_collectionTargeting: "Ciblage de collection",
  t_targeting: "Ciblage",
  t_dashboard: "Tableau de bord",
  t_search: "Rechercher",
  t_add: "Ajouter",
  t_targetedMonth: "Mois ciblé",
  t_confirm: "Confirmer",
  t_areYouSureYouWantToDeleteTheSelectedRecord: "Êtes-vous sûr de vouloir supprimer l'enregistrement sélectionné ?",
  t_areYouSureYouWantToUpdateTheSelectedRecords: "Êtes-vous sûr de vouloir mettre à jour les enregistrements sélectionnés ?",
  t_areYouSureYouWantToClearAll: "Êtes-vous sûr de vouloir tout effacer ?",
  t_selectTheAgent: "Sélectionnez l'agent",
  t_collectedDate: "Date de collecte",
  t_collectedTime: "Heure de collecte",
  t_edit: "Modifier",
  t_configuration: "CONFIGURATION",
  t_completed: "Terminé",
  t_remaining: "Restant",
  t_compilationRate: "Taux d'achèvement",
  t_minute: "Minute",
  t_targetCompletion: "Achèvement de l'objectif",
  t_hour: "Heure",
  t_day: "Jour",
  t_week: "Semaine",
  t_month: "Mois",
  t_year: "Année",
  t_weekday: "Jour de la semaine",
  t_lastOneHour: "Dernière 1 heure",
  t_lastThreeHours: "Dernières 3 heures",
  t_twoDaysAgo: "Il y a 2 jours",
  t_threeDaysAgo: "Il y a 3 jours",
  t_fourDaysAgo: "Il y a 4 jours",
  t_fiveDaysAgo: "Il y a 5 jours",
  t_sixDaysAgo: "Il y a 6 jours",
  t_period: "Période",
  t_thisWeek: "Cette semaine",
  t_lastWeek: "La semaine dernière",
  t_get: "Obtenir",
  t_saveFilters: "Sauvegarder les filtres",
  t_target: "Cible",
  t_announcements: "Annonces", // Announcements
  t_announcementName: "Nom de l'annonce",
  t_selectAudioFile: "Sélectionner un fichier audio",
  t_announcementList: "Liste des annonces",
  t_areYouSure: "Êtes-vous sûr ?",
  t_ok: "D'accord",
  t_count: "Quantité",
  t_CompletedAmount: "Montant terminé",
  t_dailyTarget: "Objectif quotidien",
  t_monthlyTarget: "Objectif mensuel",
  t_dailyTimeline: "Chronologie quotidienne",
  t_blackList: "Liste noire", // BlackListManagement
  t_descriptions: "Descriptions",
  t_inbound: "Entrant",
  t_outbound: "Sortant",
  t_number: "Numéro",
  t_callHistory: "Historique des appels", // CallHistory
  t_numberHistory: "Historique du numéro",
  t_notes: "Notes",
  t_oldNotes: "Anciennes notes",
  t_campaigns: "Campagnes", // Campaigns
  t_campaign: "Campagne",
  t_campaignName: "Nom de la campagne",
  t_campaignList: "Liste des campagnes",
  t_campaignhasBeenDeletedSuccessfully: "La campagne a été supprimée avec succès.",
  t_campaignhasBeenUpdatedSuccessfully: "La campagne a été mise à jour avec succès.",
  t_campaignhasBeenCreatedSuccessfully: "La campagne a été créée avec succès.",
  t_customTabs: "Onglets personnalisés", // CustomTabs
  t_address: "Adresse",
  t_landlinePrefix: "Préfixe ligne fixe",
  t_fctPrefix: "Préfixe FCT",
  t_customTabList: "Liste des onglets personnalisés",
  t_dataManagement: "Gestion des données", // DataUpload
  t_productManagement: "Gestion des produits",
  t_scriptManagement: "Gestion des scripts",
  t_exampleFile: "Exemple Excel",
  t_uploadFile: "Télécharger un fichier",
  t_SelectTheTitlesToUpdate: "Veuillez sélectionner les titres à mettre à jour",
  t_setUnmatchedRecordsToPassive: "Mettre les enregistrements non appariés en mode passif",
  t_setUnmatchedRecordsToUpdate: "Mettre à jour les téléphones et produits des enregistrements appariés",
  t_setAllPreviousInstitutionDataToPassive: "Mettre toutes les données de l'institution en mode passif",
  t_updateMatchedRecords: "Mettre à jour les enregistrements appariés",
  t_setAllRecordsOfProjectNameToPassive: "Mettre toutes les données du projet en mode passif",
  t_upload: "Téléverser",
  t_0customersHasBeenUpdatedSuccessfully: "0 client(s) ont été mis à jour avec succès.",
  t_customersHasBeenUpdatedSuccessfully: "clients mis à jour avec succès.",
  t_customersCouldNotBeUploadedBecauseThereHasBeenAnError: "clients n'ont pas pu être téléversés en raison d'une erreur.",
  t_pleaseSelectAProject: "Cliquez pour sélectionner un projet",
  t_dialPlan: "Plan d'appel", // DialPlan
  t_classifications: "Classifications",
  t_simulation: "Simulation",
  t_check: "Vérifier",
  t_order: "Ordre", //DIAL PLAN PRIORITY1
  t_callingPatterns: "Modèles d'appel",
  t_dialPattern: "Modèle de numérotation",
  t_simulatedCallCouldBePlacedFrom: "L'appel simulé pourrait être émis à partir de :",
  t_couldNotPlaceSimulationCall: "Appel simulé non effectué !",
  t_totalAgents: "Total", // Live Dashboard
  t_conference: "Conférence",
  t_callsInQueue: "Appels en file d'attente",
  t_abandonedCount: "Nombre d'abandons",
  t_hourlyAbandonedCount: "Nombre d'abandons horaires",
  t_statusLength: "Durée",
  t_massChangeStatuses: "Modification de statut en masse",
  t_textColour: "Couleur du texte",
  t_audioFile: "Fichier audio",
  t_type: "Type",
  t_confetti: "Confettis",
  t_champagne: "Champagne",
  t_emergency: "Urgence",
  t_blink: "Clignotant",
  t_wallboardControl: "Contrôle du tableau d'affichage",
  t_writtenSouffle: "Souffleur écrit",
  t_listen: "Écouter",
  t_clap1: "Applaudissements1",
  t_clap2: "Applaudissements2",
  t_warning: "Avertissement",
  t_changeAllStatuses: "Changer tous les statuts", // Home.vue
  t_showWentHome: "Afficher ceux qui sont rentrés chez eux", // Home.vue
  t_hideWentHome: "Cacher ceux qui sont rentrés chez eux", // Home.vue
  t_showCalledBack: "Afficher ceux qui ont été rappelés", // Home.vue
  t_reakTimeFraud: "Dépassement de pause", // Home.vue
  t_finishCodeCategory: "Catégorie", // FinishCodeManagement
  t_categoryName: "Nom de la catégorie",
  t_finishCodeCategorys: "Catégories de codes de fin",
  t_finishCodeName: "Nom affiché",
  t_finishCodes: "Codes de fin",
  t_askForActionDate: "Demander la date",
  t_saveToCalendar: "Enregistrer au calendrier",
  t_contactCallableAfterwards: "Contact rappelable",
  t_contactNumberCallableAfterwards: "Numéro rappelable",
  t_countAsCallAttempt: "Tentative de contact",
  t_lastFinishCode: "Dernier code de fin",
  t_deleteContactNumber: "Supprimer le numéro",
  t_assignToAgent: "Assigner à l'agent",
  t_inboundRoutes: "Routes entrantes", // InboundRoutes
  t_standardRoute: "Route standard",
  t_lineGroup: "Groupe de lignes", // LineGroup
  t_lineGroupName: "Nom du groupe de lignes",
  t_lineGroups: "Groupes de lignes",
  t_addedLines: "Lignes ajoutées",
  t_lineList: "Liste des lignes", // Lines
  t_importLines: "Importer des lignes",
  t_lineName: "Nom de la ligne",
  t_outboundCID: "Numéro de sortie",
  t_transferCID: "Numéro de transfert",
  t_hide_ofline_users: "Masquer les utilisateurs hors ligne",
  t_ip: "IP",
  t_transportType: "Type de transport",
  t_type: "Type",
  t_prefix: "Préfixe",
  t_inboundCallLimit: "Limite d'appel entrant",
  t_outboundCallLimit: "Limite d'appel sortant",
  t_port: "Port",
  t_codec: "Codec",
  t_fileUpload: "Téléchargement de fichier",
  t_file: "Fichier",
  t_selectTheFileYouWantToUpload: "Sélectionnez le fichier que vous souhaitez téléverser",
  t_pleaseEnterTheLineGroupName: "Veuillez saisir le nom du groupe de lignes.",
  t_youNeedToPickALineToBeAbleToCreateLineGroup: "Vous devez choisir au moins 1 ligne pour créer un groupe de lignes.",
  t_lineGroupHasBeenCreatedSuccessfully: "Le groupe de lignes a été créé avec succès.",
  t_campaignManagement: "Gestion des campagnes", // IvnDialer
  t_campaignControl: "Contrôle de la campagne",
  t_stop: "Arrêter",
  t_recycle: "Recycler",
  t_calls: "Appels",
  t_basic: "Basique",
  t_scheduledCalls: "Appels programmés",
  t_contactList: "Liste de contacts",
  t_programming: "Programmation",
  t_priority: "Priorité",
  t_maxCall: "Appel maximum",
  t_callsPerAgent: "Appels par agent",
  t_statisticsPeriod: "Période statistique (min)",
  t_callingMethod: "Méthode d'appel",
  t_noAnswerTimeout: "Délai de réponse (sec)",
  t_maxAbandonRate: "Taux d'abandon maximal (%)",
  t_dontConnectMachines: "Ne pas connecter les réponses automatiques",
  t_sortCallList: "Trier la liste d'appels",
  t_maxCycles: "Cycles maximum",
  t_recallDelayMins: "Délai de rappel (min)",
  t_afterCall: "Après l'appel",
  t_afterCallStatus: "Statut après appel",
  t_maxAttempts: "Max. Tentatives",
  t_callstatisticsfortoday: "Statistiques des appels d'aujourd'hui",
  t_statusdurationfortoday: "Durée des statuts aujourd'hui",
  t_maximumRateOfCallsThatAreAbandonedFromTheQueue: "Taux maximum des appels abandonnés dans la file d'attente",
  t_maximumAmountOfCallsThisCampaignCanStart: "Nombre maximum d'appels que cette campagne peut démarrer en même temps",
  t_callsPerAvailableAgents: "Appels par agent disponible",
  t_refreshRateOfThisCampaignsStatisticsGraphs: "Fréquence de rafraîchissement des graphiques de cette campagne",
  t_whichLineGroupToMakeCallsFrom: "Groupe de lignes à utiliser pour les appels de la campagne",
  t_maximumRingingTime: "Temps de sonnerie maximum",
  t_amountOfRoundsToCallPeopleWhoHaventBeenContactedYet: "Nombre de cycles pour rappeler les personnes non contactées",
  t_minimumAmountOfMinutesToRecallAPhoneNumber: "Temps minimum avant de rappeler un numéro",
  t_maximumCallAmountPerPhoneNumberPerDay: "Nombre maximum d'appels par numéro de téléphone par jour",
  t_maximumCallAmountPerContactPerDay: "Nombre maximum d'appels par contact par jour",
  t_maximumCallAmountPerPhoneNumber: "Nombre maximum d'appels par numéro de téléphone",
  t_maximumCallAmountPerContact: "Nombre maximum d'appels par contact",
  t_doNotConnectMachineAnsweredCalls: "Ne pas connecter les appels répondus par des machines",
  t_dailyMaxPhoneTouch: "Contact téléphonique quotidien maximum",
  t_dailyMaxContactTouch: "Contact quotidien maximum",
  t_totalMaxPhoneTouch: "Contact téléphonique total maximum",
  t_totalMaxContactTouch: "Contact total maximum",
  t_justCallMobilePhone: "Appeler uniquement les téléphones mobiles",
  t_abandoned: "Abandonné",
  t_timeCondition: "Condition de temps",
  t_transferIVR: "Transfert IVR",
  t_liveContacttIVR: "Contact en direct IVR",
  t_liveContact: "Contact en direct",
  t_noAvailableAgent: "Aucun agent disponible", // Dialer.vue
  t_noAvailableAgentIVR: "Aucun agent disponible IVR",
  t_rule: "Règle",
  t_campaignStatus: "Statut de la campagne",
  t_campaignPriority: "Priorité de la campagne",
  t_campaignFilterCriteria: "Critères de filtrage de la campagne",
  t_campaignSortingCriteria: "Critères de tri de la campagne",
  t_campaignRemainingCycles: "Cycles restants",
  t_campaignCallPerAgent: "Appels par agent",
  t_campaignTotalCustomersToCall: "Nombre total de clients à appeler",
  t_campaignTotalPhonesToCall: "Nombre total de téléphones à appeler",
  t_campaignRemainingPhonesInCycle: "Téléphones restants dans le cycle",
  t_campaignTotalCalled: "Total appelé",
  t_campaignTotalPhonesCalled: "Téléphones appelés en temps réel",
  t_campaignConnectedCall: "Appel connecté",
  t_campaignWaitingInQueue: "Appel en attente",
  t_campaignAbandonRate: "Taux d'abandon %",
  t_resultNoAnswer: "Pas de réponse (NoAnswer)",
  t_resultAbandon: "Abandon (Abandonné)",
  t_resultBusy: "Occupé (Busy)",
  t_resultCallRejected: "Appel rejeté (Rejeté par le client)",
  t_resultFaultyCall: "Appel défectueux",
  t_resultNetworkOutOfOrder: "Réseau hors service",
  t_resultFateUnknown: "Sort inconnu",
  t_resultConnectivityError: "Erreur de connectivité",
  t_resultFaultyNumber: "Numéro défectueux",
  t_finishCodeStatistics: "Statistiques des codes de fin",
  t_customersStatus: "Statut",
  t_assigned_agent: "Agent assigné",
  t_attempts: "Tentatives",
  t_dial_plan: "Plan de numérotation",
  t_phone_book: "Annuaire",
  t_ivr: "IVR",
  t_finish_code: "Code de fin",
  t_insert_date: "Date d'appel",
  t_action_date: "Date d'action",
  t_create_date: "Date de création",
  t_ex_agent: "Ex Agent",
  t_assign_date: "Date d'assignation",
  t_pleaseMakeASelectionForSorting: "Veuillez faire une sélection pour trier.",
  t_started: "Commencé",
  t_stopped: "Arrêté",
  t_recycling: "Recyclage",
  t_isCompleted: "Terminé",
  t_settingsHasBeenSavedSuccessfully: "Les paramètres ont été enregistrés avec succès.",
  t_columns: "Colonnes", //Main.vue
  t_row: "Ligne",
  t_sort: "Trier", //Main.vue
  t_algorithmicDialer: "Composeur automatique algorithmique", // List.vue
  t_basicDialer: "Composeur automatique basique", // List.vue
  t_filterManagement: "Gestion des filtres", // IvnDialerFilter
  t_noFiltersForThisCampaign: "Aucun filtre trouvé pour cette campagne.", // IvnDialerFilter > t_filterManagement > t_value
  t_condition: "Condition",
  t_equal: "Égal",
  t_notEqual: "Non égal",
  t_lessThan: "Inférieur à",
  t_greaterThan: "Supérieur à",
  t_lessThanOrEqual: "Inférieur ou égal",
  t_greaterThanOrEqual: "Supérieur ou égal",
  t_columnName: "Nom de colonne",
  t_columnNameStatus: "Statut",
  t_columnNameAssignedAgent: "Agent assigné",
  t_columnNameAttempts: "Tentatives",
  t_columnNameCampaign: "Campagne",
  t_columnNameFinishCode: "Code de fin",
  t_columnNameInsertDate: "Date d'appel",
  t_columnNameActionDate: "Date d'action",
  t_columnNameCreateDate: "Date de création",
  t_columnNameExAgent: "Ex Agent",
  t_filterHasBeenCreatedSuccessfully: "Le filtre a été créé avec succès.",
  t_IVR: "IVR", // IvrManagement
  t_IVRName: "Nom de l'IVR",
  t_IVRList: "Liste des IVR",
  t_DTMFdigit: "Saisie DTMF",
  t_announcementFile: "Fichier d'annonce",
  t_DTMFAccept: "Accepter la saisie DTMF",
  t_extension: "Extension",
  t_transfer: "Transfert",
  t_language: "Langue",
  t_timeOutLengthSec: "Durée du délai d'expiration (s)",
  t_timeOutCount: "Nombre de délais d'expiration",
  t_maxInvalidInputCount: "Nombre maximum de saisies incorrectes",
  t_acceptDTMFExtension: "Accepter les extensions DTMF",
  t_turkish: "Turc",
  t_english: "Anglais",
  t_arabic: "Arabe",
  t_russian: "Russe",
  t_hangup: "Raccrocher",
  t_hangupCall: "Rejeter l'appel",
  t_incomingCall: "Appel entrant",
  t_answer: "Répondre",
  t_menu: "Menu",
  t_matched: "Correspondant :",
  t_unmatched: "Non correspondant :",
  t_hasBeenDeletedSuccessfully: "A été supprimé avec succès.",
  t_pleaseDoNotLeaveAnyBlankFields: "Veuillez ne pas laisser de champs vides.",
  t_hasBeenUpdatedSuccessfully: "Mise à jour réussie.",
  t_hasBeenCreatedSuccessfully: "Création réussie.",
  t_pleaseSelectTheBlackListDirection: "Veuillez sélectionner la direction pour la liste noire.", // blacklist
  t_phoneBook: "Annuaire", // PhoneBook
  t_description: "Description",
  t_profiles: "Profils", // ProfileQueueManagement
  t_profileName: "Nom du profil",
  t_affectedObjects: "Objets affectés",
  t_permissions: "Autorisations",
  t_user: "Utilisateur",
  t_reset: "Réinitialiser",
  t_noPermissionsSelected: "Aucune autorisation sélectionnée.",
  t_profileList: "Liste des profils",
  t_chat: "Messagerie",
  t_souffle: "Souffle",
  t_profileHasBeenDeletedSuccessfully: "Le profil a été supprimé avec succès.",
  t_profileHasBeenUpdatedSuccessfully: "Le profil a été mis à jour avec succès.",
  t_profileHasBeenCreatedSuccessfully: "Le profil a été créé avec succès.",
  t_is_visible_in_live_dasboard: "Afficher dans le tableau de bord",
  t_projectName: "Nom du projet", // Projects
  t_afterCallWork: "Temps après l'appel (s)",
  t_cameraRecord: "Enregistrement caméra",
  t_useableBreakTime: "Temps de pause utilisable (min)",
  t_breakTime: "Temps de pause",
  t_surname: "Nom de famille",
  t_nameSurname: "Prénom et Nom",
  t_projectList: "Liste des projets",
  t_projectHasBeenDeletedSuccessfully: "Le projet a été supprimé avec succès.",
  t_projectHasBeenUpdatedSuccessfully: "Le projet a été mis à jour avec succès.",
  t_projectHasBeenCreatedSuccessfully: "Le projet a été créé avec succès.",
  t_queueNumber: "Numéro de file", // Queues - List.vue
  t_queueAgentTimeout: "Délai d'attente de l'agent (s)",
  t_queueAgentTimeoutPlaceholder: "Durée maximale pendant laquelle l'appel attendra chez l'agent",
  t_queueAnnounceFrequency: "Fréquence des annonces (s)",
  t_timeBetweenAnnouncements: "Temps entre les annonces",
  t_queueAnnouncePosition: "Annonce de position",
  t_queueMaxlen: "Longueur maximale",
  t_queuePenaltyMembersLimit: "Limite des membres pénalisés",
  t_queueRetry: "Réessayer",
  t_queueMusicOnHold: "Musique en attente",
  t_queueName: "Nom de la file",
  t_queueCallingMode: "Mode d'appel",
  t_queueStrategy: "Stratégie de distribution",
  t_queueStrategyPlaceholder: "Stratégie de distribution des appels entre les agents",
  t_queueWrapupTime: "Temps de finition (s)",
  t_queueWrapupTimePlaceholder: "Temps minimum après l'appel où les agents seront sans appel",
  t_queueAnnounceHoldTime: "Annonce du temps d'attente",
  t_queueQueueHoldTime: "Temps d'attente automatique",
  t_queueMemberDelay: "Délai pour les membres",
  t_queuePeriodicAnnounceFrequency: "Fréquence des annonces périodiques",
  t_queueServiceLevel: "Niveau de service",
  t_queueWeight: "Poids",
  t_queueList: "Liste des files",
  t_queueRrmemory: "Séquentiel (Recommandé)",
  t_queueRingall: "Appeler tout le monde simultanément",
  t_queueLeastrecent: "Appeler ceux qui ont été appelés le moins récemment",
  t_queueAgentWeight: "Priorité de l'agent",
  t_queueFewestcalls: "Appeler ceux qui ont eu le moins d'appels",
  t_queueRandom: "Aléatoire",
  t_queueManual: "Manuel",
  t_queueDialer: "Composeur",
  t_serviceManagement: "Gestion des services", // ServiceManagement
  t_dialerService: "Service de numérotation automatique",
  t_restart: "Redémarrer",
  t_callManager: "Service de gestion des appels",
  t_chatService: "Service de messagerie",
  t_smsSending: "Envoi de SMS", //SMS
  t_smsTemplate: "Modèle SMS",
  t_smsFilter: "Filtre SMS",
  t_filterName: "Nom du filtre", // SmsFilter
  t_noFilterHasBeenSelected: "Aucun filtre sélectionné.",
  t_filterList: "Liste des filtres",
  t_smsPageTitle: "Titre de la page", // SmsSender
  t_sendFromVoyceCRM: "Envoyer depuis VoyceCRM",
  t_sendFromExternalCRM: "Envoyer depuis un CRM externe",
  t_sendFromCustomSms: "Envoi personnalisé",
  t_smsPattern: "Modèle de message",
  t_preview: "Aperçu",
  t_exampleText: "Exemple de message",
  t_phoneNumbers: "Numéros de téléphone",
  t_enterPhoneNumbersSeparatedByCommas: "Entrez les numéros de téléphone séparés par des virgules.",
  t_enterEmailsSeparatedByCommas: "Entrez les adresses e-mail séparées par des virgules.",
  t_pleaseEnterAtLeastOnePhoneNumber: "Veuillez entrer au moins un numéro de téléphone.",
  t_pleaseEnterAMessage: "Veuillez entrer un message.",
  t_messagesHaveBeenDeliveredSuccessfully: "Les messages ont été envoyés avec succès.",
  t_thereWasAProblemDeliveringYourMessages: "Il y a eu un problème lors de la livraison de vos messages.",
  t_displayName: "Nom affiché", // SmsSettings
  t_senderNumber: "Numéro de l'expéditeur",
  t_accountCode: "Code de compte",
  t_apiKey: "Clé API",
  t_smsSettingList: "Liste des paramètres SMS",
  t_pattern: "Modèle",
  t_patternName: "Nom du modèle",
  t_smsText: "Texte du message",
  t_patternList: "Liste des modèles",
  t_callCenter: "Centre d'appels", // Statistics
  t_statistics: "Statistiques",
  t_missedCall: "Appel manqué",
  t_lunch: "Déjeuner",
  t_break: "Pause",
  t_AVGInboundLengt: "Durée moyenne des appels entrants",
  t_AVGOutboundLengt: "Durée moyenne des appels sortants",
  t_totalInbound: "Total entrants",
  t_totalOutbound: "Total sortants",
  t_remainingBreakTime: "Temps de pause restant",
  t_statusTime: "Temps de statut",
  t_totalActionCounts: "Nombre total d'actions",
  t_waitingInQueue: "En attente dans la file",
  t_numberofwaitingininstantqueue: "Nombre en attente dans la file instantanée",
  t_allcallrecordsfortoday: "Tous les enregistrements d'appels d'aujourd'hui",
  t_collectionTarget: "Objectif de collecte",
  t_collectedMade: "Collecté",
  t_workingDay: "Jour de travail",
  t_present: "Présent",
  t_absent: "Absent",
  t_actionDate: "Date d'action",
  t_inCallOutbound: "En appel (Sortant)",
  t_inCallInbound: "En appel (Entrant)",
  t_ringing: "Sonnerie",
  t_noAnswer: "Pas de réponse",
  t_promiseToPay: "Promesse de paiement",
  t_refuseToPay: "Refus de paiement",
  t_messageLeft: "Message laissé",
  t_busy: "Occupé",
  t_statusCategory: "Catégorie de statut", // StatusManagement
  t_statusCategorys: "Catégories de statut",
  t_statusName: "Nom du statut",
  t_categoryKey: "Clé de la catégorie",
  t_categories: "Catégories",
  t_changeable: "Modifiable",
  t_onlySPVCanChange: "Seul le superviseur peut changer",
  t_active: "Actif",
  t_statusList: "Liste des statuts",
  t_online: "En ligne",
  t_offline: "Hors ligne",
  t_other: "Autre",
  t_important: "Important",
  t_apointment: "Rendez-vous",
  t_reminder: "Rappel",
  t_personal: "Personnel",
  t_personaly: "Personnel",
  t_timeConditionName: "Nom de la condition de temps", // TimeConditionManagement - List.vue
  t_startDay: "Jour de début",
  t_startTime: "Heure de début",
  t_startingDayOfMonth: "Jour de début du mois",
  t_monthStart: "Début du mois",
  t_monthStartDay: "Jour de début du mois",
  t_monthEndDay: "Jour de fin du mois",
  t_monthEnd: "Fin du mois",
  t_endDay: "Jour de fin",
  t_endTime: "Heure de fin",
  t_endingDayOfMonth: "Jour de fin du mois",
  t_timeConditions: "Conditions de temps",
  t_monday: "Lundi",
  t_tuesday: "Mardi",
  t_wednesday: "Mercredi",
  t_thursday: "Jeudi",
  t_friday: "Vendredi",
  t_saturday: "Samedi",
  t_sunday: "Dimanche",
  t_january: "Janvier",
  t_february: "Février",
  t_march: "Mars",
  t_april: "Avril",
  t_may: "Mai",
  t_june: "Juin",
  t_july: "Juillet",
  t_august: "Août",
  t_september: "Septembre",
  t_october: "Octobre",
  t_november: "Novembre",
  t_december: "Décembre",
  t_theBeginingConditionCanNotBeGraterThanEndingCondition: "La condition de début ne peut pas être supérieure à la condition de fin.",
  t_userInformations: "Informations personnelles",
  t_import: "Importer", // UsersManagement
  t_importUsers: "Importer des utilisateurs", // UsersManagement
  t_importRecords: "Importer des enregistrements", // UsersManagement
  t_userList: "Liste des utilisateurs",
  t_tenantName: "Nom du locataire",
  t_recordingExport: "Exportation des enregistrements", // VoiceExport.vue
  t_recordingRemove: "Suppression des enregistrements", // VoiceExport.vue
  t_filters: "Filtres",
  t_tags: "Étiquettes",
  t_nameType: "Format du nom", // VoiceExport.vue
  t_youCanAddCommaBetweenEmailAddressesToAddMore: "Vous pouvez ajouter plusieurs adresses e-mail en les séparant par des virgules.",
  t_youCanAddCommaBetweenPhoneToAddMore: "Vous pouvez ajouter plusieurs numéros de téléphone en les séparant par des virgules.",
  t_startDate: "Date de début",
  t_endDate: "Date de fin",
  t_namePattern: "Format du nom",
  t_targetMail: "E-mail cible",
  t_whenTheFilesAreReadyYouWillbeSentAnEMail: "Les fichiers seront envoyés à votre adresse e-mail une fois prêts.",
  t_fileType: "Format de fichier",
  t_theProcessHasBeenStartedItWillBeSentByEmailWhenItIsCompleted: "Le processus a commencé, il vous sera envoyé par e-mail une fois terminé.",
  t_thereWasAProblemStartingTheProcess: "Il y a eu un problème lors du démarrage du processus.",
  t_process: "Processus", // VSPR
  t_deactiveCustomer: "Désactiver le client",
  t_activeCustomer: "Activer le client",
  t_assigneManager: "Assigner un gestionnaire",
  t_conclude: "Conclure",
  t_addingNote: "Ajouter une note",
  t_deleteCallRecord: "Supprimer l'enregistrement d'appel",
  t_matchingColumn: "Colonne correspondante", // components - VSPR
  t_pleaseFillTheAllNecessaryAreas: "Veuillez remplir tous les champs nécessaires.",
  t_thereWasAProblemWhileAssigning: "Il y a eu un problème lors de l'assignation.",
  t_doesNotMatchAnyCustomers: "ne correspond à aucun client.",
  t_matchingColumnMustBeTheSameInTheExcelColumn: "Le nom de la colonne correspondante doit être identique à celui de la colonne dans le fichier Excel.",
  t_AProblemOccurredwhileProcessing: "Un problème est survenu lors du traitement.",
  t_customersHaveBeenSuccessfullyUpdated: "enregistrements mis à jour avec succès.",
  t_customersHaveBeenSuccessfullyDeleted: "Enregistrement supprimé avec succès.",
  t_processSuccess: "Processus réussi",
  t_assigne: "Assigner", // AssignManager
  t_manager: "Gestionnaire",
  t_thereWasAProblemWhileAssigningManager: "Un problème est survenu lors de l'assignation du gestionnaire.",
  t_managerHaveSuccessfullyBeenAssigned: "Le gestionnaire a été assigné avec succès.",
  t_deactiveCustomersHaveSuccessfullyBeenDone: "fermetures effectuées avec succès.", // CloseCustomer
  t_VSPRCloseCustomer: "Fermer le client",
  t_VSPROpenCustomer: "Ouvrir le client",
  t_aProblemOccurredWhileConcluding: "Un problème est survenu lors de la conclusion.",
  t_customersHaveSuccessfullyBeenConcluded: "conclusions effectuées avec succès.",
  t_thereWasAProblemActivatingCustomers: "Un problème est survenu lors de l'activation des données.",
  t_customersHaveBeenSuccessfullyActivated: "données activées avec succès.",
  t_deleteCallRecordings: "Supprimer les enregistrements d'appel",
  t_fileHasBeenImportedSuccessfully: "Le fichier a été importé avec succès.",
  t_makeAnAnnouncement: "Faire une annonce", // Chat
  t_sendASouffle: "Envoyer une instruction",
  t_startChat: "Démarrer une conversation",
  t_open: "Ouvrir",
  t_conversations: "Conversations",
  t_announcementHistory: "Historique des annonces",
  t_totalCallTime: "Durée totale des appels",
  t_avgCallTime: "Durée moyenne des appels",
  t_avgAnswerTime: "Temps moyen de réponse",
  t_avgHoldTime: "Durée moyenne de mise en attente",
  t_avgAcwTime: "Durée moyenne de l'ACW",
  t_totalCallTimeDescrition: "Indicateur de performance (KPI)",
  t_avgCallTimeDescrition: "Indicateur de performance (KPI)",
  t_avgAnswerTimeDescrition: "Indicateur de performance (KPI)",
  t_avgHoldTimeDescrition: "Indicateur de performance (KPI)",
  t_avgAcwTimeDescrition: "Indicateur de performance (KPI)",
  t_noRecordOnQueue: "Aucun appel en attente dans la file d'attente",
  t_callRecords: "Enregistrements des appels",
  t_secondLine: "Deuxième ligne",
  t_changeMode: "Changer l'affichage",
  t_showNotConnected: "Afficher ceux qui ne sont pas connectés",
  t_fullScreen: "Plein écran",
  t_announcementPanel: "Panneau d'annonces",
  t_yourMessage: "Votre message",
  t_startAconversation: "Démarrer une conversation",
  t_youGotAPrivateMessage: "vous a envoyé un message privé.",
  t_startANewConversation: "Démarrer une nouvelle conversation",
  t_updatePassword: "Mettre à jour le mot de passe", // Navbar
  t_PasswordCondition: "Votre mot de passe doit comporter au moins 6 caractères, dont 1 lettre et 1 chiffre.",
  t_lastAccountEvent: "Dernière activité du compte",
  t_events: "Événements",
  t_changePassword: "Changer le mot de passe",
  t_again: "Encore une fois",
  t_currentPassword: "Mot de passe actuel",
  t_newPassword: "Nouveau mot de passe",
  t_confirmPassword: "Confirmer le nouveau mot de passe",
  t_pleaseSelectfinishCode: "Veuillez sélectionner un code de fin",
  t_survey: "Enquête",
  t_passwordmustContainAtLeast1LetterAnd1Number: "Le mot de passe doit contenir au moins 1 lettre et 1 chiffre.",
  t_enteredpasswordsDoNotMatch: "Les mots de passe saisis ne correspondent pas.",
  t_passwordMustBeAtLeast6CharactersLong: "Le mot de passe doit comporter au moins 6 caractères.",
  t_YourPasswordCannotBeTheSameAsYourOldPassword: "Votre mot de passe ne peut pas être identique à votre ancien mot de passe.",
  t_PasswordHasBeenchangedSuccessfully: "Le mot de passe a été changé avec succès.",
  t_youHaveReconnectedToTheService: "Vous êtes reconnecté au service.",
  t_yourServiceConnectionHasBeenLost: "Votre connexion au service a été perdue.",
  t_ACWNothingSelected: "Aucune action sélectionnée",
  t_ACWTimeOut: "Temps d'attente de l'ACW",
  t_rdialerconnectedtoagent: "Appel automatique connecté à l'agent",
  t_rdialerabandon: "Appel automatique abandonné",
  t_color: "Couleur",
  t_view: "Affichage",
  t_allDays: "Tous les jours",
  t_addEvent: "Ajouter un événement",
  t_eventName: "Nom de l'événement",
  t_eventNote: "Note de l'événement",
  t_eventDate: "Date de l'événement",
  t_eventTime: "Heure de l'événement",
  t_displayOnCRM: "Afficher sur le CRM",
  t_monthly: "Mensuel",
  t_weekly: "Hebdomadaire",
  t_daily: "Quotidien",
  t_hourly: "Horaire",
  t_mon: "Lun",
  t_tue: "Mar",
  t_wed: "Mer",
  t_thu: "Jeu",
  t_fri: "Ven",
  t_sat: "Sam",
  t_sun: "Dim",
  t_monSat: "Lun - Sam",
  t_sunSat: "Dim - Sam",
  t_monFri: "Lun - Ven",
  t_monWedFri: "Lun, Mer, Ven",
  t_eventhasBeenCreatedSuccessfully: "L'événement a été créé avec succès.",
  t_avaragequalitypoint: "Point de qualité moyen",
  t_liveDashboard: "Tableau de bord en direct", // Menu
  t_mAlerts: "Déclencheurs",
  t_systemManagement: "Gestion du système",
  t_operationManagement: "Gestion des opérations",
  t_integrations: "Intégrations",
  t_eMail: "E-mail",
  t_qVoiceMail: "Messagerie vocale",
  t_lastLogin: "Dernière connexion",
  t_speechRule: "Règle de discours",
  t_addSpeechRule: "Ajouter une règle de discours",
  t_lines: "Lignes",
  t_routes: "Routages", // System Management -> Main
  t_vIVR: "IVR variable",
  t_backup: "Sauvegarde",
  t_providers: "Fournisseurs",
  t_mVoice: "Voix",
  t_reachedCalls: "Appels atteints",
  t_unreachedCalls: "Appels non atteints",
  t_missedCalls: "Appels manqués",
  t_outcomingCall: "Appel sortant",
  t_inboundCall: "Appel entrant",
  t_incomingCallAvg: "Durée moyenne des appels entrants",
  t_outgoingCallAvg: "Durée moyenne des appels sortants",
  t_show: "Afficher",
  t_entries: "enregistrements",
  t_addRecord: "Ajouter un enregistrement",
  t_showing: "Affichage",
  t_title: "Titre",
  t_clear: "Effacer",
  t_perPage: "Par page",
  t_uploadFileOnClick: "Cliquez pour télécharger un fichier",
  t_delete: "Supprimer",
  t_noEditedRecord: "Aucun enregistrement à éditer.",
  t_noRecord: "Aucun enregistrement à afficher",
  t_errorFetchData: "Problème lors de la récupération des données.",
  t_backToList: "Retour à la liste",
  t_deleteRecord: "Supprimer l'enregistrement",
  t_updateRecord: "Mettre à jour l'enregistrement",
  t_titles: "Titres",
  t_searchPlaceHolder: "Recherchez par nom, numéro de client, numéro de produit, carte d'identité ou téléphone",
  t_customerId: "ID du client",
  t_breakStatus: "Statut de la pause",
  t_targetStatus: "Statut de la cible",
  t_mrAgentAssign: "Assignation d'agent",
  t_dataUpload: "Téléchargement de données",
  t_customerList: "Liste des clients",
  t_customerListByPhone: "Liste des clients par téléphone",
  t_customerListByProduct: "Liste des clients par produit",
  t_customerListByCSV: "Liste des clients par CSV",
  t_vehicle: "Véhicule",
  t_landRegistry: "Registre foncier",
  t_SSK: "SSK",
  t_mernis: "Mernis",
  t_gsmQuery: "Requête GSM",
  t_negotiation: "Négociation",
  t_products: "Produits",
  t_phones: "Téléphones",
  t_hourlyGraphic: "Graphique horaire",
  t_deleteFilter: "Supprimer le filtre",
  t_addFilter: "Ajouter un filtre",
  t_clearFilter: "Effacer le filtre",
  t_addNewRecord: "Ajouter un nouvel enregistrement",
  t_selectableLines: "Lignes sélectionnables",
  t_selectedLines: "Lignes sélectionnées",
  t_restartService: "Êtes-vous sûr de vouloir redémarrer le service ?",
  t_restartServiceSuccessfully: "Le service a été redémarré avec succès.",
  t_id: "ID",
  t_goal: "Objectif",
  t_goalManagementDescription: "Affichage du graphique",
  t_smscount: "messages seront envoyés. Confirmez-vous ?",
  t_call: "Appeler",
  t_clearFilters: "Effacer les filtres", // CRM - Main.vue
  t_management: "Gestion",
  t_customerInformation: "Informations client",
  t_customerUpdate: "Mettre à jour les informations du client",
  t_extraInformation: "Informations supplémentaires",
  t_hide: "Cacher",
  t_addAction: "Ajouter une action",
  t_addPhone: "Ajouter un téléphone",
  t_addNote: "Ajouter une note",
  t_landLine: "Ligne fixe",
  t_addFinishCode: "Ajouter un code de fin",
  t_addProductAction: "Ajouter une action produit",
  t_actionDate2: "Date de l'action",
  t_installmentCount: "Nombre de versements",
  t_installmentAmount: "Montant des versements",
  t_relationTo: "Propriétaire",
  t_isApproved: "Approuvé",
  t_addData: "Ajouter des données",
  t_sendSMS: "Envoyer un SMS",
  t_sendEmail: "Envoyer un e-mail",
  t_filterTheResults: "Filtrer les résultats",
  t_filterBy: "Filtrer par",
  t_pleaseWriteSomethingToSeeYourOptions: "Veuillez écrire quelque chose pour voir vos options.",
  t_columnVisibilityOptions: "Options de visibilité des colonnes",
  t_showHide: "(Afficher/Cacher)",
  t_agentAssign: "Assignation d'agent",
  t_assigmnentType: "Type d'assignation",
  t_contactPool: "Pool de contacts",
  t_sender: "Expéditeur",
  t_shuffle: "Mélanger",
  t_assignForProductAgent: "Assigner pour agent produit",
  t_progresingDate: "Date de traitement",
  t_allUnassignedCasesWillBeAssignedToSelectedAgentsAreYouSure: "Tous les dossiers non assignés seront assignés aux agents sélectionnés. Confirmez-vous ?",
  t_amountOfCasesWillBeUpdatedAreYouSure: "modifications seront effectuées. Confirmez-vous ?",
  t_casesCouldNotBeAssigned: "problèmes rencontrés lors de l'assignation.",
  t_customersHaveSuccessfullyBeenAssigned: "assignations effectuées avec succès.",
  t_error: "Erreur",
  t_failed: "Échec",
  t_reportDetails: "Détails du rapport", // Reports.vue
  t_summary: "Résumé",
  t_today: "Aujourd'hui", // Reports.vue
  t_yesterday: "Hier", // Reports.vue
  t_last7Days: "Les 7 derniers jours", // Reports.vue
  t_last30Days: "Les 30 derniers jours", // Reports.vue
  t_betweenDates: "Entre les dates", // Reports.vue
  t_reports: 'Rapports', //Main.vue
  t_reportType: 'Type de rapport',
  t_callDetails: "Détails de l'appel", // Report Names
  t_mrInboundCallDetails: "Détails des appels entrants",
  t_mrSL30Daily: "SL30 Journalier",
  t_mrSL30Monthly: "SL30 Mensuel",
  t_mrSL30Hourly: "SL30 Horaire",
  t_mrAbandonedCalls: "Appels abandonnés",
  t_mrAfterWorkInboundCalls: "Appels entrants après les heures de travail",
  t_mrTotalCalls: "Nombre total d'appels",
  t_extraTime: "Temps supplémentaire",
  t_giveExtraTime: "Donner du temps supplémentaire",
  t_time: "Durée (00:00:00)",
  t_mrCallDurationDetails: "Détails de la durée des appels",
  t_mrCallDurationSummary: "Résumé de la durée des appels",
  t_mrInboundCallDaily: "Répartition quotidienne des appels",
  t_mrInboundCallHourly: "Répartition horaire des appels",
  t_surveyDetails: "Détails de l'enquête",
  t_mrAgent: "Agent",
  t_mrAgentActivity: "Résumé de l'activité de l'agent",
  t_mrAgentCallCountsDaily: "Nombre d'appels par agent quotidien",
  t_mrAgentCallCountsByDate: "Nombre d'appels par agent par date",
  t_mrAgentWork: "Travail de l'agent",
  t_mrAgentCalls: "Appels de l'agent",
  t_mrAgentDaily: "Répartition quotidienne des appels par agent",
  t_mrAgentHourly: "Répartition horaire des appels par agent",
  t_mrAgentCalendar: "Détails du calendrier",
  t_mrIvnDialerCallAnalyze: "Analyse de la campagne",
  t_mrIvnDialerCallDetails: "Détails des appels de la campagne",
  t_called: "Appelé", // Dashboard.vue
  t_closed: "Fermé",
  t_backedupFiles: "Fichiers sauvegardés", // Backup.vue
  t_pleaseSelectTheFileToUpload: "Veuillez sélectionner le fichier à télécharger",
  t_createBackup: "Créer une sauvegarde",
  t_exportToDatabase: "Exporter vers la base de données",
  t_filter: "Filtrer", // GLOBAL
  t_settings: 'Paramètres',
  t_action: "Action",
  t_note: "Note",
  t_column: "Colonne",
  t_value: "Valeur",
  t_operator: "Opérateur",
  t_users: "Utilisateurs",
  t_agents: "Agents",
  t_date: "Date",
  t_agent: "Agent", // Home.vue
  t_finishCode: "Code de fin",
  t_direction: "Direction",
  t_defaultRoute: "Route par défaut",
  t_editSelected: "Modifier la sélection",
  t_crm: 'CRM',
  t_calendar: 'Calendrier',
  t_dialer: "Composeur automatique",
  t_goalManagement: 'Gestion des objectifs',
  t_line: 'Ligne',
  t_dialPlans: 'Plans de numérotation',
  t_queues: "Files d'attente",
  t_projects: 'Projets',
  t_statuses: 'Statuts',
  t_announce: 'Annonce',
  t_createScript: 'Créer un script de conversation',
  t_queueTimeOut: 'Délai d’expiration de la file d’attente',
  t_detinationType: 'Type de destination',
  t_detinationTarget: 'Cible',
  t_showInDashboard: 'Afficher dans le tableau de bord',
  t_reject: 'Rejeter',
  t_internalCall: 'Appel interne',
  t_isProductFinishCode: 'Code de fin de produit',
  t_queueGroup: 'Groupe de files d’attente', //Home.vue
  t_agentGroup: 'Groupe d’agents', //Home.vue
  t_selectableQueues: "Files d'attente sélectionnables",
  t_selectedQueues: "Files d'attente sélectionnées",
  t_isHoldSound: 'Musique d’attente',
  t_waitTime: 'Temps d’attente',
  t_isReached: 'Appel répondu',
  t_addCustomer: 'Ajouter un client',
  t_phonesUpdate: "Mettre à jour le téléphone",
  t_phonesinfoUpdate: "Mettre à jour les informations du téléphone",
  t_isAutoEmail: 'Envoyer un e-mail automatique',
  t_emailTemplate: 'Modèle d’e-mail',
  t_account: 'Compte',
  t_security: 'Sécurité',
  t_performance: 'Performance',
  t_emailDelayAmount: 'Délai (minutes)',
  t_isAutoSMS: 'Envoyer un SMS automatique',
  t_smsDelayAmount: 'Délai (minutes)',
  t_autoAnswer: 'Réponse automatique',
  t_isUnassign: 'Supprimer l’assignation',
  t_isPassive: 'Mettre en veille',
  t_setAttemptsByPhone: 'Définir les tentatives en fonction de l’historique du téléphone',
  t_recordingFile: 'Enregistrement',
  t_callStrategy: 'Stratégie d’appel',
  t_sortCriteria: 'Critères de tri',
  t_internationalCall: 'Appel international',
  t_dialerPrecall: 'Préappel du composeur',
  t_variable: 'Variable',
  t_variableLength: 'Longueur de la variable',
  t_reportFiles: 'Fichiers de rapport', // Main.vue
  t_schedule: 'Planifier',
  t_scheduler: 'Planificateur',
  t_schedulerTasks: 'Tâches planifiées',
  t_schedulerCategory: 'Catégorie de planification',
  t_reportCategory: 'Catégorie de rapport',
  t_lastXDays: 'X derniers jours',
  t_communicationType: 'Type de communication',
  t_communicationSrc: 'Source de communication',
  t_communicationTitle: 'Titre',
  t_to: 'Destinataire (À)',
  t_cc: 'Copie carbone (CC)',
  t_youCanAddCommaBetweenEmailAddressesToaddMore: "Vous pouvez ajouter plusieurs adresses e-mail en les séparant par des virgules.",
  t_email: 'E-mail',
  t_mobileNotification: 'Notification mobile',
  t_callPlaced: 'Appel en cours',
  t_callWentToAgent: 'En conversation',
  t_callJoinedQueue: 'En file d’attente',
  t_callPickedUp: 'Répondu',
  t_callRejected: 'Rejeté',
  t_schedulerTime: 'Heure de planification',
  t_schedulerTimeString: 'Description de l’heure de planification',
  t_creator: 'Créateur',
  t_nextRunTime: 'Prochaine exécution',
  t_externalChat: 'Canal écrit',
  t_start: "Démarrer", // IvnDialer
  t_selectAll: "Tout sélectionner", // CRM - Main.vue - Home.vue
  t_clearAll: "Tout effacer", // Main.vue
  t_queue: "File d'attente", // Home.vue - Reports.Vue
  t_announcement: "Annonce", //ProfileQueueManagement - Home.vue
  t_duration: "Durée", //Statistics - Home.vue
  t_productivity: "Productivité", //Statistics - Home.vue
  t_status: "Statut", // Home.vue
  t_usedBreakTime: "Pause", //Statistics - Home.vue
  t_answered: "Répondu",  // Statistic.vue - Wallboard.vue - Home.vue
  t_successfullCall: "Réussi", //Statistics - Home.vue - Dialer.vue
  t_unsuccessfullCall: "Échoué", //Statistics - Home.vue - Dialer.vue
  t_export: "Exporter", // Home.vue - List.vue - Reports.vue
  t_total: "Total", // Dialer.vue
  t_rate: "Taux (%)", // Statistic.vue - Home.vue - Dialer.vue
  t_send: "Envoyer", //SmsFilter
  t_next: 'Suivant',
  t_previous: 'Précédent',
  t_classification: "Classification", // List.vue - ListAddNew.vue
  t_available: "Disponible", // Wallboard.vue - Home.vue / Dialer.vue
  t_inCall: "En appel", // Wallboard.vue - Home.vue
  t_paused: "En pause",  // Wallboard.vue - Home.vue
  t_profile: "Profil",
  t_no: "Non",
  t_yes: "Oui",
  t_report: 'Rapport',
  t_actions: "Actions", // All
  t_cancel: "Annuler", // All
  t_save: "Sauvegarder", // All
  t_category: "Catégorie", // All
  t_project: "Projet", // All
  t_close: "Fermer", // All
  t_name: "Nom", // All
  t_sms: 'SMS', // All
  t_general: 'Général',
  t_loginPageImages: 'Images de la page de connexion',
  t_browse: 'Parcourir',
  t_thisMonth: 'Ce mois-ci',
  t_lastMonth: 'Le mois dernier',
  t_yourReportAddToReportQueue: 'Votre rapport a été ajouté à la file de rapports',
  t_notification: 'Notification',
  t_notifications: 'Notifications',
  t_pleaseSelectReport: 'Veuillez sélectionner un rapport',
  t_host: 'Serveur',
  t_subject: 'Objet',
  t_isSendMailToCustomer: 'Envoyer un e-mail au client',
  t_surveyCancel: 'Annuler l’enquête',
  t_surveyApprove: 'Approuver l’enquête',
  t_sendType: 'Type d’envoi',
  t_info: 'Information',
  t_pleaseSelectTimeCondition: 'Veuillez sélectionner une condition temporelle',
  t_crmWhiteColorInfo: 'Non touché',
  t_crmInfoColorInfo: 'Touché et appelable',
  t_crmWarningColorInfo: 'Touché et non appelable',
  t_crmSecondaryColorInfo: 'Touché et fermé',
  t_crmDangerColorInfo: 'Non touché et fermé',
  t_nextCustomer: 'Client suivant',
  t_lineChangeInfo: 'Veuillez enregistrer à nouveau vos campagnes utilisant le groupe de lignes concerné.',
  t_deleteData: 'Supprimer les données',
  t_closedByDailyAttempts: 'Fermé par tentatives quotidiennes',
  t_closedByTotalAttempts: 'Fermé par tentatives totales',
  t_resetScheduledCalls: 'Réinitialiser les appels programmés',
  t_resetDailyAttempts: 'Réinitialiser les fermetures par tentatives quotidiennes',
  t_resetTotalAttempts: 'Réinitialiser les fermetures par tentatives totales',
  t_detail: "Détail",
  t_spokenCall: 'Appel parlé',
  t_spokenCallRate: 'Taux de conversation',
  t_nonSpokenCall: 'Appel non parlé',
  t_nAttemptsCall: 'Sans tentative',
  t_attemptsRate: 'Taux de tentative',
  t_reachRate: 'Taux de réussite',
  t_totalCallInfo: 'Nombre total d’appels passés',
  t_spokenCallInfo: 'Nombre d’appels avec conversation',
  t_spokenRateInfo: 'Taux d’appels avec conversation sur le total des appels sans tentative. Formule : Conversation / Total - Sans tentative',
  t_noSpokenCallInfo: 'Nombre d’appels sans conversation après tentative (appels manqués et occupés)',
  t_noAttemptsCallInfo: 'Nombre d’appels terminés sans tentative de la part de l’opérateur',
  t_attemptsRateInfo: 'Taux d’appels où l’opérateur a tenté de joindre le numéro appelé. Formule : Total - Sans tentative / Total',
  t_reachRateInfo: 'Taux de réussite de la connexion des appels. Formule : Conversation + Abandonnés / Total',
  t_username: 'Nom d’utilisateur',
  t_examples: 'Exemples',
  t_cron: 'Planificateur',
  t_cron1: "Chaque minute",
  t_cron2: "Chaque heure",
  t_cron3: "Chaque jour à minuit",
  t_cron4: "Chaque jour à 17h00",
  t_cron5: "Chaque dimanche à minuit",
  t_cron6: "Le premier jour de chaque mois à minuit",
  t_cron7: "Le premier jour de l’année à minuit",
  t_cron8: "Chaque jour ouvrable à minuit",
  t_cron9: "Le 15 de chaque mois à minuit",
  t_cron10: "Le premier lundi de chaque année à minuit",
  t_cron11: "Le 15 janvier, juin et décembre de chaque année à minuit",
  t_cron12: "Le premier jour ouvrable de chaque mois à minuit",
  t_cron13: "Les week-ends à minuit",
  t_alert: 'Alerte',
  t_lessThan: 'Moins que',
  t_lessThanOrEqual: 'Inférieur ou égal',
  t_moreThanOrEqual: 'Supérieur ou égal',
  t_rConferenceEvent: 'Événement de conférence',
  t_rConferenceParticipants: 'Participants à la conférence',
  t_rQualityFormNote: 'Note du formulaire de qualité',
  t_rInboundFailSL3059: 'Appels abandonnés en 30/59 secondes',
  t_rInboundFailSL60119: 'Appels abandonnés en 60/119 secondes',
  t_rInboundFailSL120179: 'Appels abandonnés en 120/179 secondes',
  t_rInboundFailSL180: 'Appels abandonnés après 180 secondes',
  t_rIvrWaitLength: 'Durée d’attente IVR',
  t_rExtra: 'Informations supplémentaires',
  t_rSender: 'Expéditeur',
  t_rMessage: 'Message',
  t_rEvaluateEvent: 'Événement d’évaluation',
  t_rEvaluateTime: 'Date d’évaluation',
  t_rQualityFormName: 'Nom du formulaire',
  t_callAnalyze: 'Analyse des appels',
  t_rObjectionEvent: 'Objection',
  t_rPunishEvent: 'Punition',
  t_rQualityPoint: 'Point de qualité',
  t_rMailSender: "Expéditeur",
  t_rSubject: "Objet",
  t_rBody: "Description",
  t_rTo: 'Destinataire (À)',
  t_rCC: 'Copie carbone (Cc)',
  t_rState: "État",
  t_rStartTime: "Heure de début",
  t_rEndTime: "Heure de fin",
  t_rProductivity: "Productivité",
  t_rRemoteNumber: "Numéro de téléphone",
  t_rAgent: "Agent",
  t_rDirection: "Direction",
  t_rQueue: "File d'attente",
  t_rVerdict: "Code de résultat",
  t_rActionDate: "Date d'action",
  t_rNote: "Note",
  t_rConnectedLength: "Durée de l'appel",
  t_rQueueWaitLength: "Temps d'attente en file",
  t_rHoldLength: "Durée en attente",
  t_rAcwLength: "Durée ACW",
  t_rRecordingFile: "Nom du fichier",
  t_rTerminateSide: "Terminé par",
  t_rRecordingFileName: "Enregistrement",
  t_rLocalNumber: "Ligne",
  t_rLineName: "Nom de la ligne",
  t_rClassification: "Classification",
  t_rCampaign: "Campagne",
  t_rInteractionID: "ID d'interaction",
  t_rMüşteriNo: "Numéro de client",
  t_rAdıSoyadı: "Prénom et nom",
  t_rIsCalledBack: "Rappelé",
  t_rCalledBackedStartTime: "Heure de rappel",
  t_rCalledBackedQueue: "File de rappel",
  t_rUsername: "Agent",
  t_rInboundSuccess: "Réussi entrant",
  t_rInboundTotal: "Total entrant",
  t_rTotal: "Total général",
  t_rLogin: "Connexion",
  t_rLogout: "Déconnexion",
  t_rAvgCallTime: "Durée moyenne des appels",
  t_rOnlineTime: "Temps en ligne",
  t_rInCallTime: "Temps total en appel",
  t_rInboundSuccessSL: "SL Réponse",
  t_rInboundFail: "Abandonné",
  t_rOutboundSuccess: "Réussi sortant",
  t_rOutboundFail: "Échec sortant",
  t_rTotalWaitTime: "Temps d'attente total",
  t_rAvgWaitTime: "Temps d'attente moyen",
  t_rMaxWaitTime: "Temps d'attente max",
  t_rAgentMissed: "Appel manqué par l'agent",
  t_rInitMoment: "Date",
  t_rCount: "Quantité",
  t_rDate: "Date",
  t_rCustomerID: "Numéro de client",
  t_rProductID: "Numéro de produit",
  t_rInboundSuccessRate: "Taux de réponse",
  t_rDialerSuccess: "Composeur auto réussi",
  t_rDialerFail: "Composeur auto échoué",
  t_rDialerTotal: "Composeur auto total",
  t_rDialerTotalTalkTime: "Durée totale des appels composeur auto",
  t_rDialerTotalIVRTime: "Durée totale IVR composeur auto",
  t_rDialerAvgIVRTime: "Durée moyenne IVR composeur auto",
  t_rDialerAvgTalkTime: "Durée moyenne des appels composeur auto",
  t_rTotalAvgCallTime: "Durée moyenne totale des appels",
  t_rTotalCallTime: "Durée totale des appels",
  t_rTotalSuccessCallCount: "Nombre d'appels réussis",
  t_rTotalCallCount: "Nombre total d'appels",
  t_rDialerTotalCallTime: "Durée totale des appels composeur auto",
  t_rDialerAvgCallTime: "Durée moyenne des appels composeur auto",
  t_rTotalSuccess: "Total des appels réussis",
  t_rTotalTalkTime: "Temps total de conversation",
  t_rTotalAvgTalkTime: "Durée moyenne de conversation",
  t_rTotalCustomer: "Total des clients",
  t_rCallable: "Appelable",
  t_rUnCallable: "Complété",
  t_rZeroAttempts: "Jamais appelé",
  t_rStartDate: "Date de début",
  t_rAgentCount: "Nombre d'agents",
  t_rIvrMissed: "Appels manqués IVR",
  t_rInboundAgentTotal: "Appels entrants par agent",
  t_rInboundSuccessSLA30: "Répondu après 30s",
  t_rInboundSuccessSL120: "Répondu en moins de 120s",
  t_rInboundSuccessSLA120: "Répondu après 120s",
  t_rInboundFailSL30: "Appels abandonnés avant 30s",
  t_rInboundFailSLA30: "Appels abandonnés après 30s",
  t_rInboundSuccessSLA30AvgWaitTime: "Temps d'attente moyen après 30s",
  t_rInboundSuccessSLA120AvgWaitTime: "Temps d'attente moyen après 120s",
  t_rAvgWaitTimeSLA30: "Temps d'attente moyen après 30s",
  t_rSuccessRate: "Taux de réussite",
  t_rServiceLevel: "Niveau de service",
  t_rTotalConnectedLength: "Durée totale des appels",
  t_rAvgConnectedLength: "Durée moyenne des appels",
  t_rTotalSuccessCount: "Nombre total d'appels réussis",
  t_rBaseRemoteNumber: "Téléphone",
  t_rTotalCall: "Appels totaux",
  t_rSuccessCall: "Appels réussis",
  t_rFailCall: "Appels échoués / Manqués",
  t_rAMD: "Répondeur",
  t_rTotalCallLength: "Durée totale des appels",
  t_rFirstCallTime: "Première heure d'appel",
  t_rLastCallTime: "Dernière heure d'appel",
  t_rAbandon: "Abandonné",
  t_rSaleCall: "Vente réussie",
  t_rEventTime: "Heure de l'événement",
  t_rEvent: "Événement",
  t_rPrevStatus: "Statut précédent",
  t_rPrevReason: "Raison précédente",
  t_rPrevStatusLength: "Durée (s)",
  t_rInboundSL30: "SL30",
  t_rInboundAnswerRate: "Taux de réponse entrant",
  t_rInboundAvgCallTime: "Durée moyenne d'appel entrant",
  t_rOutboundAvgCallTime: "Durée moyenne d'appel sortant",
  t_rInterval: "Heure",
  t_rInboundTotalCallTime: "Durée totale des appels entrants",
  t_rOutboundTotalCallTime: "Durée totale des appels sortants",
  t_rDayName: "Jour",
  t_rInboundSuccessSL30: "Répondu dans les 30 premières secondes",
  t_rInboundSuccessSL30Rate: "Taux de réponse en 30s",
  t_rInboundFailSL10: "Abandonné dans les 10 premières secondes",
  t_rInboundSuccessRateSL30: "Taux de réponse SL30",
  t_rInboundFailRate: "Taux d'abandon",
  t_rInboundAvgTalkTime: "Durée moyenne des appels entrants",
  t_rInboundTotalTalkTime: "Durée totale des appels entrants",
  t_rInboundAvgAnswerTime: "Temps moyen de réponse",
  t_rOutboundTotal: "Appels sortants totaux",
  t_rOutboundAvgTalkTime: "Durée moyenne des appels sortants",
  t_rOutboundTotalTalkTime: "Durée totale des appels sortants",
  t_rCreateDate: "Date de création",
  t_rAssignedAgent: "Agent assigné",
  t_rLine: "Ligne",
  t_rSuccessCalls: "Appels réussis",
  t_rFailCalls: "Appels échoués",
  t_rHour: "Heure",
  t_rAgentSentimentPositiveSegmentCount: "Nombre de segments positifs de l'agent",
  t_rAgentSentimentPositiveScore: "Score positif de l'agent",
  t_rAgentSentimentPositiveTokenCount: "Nombre de tokens positifs de l'agent",
  t_rAgentSentimentNegativeSegmentCount: "Nombre de segments négatifs de l'agent",
  t_rAgentSentimentNegativeScore: "Score négatif de l'agent",
  t_rAgentSentimentNegativeTokenCount: "Nombre de tokens négatifs de l'agent",
  t_rAgentSentimentTotalScore: "Score total de l'agent",
  t_rAgentSentimentPositiveSegmentRate: "Taux de segments positifs de l'agent (%)",
  t_rAgentSentimentNegativeSegmentRate: "Taux de segments négatifs de l'agent (%)",
  t_rAgentSentimentNotrSegmentRate: "Taux de segments neutres de l'agent (%)",
  t_rCustomerSentimentPositiveSegmentCount: "Nombre de segments positifs du client",
  t_rCustomerSentimentPositiveScore: "Score positif du client",
  t_rCustomerSentimentPositiveTokenCount: "Nombre de tokens positifs du client",
  t_rCustomerSentimentNegativeSegmentCount: "Nombre de segments négatifs du client",
  t_rCustomerSentimentNegativeScore: "Score négatif du client",
  t_rCustomerSentimentNegativeTokenCount: "Nombre de tokens négatifs du client",
  t_rCustomerSentimentTotalScore: "Score total du client",
  t_rCustomerSentimentPositiveSegmentRate: "Taux de segments positifs du client (%)",
  t_rCustomerSentimentNegativeSegmentRate: "Taux de segments négatifs du client (%)",
  t_rCustomerSentimentNotrSegmentRate: "Taux de segments neutres du client (%)",
  t_rTotalDuration: "Durée totale des appels",
  t_rOverlappedSpeechRatio: "Taux de chevauchement des discours",
  t_rOverlappedSpeechDuration: "Durée du chevauchement des discours (s)",
  t_rOverlappedSilenceRatio: "Taux de silence",
  t_rOverlappedSilenceDuration: "Durée du silence (s)",
  t_rAgentSpeechRatio: "Taux de discours de l'agent",
  t_rAgentSpeechDuration: "Durée de discours de l'agent (s)",
  t_rAgentSpeakingRate: "Taux de parole de l'agent (Mots / Minute)",
  t_rAgentInterruptCount: "Nombre d'interruptions de l'agent",
  t_rCustomerSpeechRatio: "Taux de discours du client",
  t_rCustomerSpeechDuration: "Durée de discours du client (s)",
  t_rCustomerSpeakingRate: "Taux de parole du client (Mots / Minute)",
  t_rCustomerInterruptCount: "Nombre d'interruptions du client",
  t_rListens: "Écoutés",
  t_rTotalEvaluations: "Évaluations totales",
  t_rAutoEvaluations: "Évaluations automatiques",
  t_rEvaluations: "Évaluations manuelles",
  t_rAvgAutoEvaluationsPoint: "Note moyenne des évaluations automatiques",
  t_rAvgEvaluationsPoint: "Note moyenne des évaluations manuelles",
  t_rTotalCalls: "Appels totaux",
  t_rOverlappedSilence: "Silences doubles",
  t_rOverlappedSilenceRateByTotalCalls: "Silences doubles / Appels totaux (%)",
  t_rOverlappedSilenceRateBySuccessCalls: "Silences doubles / Appels réussis (%)",
  t_rClosedByAgentCount: "Fermé par l'agent",
  t_rClosedByCustomerCount: "Fermé par le client",
  t_rClosedByAgentRateByTotalCalls: "Fermé par l'agent (%)",
  t_rClosedByCustomerRateByTotalCalls: "Fermé par le client (%)",
  t_rForbiddenWords: "Utilisation de mots interdits",
  t_rForbiddenWordsRateByTotalCalls: "Mots interdits / Appels totaux (%)",
  t_rForbiddenWordsRateBySuccessCalls: "Mots interdits / Appels réussis (%)",
  t_rAgentNoSpeechCount: "Appels où le client parle et l'agent ne parle pas",
  t_rAgentNoSpeechRateByTotalCalls: "Appels où le client parle et l'agent ne parle pas / Appels totaux (%)",
  t_rAgentNoSpeechRateBySuccessCalls: "Appels où le client parle et l'agent ne parle pas / Appels réussis (%)",
  t_rAvgAHT: "AHT moyen",
  t_rMaxAHT: "AHT max",
  t_rMinAHT: "AHT min",
  t_rDuration: "Durée de l'appel",
  t_rListeningDuration: "Durée d'écoute",
  t_rPlayCount: "Nombre de lectures",
  t_rPauseCount: "Nombre de pauses",
  t_rIsMuted: "Son coupé",
  t_rPlayBackChangeCount: "Nombre de changements de vitesse de lecture",
  t_rPlayBackStartSpeed: "Vitesse de départ",
  t_rPlayBackEndSpeed: "Vitesse de fin",
  t_rManualForm: "Formulaire manuel",
  t_rManualFormPoint: "Note du formulaire manuel",
  t_rAutoForm: "Formulaire automatique",
  t_rAutoFormPoint: "Note du formulaire automatique",
  t_rQualityNote: "Note de qualité",
  t_abandonedCampaign: "Campagne d'appels abandonnés",
  t_autoRecycle: "Recyclage automatique",
  t_goalListByAgents: "Liste des objectifs par agent",
  t_addGoal: "Ajouter un objectif",
  t_addCollection: "Ajouter un mouvement",
  t_collections: "Mouvements",
  t_archivedMessages: "Messages archivés",
  t_archivetedMessages: "Messages archivés",
  // Report Names
  t_rQualityDetails: "Détail de qualité",
  t_rInboundCallDetails: "Résumé des appels entrants",
  t_rCallTimeAndCounts: "Temps et nombre d'appels",
  t_rCallDetails: "Détails de l'appel",
  t_rClassificationDetails: "Détails de la classification",
  t_rAgentPerformanceSummary: "Résumé de la performance des agents",
  t_rAgentPerformanceSummaryByShift: "Performance par shift",
  t_rVerdictDetails: "Détail du code de résultat",
  t_rSmsHistory: 'Historique des SMS',
  t_rMailHistory: 'Historique des e-mails',
  t_rSecondLineDetails: 'Détail de la deuxième ligne / interne',
  t_rAbandonCalls: 'Détail des appels abandonnés',
  t_rMissedCalls: 'Détail des appels manqués',
  t_rAgentSummary: 'Résumé des agents',
  t_rQueueSL: 'SL / File d’attente',
  t_rOutOfWork: 'Appels après les heures de travail',
  t_rQueueDaily: 'Nombre d’appels entrants par jour',
  t_rAgentDaily: 'Nombre d’appels entrants par jour',
  t_rQueueHourly: 'Nombre d’appels entrants par heure',
  t_rAgentHourly: 'Nombre d’appels entrants par heure',
  t_rVerdictByDate: 'Nombre de codes de résultat par date',
  t_rVerdictByDaily: 'Codes de résultat par jour',
  t_rVerdictByQueue: 'Codes de résultat par file',
  t_rVerdictByAgent: 'Codes de résultat par agent',
  t_rClassificationByDate: 'Nombre de classifications par date',
  t_rClassificationByDaily: 'Classifications par jour',
  t_rClassificationByQueue: 'Classifications par file',
  t_rClassificationByAgent: 'Classifications par agent',
  t_rCallCountByDate: 'Nombre d’appels par jour',
  t_rDataSummaryByQueue: 'Résumé des données par file',
  t_rProductActionDetails: "Détails des actions de produit",
  t_rLoginLogout: 'Connexion / Déconnexion',
  t_rLocalNumberSL: 'SL / Numéro',
  t_rCallTimeAndCountByDirection: 'Temps et nombre d’appels par direction',
  t_rPozitifCallDetails: 'Détails des appels / Pool',
  t_rActivity: 'Activité de l’agent',
  t_rDailyCallValues: 'Valeurs des appels par jour',
  t_callrecords: "Enregistrements des appels",
  t_rHourlyCallValues: 'Valeurs des appels par heure',
  t_rAgentPerformanceDaily: 'Performance des agents par jour',
  t_rAgentPerformanceMonthly: 'Performance des agents par mois',
  t_rAgentPerformanceHourly: 'Performance des agents par heure',
  t_rMonthlyCallValues: 'Valeurs des appels par mois',
  t_rCallPerformanceByDate: 'Performance des appels par jour',
  t_rDataSummaryByCreateDate: 'Résumé des données par date de création',
  t_rDataSummaryByAssignedAgent: 'Résumé des données par agent assigné',
  t_rDataSummaryByDynamicFields: 'Résumé des données par champ dynamique',
  t_rLinePerformance: 'Performance de la ligne',
  t_rInboundCallValuesByHourly: 'Valeurs des appels entrants par heure',
  t_rSurveyResult: 'Résultats du sondage',
  t_rEmotionAnalysis: 'Analyse des émotions / Jour',
  t_rSpeechStatistics: 'Statistiques de parole / Jour',
  t_analysis: "Analyse",
  t_rQualityByDate: 'Qualité / Jour',
  t_rQualityByAgent: 'Qualité / Agent',
  t_rCallStatisticsByDate: 'Statistiques des appels / Jour',
  t_rListeningDetails: 'Détails d’écoute',
  t_rProject: 'Projet',
  t_rIVR: 'IVR',
  t_rInQueue: "En file d’attente",
  t_rInProgress: "En cours",
  t_rShiftStart: 'Début du poste',
  t_rShiftEnd: 'Fin du poste',
  t_rShiftDuration: 'Durée du poste',
  t_rMissingTime: 'Temps manquant',
  t_rCalculatedShiftDuration: 'Durée calculée du poste',
  t_rCalculatedBreak: 'Durée de pause calculée',
  t_rTotalWorkDuration: 'Durée totale de travail',
  t_rLeave: 'Congé',
  t_rBreakTime: 'Temps de pause de référence',
  t_rCalculatedWorkTime: 'Temps de travail calculé',
  t_rWorkDuration: 'Durée du travail',
  t_rTotalWorkDifference: 'Différence de durée de travail',
  t_reportName: "Nom du rapport",
  t_dataCount: "Nombre de données",
  t_expand: "Agrandir",
  t_shrink: "Réduire",
  t_darkMode: "Mode sombre",
  t_lightMode: "Mode clair",
  t_alertCategory: "Catégorie d'alerte",
  t_targetCategory: "Catégorie d'objectif",
  t_targetType: "Type d'objectif",
  t_none: "Aucun",
  t_seeExampleTimePatterns: "Voir les exemples de modèles temporels",
  t_apply: "Appliquer",
  t_callList: 'Liste d’appel',
  t_pool: 'Pool',
  t_all: 'Tous',
  t_logs: 'Journaux',
  t_totalAttempts: 'Tentatives totales',
  t_insertDate: 'Dernier appel',
  t_actionTime: 'Heure d’appel',
  t_assignDate: 'Date d’affectation',
  t_createDate: 'Date de création',
  t_createTime: 'Heure de création',
  t_exAgent: 'Ancien agent',
  t_addExtraCustomerInfo: 'Ajouter des informations supplémentaires sur le client',
  t_primaryKey: 'Clé primaire',
  t_secondaryKey: 'Clé secondaire',
  t_qVoice: 'Voix',
  t_qVideo: 'Vidéo',
  t_qText: 'Texte',
  t_interactionWeight: 'Poids du canal',
  t_records: 'Enregistrements',
  t_qualityForm: 'Formulaire de qualité',
  t_keyWords: 'Mots-clés',
  t_criterions: 'Critères',
  t_required: "Obligatoire",
  t_isChatFinishCode: 'Code de fin pour les canaux textuels',
  t_minWaitInQueue: 'Temps d’attente min. en file d’attente (s)',
  t_maxWaitInQueue: 'Temps d’attente max. en file d’attente (s)',
  t_minDuration: 'Durée min. de l’appel (s)',
  t_maxDuration: 'Durée max. de l’appel (s)',
  t_rIsEvaluated: 'Évalué',
  t_rEvaluator: 'Évaluateur',
  t_rEvaluatedStartTime: 'Heure de début de l’évaluation',
  t_rEvaluatedEndTime: 'Heure de fin de l’évaluation',
  t_rMinQualityPoint: 'Note de qualité min.',
  t_rMaxQualityPoint: 'Note de qualité max.',
  t_rQualityForm: 'Formulaire de qualité',
  t_rIsObjection: 'Objection',
  t_multiple: 'Sélection multiple',
  t_question: 'Question',
  t_minValue: 'Valeur min.',
  t_maxValue: 'Valeur max.',
  t_minTrueCount: 'Nombre min. de bonnes réponses',
  t_ordered: 'Ordonné',
  t_minPoint: 'Points min.',
  t_maxPoint: 'Points max.',
  t_point: 'Points',
  t_selected: 'Sélectionné',
  t_punish: 'Punir',
  t_pointRange: 'Plage de points',
  t_answer2: 'Réponse',
  t_openInNewWindow: 'Ouvrir dans une nouvelle fenêtre',
  t_playbackRate: 'Vitesse de lecture',
  t_isMailSendToAgent: 'Envoyer un e-mail à l’agent',
  t_httpRequest: 'Requête HTTP',
  t_tts: 'TTS',
  t_stt: 'STT',
  t_beepTone: "Signal sonore",
  t_model: 'Modèle',
  t_whatsapp: 'WhatsApp',
  t_readQR: 'Lire le QR',
  t_url: 'Adresse URL',
  t_headers: 'En-têtes (JSON)',
  t_body: 'Corps (JSON)',
  t_text: 'Texte',
  t_useableVariables: 'Variables utilisables',
  t_remoteNumber: 'Numéro de téléphone',
  t_hideVariableFromAgent: 'Masquer la variable à l’agent',
  t_provider: 'Fournisseur',
  t_speaker: 'Orateur',
  t_isInList: 'Dans la liste',
  t_isNotInList: 'Pas dans la liste',
  t_isContains: 'Contient',
  t_isNotContains: 'Ne contient pas',
  t_IsConvergent: 'Convergent',
  t_IsNotConvrgent: 'Non convergent',
  t_changeAllQueues: 'Changer toutes les files d’attente',
  t_remove: 'Supprimer',
  t_answerToObject: 'Répondre à l’objection',
  t_objectToForm: 'Objection au formulaire',
  t_objections: 'Objections',
  t_accept: 'Accepter',
  t_rLastObjectingUser: 'Dernier utilisateur à avoir objecté',
  t_evaluatedDaily: "Évalué aujourd'hui",
  t_avgPointDaily: "Moyenne des points aujourd'hui",
  t_evaluatedWeekly: "Évalué cette semaine",
  t_avgPointWeekly: "Moyenne des points cette semaine",
  t_evaluatedMonthly: "Évalué ce mois-ci",
  t_avgPointMonthly: "Moyenne des points ce mois-ci",
  t_standart: 'Standard',
  t_advanced: 'Avancé',
  t_quality: 'Qualité',
  t_removeEvaluation: 'Supprimer l’évaluation',
  t_openOnNewWindow: 'Ouvrir dans un nouvel onglet',
  t_undefined: 'Non défini',
  t_callTime: 'Durée de l’appel',
  t_serviceLevel: 'Niveau de service',
  t_abandonRate: 'Taux d’abandon',
  t_callAnswerTime: 'Temps de réponse',
  t_noRouteToDestination: 'Pas de route vers la destination',
  t_passwordSentToMailAdress: 'Votre mot de passe a été envoyé à votre adresse e-mail.',
  t_noEmailAdress: 'Aucune adresse e-mail associée à ce compte. Veuillez contacter votre administrateur.',
  t_forgotPassword: 'Mot de passe oublié',
  t_saveRecord: 'Enregistrer un enregistrement vocal',
  t_isHttps: 'HTTPS',
  t_callStatistics: 'Statistiques des appels',
  t_addtCategory: 'Ajouter une nouvelle catégorie',
  t_create: 'Créer',
  t_questionCount: 'Nombre de questions',
  t_minPts: 'Points min.',
  t_maxPts: 'Points max.',
  t_questionsAndAnswers: 'Questions & Réponses',
  t_extensionRoutes: 'Routes',
  t_evaluatedRecords: 'Enregistrements évalués',
  t_alerts: 'Alertes',
  t_audioEncoding: "Encodage audio",
  t_gender: "Sexe de la voix",
  t_token: "Token",
  t_showTCKN: "Afficher le TCKN",
  t_webChat: "Chat Web",
  t_whatsapp: "WhatsApp",
  t_instagram: "Instagram",
  t_messenger: "Messenger",
  t_informAgent: 'Informer l’agent',
  t_autoCustomerCard: 'Ouvrir automatiquement la fiche client',
  t_downloadCallRecordings: 'Télécharger les enregistrements d’appels',
  t_outboundCallApproval: 'Approbation d’appels sortants',
  t_callHistoryDownloadRecording: 'Télécharger l’enregistrement des appels dans l’historique',
  t_callHistoryListenRecording: 'Écouter l’enregistrement des appels dans l’historique',
  t_dialerReopenTotalAttempts: 'Réouvrir les appels fermés par tentatives totales',
  t_dialerReopenDailyAttempts: 'Réouvrir les appels fermés par tentatives quotidiennes',
  t_dialerClearCallSchedules: 'Réinitialiser les appels programmés',
  t_showAllFinishCodes: 'Afficher tous les codes de fin',
  t_finishcodesofcallsfortoday: "Codes de fin des appels d’aujourd’hui",
  t_updateLastFinishCode: 'Mettre à jour le dernier code de fin',
  t_showIVRInputs: 'Afficher les saisies IVR',
  t_hidePhoneNumbers: 'Masquer les numéros de téléphone',
  t_addExtraData: 'Ajouter des données supplémentaires',
  t_script: 'Script',
  t_crmCall: 'Appel',
  t_crmAddCustomer: 'Ajouter un client',
  t_displayAllRecords: 'Afficher tous les enregistrements',
  t_editCustomer: 'Mettre à jour le client',
  t_crmlistenRecording: 'Écouter l’enregistrement CRM',
  t_showNoteDetails: 'Afficher les détails de la note',
  t_excelCustomer: 'Client Excel',
  t_excelCustomerByPhone: 'Excel par téléphone',
  t_excelCustomerByProduct: 'Excel par produit',
  t_showPhoneDetails: 'Afficher les détails du téléphone',
  t_justShowOwnCustomers: 'Afficher uniquement mes clients',
  t_dontSendCustomMessage: 'Empêcher la modification du message',
  t_keywordCategories: "Catégories de mots-clés",
  t_keyWord: "Mot-clé",
  t_minOccurrence: "Occurrence minimale",
  t_maxOccurrence: "Occurrence maximale",
  t_inFirstSeconds: "Dans les premières secondes",
  t_inLastSeconds: "Dans les dernières secondes",
  t_logicCondition: "Condition logique",
  t_reverse: "Inverse",
  t_and: "Et",
  t_or: "Ou",
  t_customer: "Client",
  t_oneOfBoth: "L’un des deux",
  t_bothOfThem: "Les deux",
  t_liveDashboards: 'TABLEAUX DE BORD EN DIRECT',
  t_tools: 'OUTILS',
  t_admin: 'ADMIN',
  t_useDatesForEvaluateDate: 'Utiliser les dates comme dates d’évaluation',
  t_menuLineGroups: 'Menu - Groupes de lignes',
  t_menuDialPlans: 'Menu - Plans d’appel',
  t_menuIVR: 'Menu - IVR',
  t_menuAnnouncements: 'Menu - Annonces',
  t_menuRoutes: 'Menu - Routes',
  t_menuTimeCondition: 'Menu - Condition temporelle',
  t_menuUsers: 'Menu - Utilisateurs',
  t_menutExtensionRoutes: 'Menu - Routes',
  t_menuQueues: 'Menu - Files d’attente',
  t_menuProjects: 'Menu - Projets',
  t_menutStatuses: 'Menu - Statuts',
  t_menutFinishCodes: 'Menu - Codes de fin',
  t_menuBlackList: 'Menu - Liste noire',
  t_menuShifts: 'Menu - Postes',
  t_menuProfiles: 'Menu - Profils',
  t_menuRecordingExport: 'Menu - Export d’enregistrement',
  t_menuSms: 'Menu - SMS',
  t_menuReports: 'Menu - Rapports',
  t_menuGoalManagement: 'Menu - Gestion des objectifs',
  t_menuDialer: 'Menu - Composeur auto',
  t_menuLiveDashboard: 'Menu - Surveillance en direct',
  t_menuPhoneBook: 'Menu - Carnet d’adresses',
  t_menuCalendar: 'Menu - Calendrier',
  t_menuCrm: 'Menu - CRM',
  t_menuDashboard: 'Menu - Tableau de bord',
  t_menuEvaluatedRecords: 'Menu - Enregistrements évalués',
  t_menuSystemManagement: 'Menu - Gestion du système',
  t_menuOperationManagement: 'Menu - Gestion des opérations',
  t_menuIntegrations: 'Menu - Intégrations',
  t_menuSettings: 'Menu - Paramètres',
  t_menuWallboard: 'Menu - Mur d’images',
  t_menuDashboard: 'Menu - Tableau de bord',
  t_menuQueueGroup: 'Menu - Groupe de files',
  t_menuvIVR: 'Menu - IVR dynamique',
  t_menuScheduler: 'Menu - Planificateur',
  t_menuExternalChat: 'Menu - Canal écrit',
  t_menuSurvey: 'Menu - Enquête',
  t_menuEMail: 'Menu - E-Mail',
  t_menuAlerts: 'Menu - Alertes',
  t_menuQuality: 'Menu - Qualité',
  t_otherAgentList: 'Autres - Liste des agents',
  t_othertAutoCustomerCard: 'Autres - Ouvrir automatiquement la fiche client',
  t_otherDownloadCallRecordings: 'Autres - Télécharger les enregistrements d’appels',
  t_otherOutboundCallApproval: 'Autres - Approbation des appels sortants',
  t_otherCallHistoryDownloadRecording: 'Autres - Télécharger l’enregistrement des appels dans l’historique',
  t_otherCallHistoryListenRecording: 'Autres - Écouter l’enregistrement des appels dans l’historique',
  t_otherDialerReopenTotalAttempts: 'Autres - Réouvrir les appels fermés par tentatives totales',
  t_otherDialerReopenDailyAttempts: 'Autres - Réouvrir les appels fermés par tentatives quotidiennes',
  t_otherDialerClearCallSchedules: 'Autres - Réinitialiser les appels programmés',
  t_otherShowAllFinishCodes: 'Autre - Afficher tous les codes de fin',
  t_otherUpdateLastFinishCode: 'Autre - Mettre à jour le dernier code de fin',
  t_otherShowIVRInputs: 'Autre - Afficher les saisies IVR',
  t_otherHidePhoneNumbers: 'Autre - Masquer les numéros de téléphone',
  t_ChatSouffle: 'Chat - Souffle',
  t_chatChat: 'Chat - Messagerie',
  t_chatAnnounce: 'Chat - Annonce',
  t_crmFilters: 'CRM - Filtres',
  t_crmColumns: 'CRM - Colonnes',
  t_crmManagement: 'CRM - Gestion',
  t_crmAddExtraData: 'CRM - Ajouter des données supplémentaires',
  t_crmScript: 'CRM - Script',
  t_crmProducts: 'CRM - Produits',
  t_crmPhones: 'CRM - Téléphones',
  t_crmNotes: 'CRM - Notes',
  t_crmSms: 'CRM - SMS',
  t_crmSmsSending: 'CRM - Envoi de SMS',
  t_crmCrmCall: 'CRM - Appel',
  t_crmCrmAddCustomer: 'CRM - Ajouter un client',
  t_crmDisplayAllRecords: 'CRM - Afficher tous les dossiers',
  t_crmEditCustomer: 'CRM - Modifier le client',
  t_crmCrmlistenRecording: 'CRM - Écouter l’enregistrement CRM',
  t_crmShowNoteDetails: 'CRM - Afficher les détails de la note',
  t_crmExcelCustomer: 'CRM - Client basé sur Excel',
  t_crmExcelCustomerByPhone: 'CRM - Téléphone basé sur Excel',
  t_crmShowPhoneDetails: 'CRM - Afficher les détails du téléphone',
  t_rTransferType: "Type de transfert",
  t_rTransferTarget: "Cible du transfert",
  t_acw: "Temps ACW",
  t_rDialerConnectedToAgent: "Composeur automatique connecté à un agent",
  t_rDialerAbandon: "Composeur automatique abandonné",
  t_rACWLength: "Durée ACW",
  t_callAnalize: "Analyse des appels",
  t_callFinishCode: "Appel",
  t_writtenChannelFinishCode: "Canal écrit",
  t_productFinishCode: "Produit",
  t_finishCodeType: "Type de code de fin",
  t_speechText: "Texte de parole",
  t_reportGroup: "Grouper",
  t_pleaseSelect: "Veuillez sélectionner",
  t_awaitingInteractionsCount: "En attente",
  t_descawaitingInteractionsCount: "Nombre total d'appels en attente dans les files d'attente sélectionnées",
  t_answeredCount: "Répondu",
  t_descansweredCount: "Nombre total d'appels répondus dans les files d'attente sélectionnées",
  t_abandonedCount: "Abandonné",
  t_descabandonedCount: "Nombre total d'appels abandonnés dans les files d'attente sélectionnées",
  t_activeInteractionsCount: "Nombre d'appels actifs",
  t_descactiveInteractionsCount: "Nombre total d'appels actifs dans les files d'attente sélectionnées",
  t_averageWaitTime: "Temps d'attente moyen",
  t_descaverageWaitTime: "Temps moyen d'attente des appels dans les files d'attente sélectionnées",
  t_callBackSuccess: "Rappel réussi",
  t_desccallBackSuccess: "Nombre de rappels réussis pour les appels abandonnés",
  t_firstCallResolutionCount: "Résolution au premier appel",
  t_descfirstCallResolutionCount: "Nombre d'appels résolus avec succès au premier contact",
  t_serviceLevel: "Niveau de service",
  t_descserviceLevel: "Pourcentage d'appels répondus dans le niveau de service défini dans les paramètres de file d'attente",
  t_sRLevel: "Taux de succès",
  t_descsRLevel: "Taux d'appels complétés avec des codes de succès sur tous les appels",
  t_averageConnectedTime: "Durée moyenne des appels",
  t_descaverageConnectedTime: "Durée moyenne de tous les appels réussis",
  t_abandonRate: "Taux d'abandon",
  t_descabandonRate: "Taux d'abandon des appels non répondus par rapport à tous les appels dans les files d'attente sélectionnées",
  t_transferQueue: "Transférer la file d'attente",
  t_rAvgQueueLength: "Temps d'attente moyen dans la file d'attente",
  t_crmlistenRecordingJustOwn: "Écouter uniquement vos propres appels",
  t_deleteQualityForm: "Supprimer le formulaire de qualité",
  t_currentPasswordDoesNotMatch: "Le mot de passe actuel est incorrect",
  t_confirmPasswordDoesNotMatch: "Le mot de passe de confirmation ne correspond pas",
  t_rRinging: "Sonnerie",
  t_rEnterIVR: "Redirigé vers IVR",
  t_ranswer: "IVR a répondu",
  t_rStartIvr: "IVR démarré",
  t_rTimeConditionFail: "Condition temporelle non respectée",
  t_rTimeConditionSuccess: "Condition temporelle respectée",
  t_rStartIvrMenu: "Menu",
  t_rMenuAttempt: "Tentative",
  t_rMenuAnnouncement: "Annonce",
  t_rDtmfResult: "Résultat DTMF",
  t_rTaskmenu: "Menu",
  t_rTasksurvey: "Tâche de sondage",
  t_rStartPreSurveyTask: "Démarrage du sondage",
  t_rPreSurveyAttempt: "Tentative",
  t_rPreSurveyAnnouncement: "Annonce du sondage",
  t_rPreSurveyResults: "Résultats du sondage",
  t_rTaskcondition: "Condition",
  t_rConditionFail: "Condition non respectée",
  t_rTaskvariable: "Variable",
  t_rStartVariableTask: " ",
  t_rTaskannounce: "Annonce",
  t_rVariableAttempt: "Tentative",
  t_rVariableAnnouncement: "Annonce de la variable",
  t_rTaskhttp: "Requête HTTP effectuée",
  t_rVariableFailed: "Erreur de variable",
  t_rFailedTaskvariable: "Erreur dans la tâche de variable",
  t_rMenuFailed: "Erreur de menu",
  t_rFailedTaskmenu: "Erreur dans la tâche de menu",
  t_rConditionError: "Erreur de condition",
  t_rFailedTaskcondition: "Erreur dans la tâche de condition",
  t_rTimeConditionError: "Erreur de condition temporelle",
  t_rIvrFailed: "IVR terminé",
  t_rFailedInboundCall: "Appel entrant terminé",
  t_rFinalized: "Finalisé",
  t_rJoinedQueue: "Entré en file d'attente",
  t_rAssigned: "Attribué",
  t_rAccepted: "Accepté",
  t_rPickedUp: "Pris en charge",
  t_rRemoteFailed: "Erreur de numéro distant",
  t_rConditionSuccess: "Condition réussie",
  t_rTaskqueue: "File d'attente",
  t_roriginate: "Appel initialisé",
  t_rcall_target: "Cible de l'appel",
  t_rnew_bridge: "Nouveau canal",
  t_rbridge_created: "Canal créé",
  t_rcalls_bridged: "Canaux connectés",
  t_rcall_transferred: "Transféré",
  t_rUnassigned: "Désattribué",
  t_rAbandoned: "Abandonné",
  t_rRejected: "Rejeté",
  t_rNoAnswer: "Sans réponse",
  t_rTransferAssigned: "Attribué pour transfert",
  t_rTransferAccepted: "Transfert accepté",
  t_rTaskivr: "IVR",
  t_rTasktransfer: "Transfert",
  t_rEndCall: "Appel terminé",
  t_rFailedTaskhttp: "Erreur de requête HTTP",
  t_rTotalLocal: "Fermeture locale totale",
  t_rInboundLocal: "Fermeture locale des appels entrants",
  t_rOutboundLocal: "Fermeture locale des appels sortants",
  t_rAvgDialingTime: "Temps de composition moyen",
  t_rTotalInCallTime: "Temps total en appel",
  t_rTotalStatusTime: "Temps total de statut",
  t_rTotalHoldCount: "Nombre total de mises en attente",
  t_rTotalHoldTime: "Temps total de mise en attente",
  t_rAvgHandleTime: "Temps de gestion moyen",
  t_rLogoutCount: "Nombre de déconnexions",
  t_rProductivityTime: "Temps de productivité",
  t_rBreakCategoryTime: "Temps de pause utilisé",
  t_rAvgAlertTime: "Temps d'alerte moyen",
  t_addFile: "Ajouter un fichier",
  t_appointment: "Rendez-vous",
  t_appointments: "Rendez-vous",
  t_second: "Seconde",
  t_notAnsweringCallDesc2: "Vous êtes en statut Non-Répondant depuis 1 minute, veuillez passer en statut Disponible pour continuer.",
  t_notAnsweringCallDesc: "Vous n'avez pas répondu à l'appel, veuillez passer en statut Disponible pour continuer.",
  t_notAnsweringCall: "Appel non répondu",
  t_timeFormat: "Format de l'heure",
  t_callGroup: "Groupe d'appel",
  t_pickupGroup: "Groupe de prise en charge",
  t_fodlerType: "Format de classement",
  t_conferenceCallAccepted: "Appel connecté",
  t_conferenceCallHangup: "Appel terminé",
  t_conferenceCallDeclined: "Appel non connecté",
  t_conferenceCalling: "Appel en cours",
  t_agentListDetails: "Détails de la liste des agents",
  t_rAgentGroup: "Groupe d'agents",
  t_new: "Nouveau",
  t_recordsActivityLog: "Journal d'activité des enregistrements",
  t_isAutoForm: "Formulaire automatique",
  t_timestamp: 'Horodatage',
  t_verificationCode: "Code de vérification",
  t_verificationCodeDesc: "Veuillez entrer le code de vérification à 6 chiffres",
  t_verify: "Vérifier",
  t_phone: "Téléphone",
  t_downloadVoyceVoiceConverter: "Télécharger Voyce Voice Converter",
  t_position: "Position",
  t_ivrBreakOut: "Sortie de file d'attente",
  t_positionAndIvrBreakOut: "Position et sortie de file d'attente",
  t_ivrBreakOutTarget: "Cible de sortie de file d'attente",
  t_displayAllCalendar: "Afficher tous les calendriers",
  t_appointmentAssignmentDesc: "De nouveaux rendez-vous vous ont été attribués. Veuillez vérifier votre calendrier.",
  t_appointmentAssignment: "Attribution de rendez-vous",
  t_displayCalls: 'Surveillance des appels',
  t_showUsedAreas: "Zones utilisées",
  t_ftp: "FTP",
  t_voiceExport: "Export vocal",
  t_putFtp: "Envoyer FTP",
  t_remotePath: "Chemin de fichier distant",
  t_passive: 'Passif',
  t_passives: 'Passifs',
  t_state: 'État',
  t_frequentlyAskedQuestions: 'FAQ',
  t_helpCenter: 'Centre d’aide',
  t_contactUs: 'Nous contacter',
  t_isSecure: 'Connexion sécurisée',
  t_rejectUnauthorized: 'Rejeter non autorisé',
  t_customMetaData: 'Personnaliser les métadonnées',
  t_metaDataEndpoint: 'Point de terminaison des métadonnées',
  t_metaDataRemotePath: 'Chemin de fichier distant des métadonnées',
  t_putAsFolder: 'Envoyer comme dossier',
  t_isDefault: 'Par défaut',
  t_rSLDuration: 'Durée SL',
  t_twoFactorAuthentication: "Authentification à deux facteurs",
  t_sentToPhoneNumber: "envoyé au numéro de téléphone",
  t_sentToEmailAdress: "envoyé à l’adresse e-mail",
  t_verifyYourIdentity: "Saisissez le code de vérification pour confirmer votre identité",
  t_remainingTime: "Temps restant",
  t_sec: "sec",
  t_donthaveAccess: "Vous n’avez pas accès à ce compte de communication ?",
  t_verificationCode: "Code de vérification",
  t_sendAsMail: "envoyer par e-mail",
  t_sendAsSms: "envoyer par SMS",
  t_verify: "Vérifier",
  t_closeProduct: "Fermer le produit",
  t_setPassiveWhenNoProduct: 'Marquer comme passif si aucun produit',
  t_sendHttpRequest: "Envoyer une requête HTTP",
  t_month2: "Mois (sans zéro)",
  t_day2: "Jour (sans zéro)",
  t_shifts: "Postes",
  t_shift: "Poste",
  t_missingTime: 'Temps manquant',
  t_isAnnualLeave: 'Congé annuel',
  t_paidLeave: 'Congé payé',
  t_unpaidLeave: 'Congé non payé',
  t_isOwner: 'Propriétaire',
  t_owner: 'Propriétaire',
  t_thirdParty: 'Tiers',
  t_breakTimeFraud: 'Fraude sur le temps de pause',
  t_dontControlLastTenDigit: 'Ne pas contrôler les 10 derniers chiffres',
  t_bank: 'Banque',
  t_dontChangeSurvey: 'Ne pas modifier l’état de l’enquête',
  t_finishCodeBreakdown: 'Détail du code de fin',
  t_displayPassiveRecords: 'Afficher les enregistrements passifs',
  t_newAssets: "Nouveau bien",
  t_reached: "Atteint",
  t_reachedDate: "Date d’atteinte",
  t_dontGetNoQueueRecords: "Ne pas récupérer les enregistrements sans file d’attente",
  t_agentHaveStaticQueueButDontHaveProjectPermission: "L’agent a une file d’attente statique mais n’a pas l’autorisation de projet.",
  t_autoRecall: 'Rappel automatique',
  t_autoRecallDay: 'Jour suivant',
  t_rDateName: "Jour",
  t_rWeekOfMonth: "Semaine du mois",
  t_rGivenBreakTime: "Temps de pause accordé",
  t_rOfflineTime: "Temps hors ligne",
  t_rMonth: "Mois",
  t_rBreakTimeDifference: "Différence de temps de pause",
  t_copyFromTheEditor: "Copier de l’éditeur",
  t_pasteIntoTheEditor: "Coller dans l’éditeur",
  t_duplicate: "Dupliquer",
  t_identifier: "TCKN",
  t_undeafen: 'Rétablir le son',
  t_deafen: 'Couper le son',
  t_canTransfer: 'Peut être transféré',
  t_wordDistance: 'Distance des mots',
  t_segmentDistance: 'Distance des segments',
  t_maxWordGroupCount: 'Nombre max. de groupes de mots',
  t_trendAnalyze: 'Analyse de tendance',
  t_realTimeTranscript: 'Transcription en temps réel',
  t_snapshots: "Snapshots",
  t_snapshotDate: "Date du Snapshot",
  t_firstCallDate: "Date du premier appel",
  t_lastCallDate: "Date du dernier appel",
  t_callCount: "Nombre d'appels",
  t_density: "Densité",
  t_avarageDensity: "Densité moyenne",
  t_displayDensityStatistics: 'Afficher le graphique de densité',
  t_hideFromAgent: 'Cacher à l’agent',
  t_customerNo: "Numéro de client",
  t_customerName: "Nom et prénom",
  t_customerIdentifier: "Identifiant client (TCKN)",
  t_reached: "Atteint",
  t_reachedDate: "Date atteinte",
  t_productNo: "Numéro de produit",
  t_artificialIntelligence: "Intelligence Artificielle",
  t_speakers: "Haut-parleurs",
  t_oneTime: "Une seule fois",
  t_scheduled: "Programmé",
  t_automatic: "Automatique",
  t_periodTime: "Période de temps",
  t_prompt: "Prompt",
  t_aiCallResult: "Résultat de l'appel AI",
  t_exceptions: "Exceptions",
}
