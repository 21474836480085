export default {
  t_welcome: "Welkom", //Login 
  t_copyright: "Alle Rechten Voorbehouden ©️ 2023 ver3.1.0",
  t_copy: "Kopiëren",
  t_userLogin: "Gebruikerslogin",
  t_userName: "Gebruikersnaam",
  t_password: "Wachtwoord",
  t_login: "Inloggen",
  t_hello: "Hallo",
  t_usernameOrPasswordIsInvalid: "Gebruikersnaam of wachtwoord is ongeldig.",
  t_successfullyLoggedIn: "Succesvol ingelogd.",
  t_pleaseSelectQueueAndClassification: "Selecteer een wachtrij en classificatie",
  t_PleaseSelectProjetAndQueues: "Selecteer een project en wachtrijen",
  t_pleaseSingIn: "Log alstublieft in",
  t_rememberMe: 'Onthoud mij',
  t_signIn: 'Inloggen',
  t_changeStatusAfterTheCall: "Verander status na het gesprek", //LoyoutContentRedererDefault.vue // 
  t_remainingTime: "Resterende tijd",
  t_continue: "Doorgaan",
  t_hold: "In de wacht zetten",
  t_microphoneOn: "Microfoon aan",
  t_microphoneOff: "Microfoon uit",
  t_inputList: "Invoerlijst",
  t_showInputs: "Toon invoer",
  t_chatPanel: "Chat Paneel",
  t_showDetail: "Toon details",
  t_connected: "Verbonden", // Navbar.vue 
  t_disconnected: "Niet verbonden",
  t_dayMode: "Dagmodus",
  t_nightMode: "Nachtmodus",
  t_agentList: "Agentenlijst",
  t_logOut: "Uitloggen", // AgentTargets // 
  t_collectionTargeting: "Verzamelen",
  t_targeting: "Doelen",
  t_dashboard: "Dashboard",
  t_search: "Zoeken",
  t_add: "Toevoegen",
  t_targetedMonth: "Geselecteerde maand",
  t_confirm: "Bevestigen",
  t_areYouSureYouWantToDeleteTheSelectedRecord: "Weet u zeker dat u het geselecteerde record wilt verwijderen?",
  t_areYouSureYouWantToUpdateTheSelectedRecords: "Weet u zeker dat u de geselecteerde records wilt bijwerken?",
  t_areYouSureYouWantToClearAll: "Weet u zeker dat u alles wilt wissen?",
  t_selectTheAgent: "Selecteer de agent",
  t_collectedDate: "Verzamelingsdatum",
  t_collectedTime: "Verzamelings tijd",
  t_edit: "Bewerken",
  t_configuration: "CONFIGURATIE",
  t_completed: "Voltooid",
  t_remaining: "Resterend",
  t_compilationRate: "Voltooiingspercentage",
  t_minute: "Minuut",
  t_targetCompletion: "Doel voltooien",
  t_hour: "Uur",
  t_day: "Dag",
  t_week: "Week",
  t_month: "Maand",
  t_year: "Jaar",
  t_weekday: "Werkdag",
  t_lastOneHour: "Laatste 1 uur",
  t_lastThreeHours: "Laatste 3 uur",
  t_twoDaysAgo: "Laatste 2 dagen",
  t_threeDaysAgo: "Laatste 3 dagen",
  t_fourDaysAgo: "Laatste 4 dagen",
  t_fiveDaysAgo: "Laatste 5 dagen",
  t_sixDaysAgo: "Laatste 6 dagen",
  t_period: "Periode",
  t_thisWeek: "Deze week",
  t_lastWeek: "Vorige week",
  t_get: "Ophalen",
  t_saveFilters: "Filters opslaan",
  t_target: "Doel",
  t_announcements: "Aankondigingen", // Announcements // 
  t_announcementName: "Naam aankondiging",
  t_selectAudioFile: "Selecteer een audiobestand",
  t_announcementList: "Aankondigingenlijst",
  t_areYouSure: "Weet u het zeker?",
  t_ok: "Oké",
  t_count: "Aantal",
  t_CompletedAmount: "Voltooide hoeveelheid",
  t_dailyTarget: "Dagelijkse doelstelling",
  t_monthlyTarget: "Maandelijkse doelstelling",
  t_dailyTimeline: "Dagelijkse tijdlijn",
  t_blackList: "Zwarte lijst", // BlackListManagement // 
  t_descriptions: "Beschrijving",
  t_inbound: "Inkomend",
  t_outbound: "Uitgaand",
  t_number: "Nummer",
  t_callHistory: "Gespreksgeschiedenis", // CallHistory // 
  t_numberHistory: "Nummergeschiedenis",
  t_notes: "Notities",
  t_oldNotes: "Oude notities",
  t_campaigns: "Campagnes", // Campaigns // 
  t_campaign: "Campagne",
  t_campaignName: "Campagnenaam",
  t_campaignList: "Campagnelijst",
  t_campaignhasBeenDeletedSuccessfully: "Campagne succesvol verwijderd.",
  t_campaignhasBeenUpdatedSuccessfully: "Campagne succesvol bijgewerkt.",
  t_campaignhasBeenCreatedSuccessfully: "Campagne succesvol aangemaakt.",
  t_customTabs: "Aangepaste tabbladen", // CustomTabs // 
  t_address: "Adres",
  t_landlinePrefix: "Vaste lijn Prefix",
  t_fctPrefix: "FCT Prefix",
  t_customTabList: "Aangepaste tabbladlijst",
  t_dataManagement: "Gegevensbeheer", // DataUpload // 
  t_productManagement: "Productbeheer",
  t_scriptManagement: "Scriptbeheer",
  t_exampleFile: "Voorbeeldbestand",
  t_uploadFile: "Bestand uploaden",
  t_SelectTheTitlesToUpdate: "Selecteer de titels om bij te werken",
  t_setUnmatchedRecordsToPassive: "Onverwerkte records naar passief zetten",
  t_setUnmatchedRecordsToUpdate: "Bijgewerkte telefoonnummers en producten",
  t_setAllPreviousInstitutionDataToPassive: "Alle gegevens van de instelling passief maken",
  t_updateMatchedRecords: "Overeenkomende records bijwerken",
  t_setAllRecordsOfProjectNameToPassive: "Alle gegevens van het project passief maken",
  t_upload: "Uploaden",
  t_0customersHasBeenUpdatedSuccessfully: "0 klanten zijn succesvol bijgewerkt.",
  t_customersHasBeenUpdatedSuccessfully: "klanten succesvol bijgewerkt.",
  t_customersCouldNotBeUploadedBecauseThereHasBeenAnError: "klanten konden niet worden geüpload vanwege een fout.",
  t_pleaseSelectAProject: "Klik om een project te selecteren",
  t_dialPlan: "Belplan", // DialPlan // 
  t_classifications: "Classificaties",
  t_simulation: "Simulatie",
  t_check: "Controleren",
  t_order: "Volgorde", //DIAL PLAN PRIORITY1 
  t_callingPatterns: "Belpatronen",
  t_dialPattern: "Doorschakelpatroon",
  t_simulatedCallCouldBePlacedFrom: "Uitgaande volgorde:",
  t_couldNotPlaceSimulationCall: "Gesprek kon niet worden geplaatst!",
  t_totalAgents: "Totaal", // Live Dashboard // 
  t_conference: "Conferentie",
  t_callsInQueue: "Wachtrij",
  t_abandonedCount: "Gemiste oproepen",
  t_hourlyAbandonedCount: "Gemiste oproepen per uur",
  t_statusLength: "Status duur",
  t_massChangeStatuses: "Bulkstatus wijzigen",
  t_textColour: "Tekstkleur",
  t_audioFile: "Audiobestand",
  t_type: "Type",
  t_confetti: "Confetti",
  t_champagne: "Champagne",
  t_emergency: "Noodgeval",
  t_blink: "Knipperen",
  t_wallboardControl: "Wallboard Controle",
  t_writtenSouffle: "Geschreven Soufflé",
  t_listen: "Luisteren",
  t_clap1: "Applaus1",
  t_clap2: "Applaus2",
  t_warning: "Waarschuwing",
  t_changeAllStatuses: "Alle statussen wijzigen",// Home.vue 
  t_showWentHome: "Toon wie naar huis is gegaan", // Home.vue 
  t_hideWentHome: "Verberg wie naar huis is gegaan", // Home.vue 
  t_showCalledBack: "Toon terugbelverzoeken", // Home.vue 
  t_reakTimeFraud: "Pauzeoverschrijding", // Home.vue 
  t_finishCodeCategory: "Categorie", // FinishCodeManagement // 
  t_categoryName: "Categorienaam",
  t_finishCodeCategorys: "Resultaatcodecategorieën",
  t_finishCodeName: "Weergavenaam",
  t_finishCodes: "Resultaatcodes",
  t_askForActionDate: "Vraag om datum",
  t_saveToCalendar: "Opslaan in agenda",
  t_contactCallableAfterwards: "Contacteerbaar",
  t_contactNumberCallableAfterwards: "Telefoonnummer contacteerbaar",
  t_countAsCallAttempt: "Tel als belpoging",
  t_lastFinishCode: "Laatste resultaatcode",
  t_deleteContactNumber: "Telefoonnummer verwijderen",
  t_assignToAgent: "Toewijzen aan agent",
  t_inboundRoutes: "Inkomende routes", // InboundRoutes // 
  t_standardRoute: "Standaard route",
  t_lineGroup: "Lijngroep", // LineGroup // 
  t_lineGroupName: "Naam lijngroep",
  t_lineGroups: "Lijngroepen",
  t_addedLines: "Toegevoegde lijnen",
  t_lineList: "Lijnenlijst", // Lines // 
  t_importLines: "Lijnen importeren",
  t_lineName: "Naam lijn",
  t_outboundCID: "Uitgaand nummer",
  t_transferCID: "Doorschakeling nummer",
  t_hide_ofline_users: "Offline gebruikers verbergen",
  t_ip: "IP",
  t_transportType: "Transporttype",
  t_type: "Type",
  t_prefix: "Voorvoegsel",
  t_inboundCallLimit: "Inkomende oproep limiet",
  t_outboundCallLimit: "Uitgaande oproep limiet",
  t_port: "Poort",
  t_codec: "Codec",
  t_fileUpload: "Bestand uploaden",
  t_file: "Bestand",
  t_selectTheFileYouWantToUpload: "Selecteer het bestand dat u wilt uploaden",
  t_pleaseEnterTheLineGroupName: "Voer een naam voor de lijngroep in.",
  t_youNeedToPickALineToBeAbleToCreateLineGroup: "U moet minstens één lijn selecteren om een ​​lijngroep te kunnen maken.",
  t_lineGroupHasBeenCreatedSuccessfully: "Lijngroep succesvol aangemaakt.",
  t_campaignManagement: "Campagnebeheer", // IvnDialer // 
  t_campaignControl: "Gespreksbeheer",
  t_stop: "Stop",
  t_recycle: "Herstart",
  t_calls: "Oproepen",
  t_basic: "Basis",
  t_scheduledCalls: "Geplande oproepen",
  t_contactList: "Contactlijst",
  t_programming: "Programmeren",
  t_priority: "Prioriteit",
  t_maxCall: "Maximaal aantal oproepen",
  t_callsPerAgent: "Oproepen per agent",
  t_statisticsPeriod: "Statistiekperiode (min)",
  t_callingMethod: "Uitgaande methode",
  t_noAnswerTimeout: "Timeout voor onbeantwoorde oproepen (sec)",
  t_maxAbandonRate: "Maximale gemiste oproep percentage (%)",
  t_dontConnectMachines: "Niet verbinden met machines",
  t_sortCallList: "Gesprekslijst sorteren",
  t_maxCycles: "Maximale cycli",
  t_recallDelayMins: "Vertragingsherinnering (min)",
  t_afterCall: "Na het gesprek",
  t_afterCallStatus: "Status na gesprek",
  t_maxAttempts: "Maximale pogingen",
  t_callstatisticsfortoday: "Gespreksstatistieken van vandaag",
  t_statusdurationfortoday: "Statusduur van vandaag",
  t_maximumRateOfCallsThatAreAbandonedFromTheQueue: "Maximale gemiste oproeppercentage van de wachtrij",
  t_maximumAmountOfCallsThisCampaignCanStart: "Maximale hoeveelheid oproepen die deze campagne kan starten",
  t_callsPerAvailableAgents: "Oproepen per beschikbare agent",
  t_refreshRateOfThisCampaignsStatisticsGraphs: "Verversingssnelheid van de campagnestatistiekgrafieken",
  t_whichLineGroupToMakeCallsFrom: "Lijngroep waaruit de campagne oproepen zal maken",
  t_maximumRingingTime: "Maximale beltijd bij de ontvanger",
  t_amountOfRoundsToCallPeopleWhoHaventBeenContactedYet: "Aantal rondes om mensen te bellen die nog niet zijn gecontacteerd",
  t_minimumAmountOfMinutesToRecallAPhoneNumber: "Minimale tijd (minuten) om een telefoonnummer opnieuw te bellen",
  t_maximumCallAmountPerPhoneNumberPerDay: "Maximaal aantal oproepen per telefoonnummer per dag",
  t_maximumCallAmountPerContactPerDay: "Maximaal aantal oproepen per contact per dag",
  t_maximumCallAmountPerPhoneNumber: "Maximaal aantal oproepen per telefoonnummer",
  t_maximumCallAmountPerContact: "Maximaal aantal oproepen per contact",
  t_doNotConnectMachineAnsweredCalls: "Verbind geen oproepen die door machines zijn beantwoord",
  t_dailyMaxPhoneTouch: "Dagelijkse maximale contacten telefoon",
  t_dailyMaxContactTouch: "Dagelijkse maximale contacten contact",
  t_totalMaxPhoneTouch: "Totale maximale contacten telefoon",
  t_totalMaxContactTouch: "Totale maximale contacten contact",
  t_justCallMobilePhone: "Bel alleen mobiele nummers",
  t_abandoned: "Gemist",
  t_timeCondition: "Tijdvoorwaarde",
  t_transferIVR: "Overdracht IVR",
  t_liveContacttIVR: "Live contact IVR",
  t_liveContact: "Live contact",
  t_noAvailableAgent: "Geen beschikbare agent", // Dialer.vue // 
  t_noAvailableAgentIVR: "Geen beschikbare agent IVR",
  t_rule: "Regel",
  t_campaignStatus: "Campagnestatus",
  t_campaignPriority: "Campagneprioriteit",
  t_campaignFilterCriteria: "Filtercriteria campagne",
  t_campaignSortingCriteria: "Sorteercriteria campagne",
  t_campaignRemainingCycles: "Resterende cycli",
  t_campaignCallPerAgent: "Oproepen per agent",
  t_campaignTotalCustomersToCall: "Totaal te bellen klanten",
  t_campaignTotalPhonesToCall: "Totaal te bellen telefoons",
  t_campaignRemainingPhonesInCycle: "Wachttijd in de cyclus",
  t_campaignTotalCalled: "Totaal gebeld",
  t_campaignTotalPhonesCalled: "Huidig gebeld",
  t_campaignConnectedCall: "Verbonden oproep",
  t_campaignWaitingInQueue: "Wachtrij oproep",
  t_campaignAbandonRate: "Afbreekpercentage %",
  t_resultNoAnswer: "Geen Antwoord",
  t_resultAbandon: "Afgebroken",
  t_resultBusy: "Bezet",
  t_resultCallRejected: "Oproep Geweigerd",
  t_resultFaultyCall: "Foute Oproep",
  t_resultNetworkOutOfOrder: "Netwerk Storing",
  t_resultFateUnknown: "Onbekend Lot",
  t_resultConnectivityError: "Verbindingsfout",
  t_resultFaultyNumber: "Fout Nummer",
  t_finishCodeStatistics: "Resultaatcodeverdeling",
  t_customersStatus: "Status",
  t_assigned_agent: "Agent",
  t_attempts: "Pogingen",
  t_dial_plan: "Belplan",
  t_phone_book: "Telefoonboek",
  t_ivr: "IVR",
  t_finish_code: "Resultaatcode",
  t_insert_date: "Oproepdatum",
  t_action_date: "Actiedatum",
  t_create_date: "Aanmaakdatum",
  t_ex_agent: "Voormalige Agent",
  t_assign_date: "Toewijzingsdatum",
  t_pleaseMakeASelectionForSorting: "Maak alstublieft een enkele selectie voor sorteren.",
  t_started: "Gestart",
  t_stopped: "Gestopt",
  t_recycling: "Wordt hergebruikt",
  t_isCompleted: "Voltooid",
  t_settingsHasBeenSavedSuccessfully: "Instellingen succesvol opgeslagen.",
  t_columns: "Kolommen", //Main.vue 
  t_row: "Rij",
  t_sort: "Sorteren", //Main.vue 
  t_algorithmicDialer: "Algoritmische Automatische Oproeper", // List.vue 
  t_basicDialer: "Basis Automatische Oproeper", // List.vue 
  t_filterManagement: "Filterbeheer", // IvnDialerFilter // 
  t_noFiltersForThisCampaign: "Geen filters voor deze campagne.", // IvnDialerFilter > t_fiterManagemt > t_value 
  t_condition: "Voorwaarde",
  t_equal: "Gelijk",
  t_notEqual: "Niet Gelijk",
  t_lessThan: "Minder dan",
  t_greaterThan: "Groter dan",
  t_lessThanOrEqual: "Minder dan of gelijk aan",
  t_greaterThanOrEqual: "Groter dan of gelijk aan",
  t_columnName: "Kolomnaam",
  t_columnNameStatus: "Status",
  t_columnNameAssignedAgent: "Toegewezen Agent",
  t_columnNameAttempts: "Pogingen",
  t_columnNameCampaign: "Campagne",
  t_columnNameFinishCode: "Laatste Resultaatcode",
  t_columnNameInsertDate: "Oproepdatum",
  t_columnNameActionDate: "Actiedatum",
  t_columnNameCreateDate: "Aanmaakdatum",
  t_columnNameExAgent: "Voormalige Agent",
  t_filterHasBeenCreatedSuccessfully: "Filter succesvol aangemaakt.",
  t_IVR: "IVR", // IvrManagement // 
  t_IVRName: "IVR Naam",
  t_IVRList: "IVR Lijst",
  t_DTMFdigit: "DTMF Toets",
  t_announcementFile: "Aankondigingsbestand",
  t_DTMFAccept: "DTMF Accepteren",
  t_extension: "Extensie",
  t_transfer: "Overdracht",
  t_language: "Taal",
  t_timeOutLengthSec: "Time-out duur (seconden)",
  t_timeOutCount: "Aantal Time-outs",
  t_maxInvalidInputCount: "Max aantal verkeerde invoer",
  t_acceptDTMFExtension: "Accepteer DTMF Extensie",
  t_turkish: "Turks",
  t_english: "Engels",
  t_arabic: "Arabisch",
  t_russian: "Russisch",
  t_hangup: "Ophangen",
  t_hangupCall: "Oproep Ophangen",
  t_incomingCall: "Inkomende Oproep",
  t_answer: "Beantwoorden",
  t_menu: "Menu",
  t_matched: "Gevonden:",
  t_unmatched: "Niet Gevonden:",
  t_hasBeenDeletedSuccessfully: "Succesvol verwijderd.",
  t_pleaseDoNotLeaveAnyBlankFields: "Laat geen velden leeg.",
  t_hasBeenUpdatedSuccessfully: "Succesvol bijgewerkt.",
  t_hasBeenCreatedSuccessfully: "Succesvol aangemaakt.",
  t_pleaseSelectTheBlackListDirection: "Selecteer alstublieft de richting voor de blokkering.", // blacklist 
  t_phoneBook: "Telefoonboek", // PhoneBook // 
  t_description: "Beschrijving",
  t_profiles: "Profielen", // ProfileQueueManagement // 
  t_profileName: "Profielnaam",
  t_affectedObjects: "Beïnvloede Objecten",
  t_permissions: "Toestemmingen",
  t_user: "Gebruiker",
  t_reset: "Reset",
  t_noPermissionsSelected: "Geen machtigingen geselecteerd.",
  t_profileList: "Profielenlijst",
  t_chat: "Chat",
  t_souffle: "Suflé",
  t_profileHasBeenDeletedSuccessfully: "Profiel succesvol verwijderd.",
  t_profileHasBeenUpdatedSuccessfully: "Profiel succesvol bijgewerkt.",
  t_profileHasBeenCreatedSuccessfully: "Profiel succesvol aangemaakt.",
  t_is_visible_in_live_dasboard: "Zichtbaar op het dashboard",
  t_projectName: "Projectnaam", // Projects // 
  t_afterCallWork: "Na Gesprek Werk (seconden)",
  t_cameraRecord: "Cameraregistratie",
  t_useableBreakTime: "Beschikbare Pauzetijd (minuten)",
  t_breakTime: "Pauzetijd",
  t_surname: "Achternaam",
  t_nameSurname: "Naam en Achternaam",
  t_projectList: "Projectlijst",
  t_projectHasBeenDeletedSuccessfully: "Project succesvol verwijderd.",
  t_projectHasBeenUpdatedSuccessfully: "Project succesvol bijgewerkt.",
  t_projectHasBeenCreatedSuccessfully: "Project succesvol aangemaakt.",
  t_queueNumber: "Wachtrij Nummer", // Queues - List.vue // 
  t_queueAgentTimeout: "Agent Time-out (seconden)",
  t_queueAgentTimeoutPlaceholder: "Maximale tijd dat een oproep bij een agent wacht",
  t_queueAnnounceFrequency: "Aankondigingsfrequentie (seconden)",
  t_timeBetweenAnnouncements: "Tijd tussen aankondigingen",
  t_queueAnnouncePosition: "Positie aankondiging",
  t_queueMaxlen: "Maximale Lengte",
  t_queuePenaltyMembersLimit: "Straf Ledenlimiet",
  t_queueRetry: "Opnieuw Proberen",
  t_queueMusicOnHold: "Wachtmuziek",
  t_queueName: "Wachtrijnaam",
  t_queueCallingMode: "Belmodus",
  t_queueStrategy: "Wachtrij Strategie",
  t_queueStrategyPlaceholder: "Verdeelsysteem voor oproepen onder agenten",
  t_queueWrapupTime: "Nabewerkings Tijd (seconden)",
  t_queueWrapupTimePlaceholder: "Minimale tijd tussen gesprekken voor agenten",
  t_queueAnnounceHoldTime: "Wachttijd Aankondiging",
  t_queueQueueHoldTime: "Automatische Wachttijd",
  t_queueMemberDelay: "Lid Vertraging",
  t_queuePeriodicAnnounceFrequency: "Periodieke Aankondigingsfrequentie",
  t_queueServiceLevel: "Serviceniveau",
  t_queueWeight: "Gewicht",
  t_queueList: "Wachtrijlijst",
  t_queueRrmemory: "Geheugenvolgorde (Aanbevolen)",
  t_queueRingall: "Iedereen tegelijk",
  t_queueLeastrecent: "Meest recent opgeroepen eerst",
  t_queueAgentWeight: "Agent Prioriteit",
  t_queueFewestcalls: "Agent met de minste oproepen eerst",
  t_queueRandom: "Willekeurig",
  t_queueManual: "Handmatig",
  t_queueDialer: "Dialer",
  t_serviceManagement: "Servicebeheer", // ServiceManagement // 
  t_dialerService: "Automatische Oproepdienst",
  t_restart: "Herstarten",
  t_callManager: "Oproepbeheer",
  t_chatService: "Chatdienst",
  t_smsSending: "SMS Verzending", //SMS// 
  t_smsTemplate: "Sjabloon",
  t_smsFilter: "Filter",
  t_filterName: "Filternaam", // SmsFilter // 
  t_noFilterHasBeenSelected: "Geen filter geselecteerd.",
  t_filterList: "Filterlijst",
  t_smsPageTitle: "Paginatitel", // SmsSender // 
  t_sendFromVoyceCRM: "Versturen vanuit Voyce CRM",
  t_sendFromExternalCRM: "Versturen vanuit extern CRM",
  t_sendFromCustomSms: "Versturen vanuit aangepaste SMS",
  t_smsPattern: "SMS Sjabloon",
  t_preview: "Voorbeeld",
  t_exampleText: "Voorbeeld Berichttekst",
  t_phoneNumbers: "Telefoonnummers",
  t_enterPhoneNumbersSeparatedByCommas: "Voer telefoonnummers in, gescheiden door komma's.",
  t_enterEmailsSeparatedByCommas: "Voer e-mailadressen in, gescheiden door komma's.",
  t_pleaseEnterAtLeastOnePhoneNumber: "Voer ten minste één telefoonnummer in.",
  t_pleaseEnterAMessage: "Voer een bericht in.",
  t_messagesHaveBeenDeliveredSuccessfully: "Berichten zijn succesvol afgeleverd.",
  t_thereWasAProblemDeliveringYourMessages: "Er is een probleem opgetreden bij het afleveren van uw berichten.",
  t_displayName: "Weergavenaam", // SmsSettings // 
  t_senderNumber: "Verzendnummer",
  t_accountCode: "Accountcode",
  t_apiKey: "API-sleutel",
  t_smsSettingList: "SMS Instellingenlijst",
  t_pattern: "Sjabloon",
  t_patternName: "Sjabloonnaam",
  t_smsText: "Berichttekst",
  t_patternList: "Sjabloonlijst",
  t_callCenter: "Callcenter", // Statistics // 
  t_statistics: "Statistieken",
  t_missedCall: "Gemiste Oproep",
  t_lunch: "Lunch",
  t_break: "Pauze",
  t_AVGInboundLengt: "Gemiddelde inkomende oproepduur",
  t_AVGOutboundLengt: "Gemiddelde uitgaande oproepduur",
  t_totalInbound: "Totaal Inkomend",
  t_totalOutbound: "Totaal Uitgaand",
  t_remainingBreakTime: "Resterende Pauzetijd",
  t_statusTime: "Statustijden",
  t_totalActionCounts: "Totale Actieaantallen",
  t_waitingInQueue: "In Wachtrij",
  t_numberofwaitingininstantqueue: "Aantal in de wachtrij op dit moment",
  t_allcallrecordsfortoday: "Alle oproepregistraties van vandaag",
  t_collectionTarget: "Doel",
  t_collectedMade: "Verzameld",
  t_workingDay: "Werkdag",
  t_present: "Aanwezig",
  t_absent: "Afwezig",
  t_actionDate: "Actiedatum",
  t_inCallOutbound: "In Oproep (Uitgaand)",
  t_inCallInbound: "In Oproep (Inkomend)",
  t_ringing: "Overgaan",
  t_noAnswer: "Geen antwoord",
  t_promiseToPay: "Betalingsbelofte",
  t_refuseToPay: "Betaling geweigerd",
  t_messageLeft: "Bericht achtergelaten",
  t_busy: "Bezet",
  t_statusCategory: "Statuscategorie", // StatusManagement // 
  t_statusCategorys: "Statuscategorieën",
  t_statusName: "Statusnaam",
  t_categoryKey: "Categorie Sleutel",
  t_categories: "Categorieën",
  t_changeable: "Wijzigbaar",
  t_onlySPVCanChange: "Alleen Supervisor kan wijzigen",
  t_active: "Actief",
  t_statusList: "Statuslijst",
  t_online: "Online",
  t_offline: "Offline",
  t_other: "Anders",
  t_important: "Belangrijk",
  t_apointment: "Afspraak",
  t_reminder: "Herinnering",
  t_personal: "Persoonlijk",
  t_personaly: "Persoonlijk",
  t_timeConditionName: "Tijd Voorwaarde Naam", // TimeConditionManagement - List.vue 
  t_startDay: "Startdag",
  t_startTime: "Starttijd",
  t_startingDayOfMonth: "Startdag van de maand",
  t_monthStart: "Begin van de maand",
  t_monthStartDay: "Startdag van de maand",
  t_monthEndDay: "Laatste dag van de maand",
  t_monthEnd: "Einde van de maand",
  t_endDay: "Einddag",
  t_endTime: "Eindtijd",
  t_endingDayOfMonth: "Einddag van de maand",
  t_timeConditions: "Tijdvoorwaarden",
  t_monday: "Maandag",
  t_tuesday: "Dinsdag",
  t_wednesday: "Woensdag",
  t_thursday: "Donderdag",
  t_friday: "Vrijdag",
  t_saturday: "Zaterdag",
  t_sunday: "Zondag",
  t_january: "Januari",
  t_february: "Februari",
  t_march: "Maart",
  t_april: "April",
  t_may: "Mei",
  t_june: "Juni",
  t_july: "Juli",
  t_august: "Augustus",
  t_september: "September",
  t_october: "Oktober",
  t_novenber: "November",
  t_december: "December",
  t_theBeginingConditionCanNotBeGraterThanEndingCondition: "De begintoestand mag niet groter zijn dan de eindtoestand.",
  t_userInformations: "Gebruikersinformatie",
  t_import: "Importeren", // UsersManagement // 
  t_importUsers: "Gebruikers Importeren", // UsersManagement // 
  t_importRecords: "Records Importeren", // UsersManagement // 
  t_userList: "Gebruikerslijst",
  t_tenantName: "Tenant Naam",
  t_recordingExport: "Audio Export", // VoiceExport.vue 
  t_recordingRemove: "Audio Verwijderen", // VoiceExport.vue 
  t_filters: "Filters",
  t_tags: "Labels",
  t_nameType: "Naam Formaat", // VoiceExport.vue 
  t_youCanAddCommaBetweenEmailAddressesToAddMore: "U kunt komma's gebruiken tussen e-mailadressen om er meer toe te voegen",
  t_youCanAddCommaBetweenPhoneToAddMore: "U kunt komma's gebruiken tussen telefoonnummers om er meer toe te voegen",
  t_startDate: "Startdatum",
  t_endDate: "Einddatum",
  t_namePattern: "Naam Patroon",
  t_targetMail: "Doel E-Mail",
  t_whenTheFilesAreReadyYouWillbeSentAnEMail: "Wanneer de bestanden gereed zijn, wordt er automatisch een e-mail naar u verzonden.",
  t_fileType: "Bestandsformaat",
  t_theProcessHasBeenStartedItWillBeSentByEmailWhenItIsCompleted: "Het proces is gestart en zal per e-mail worden verzonden zodra het voltooid is.",
  t_thereWasAProblemStartingTheProcess: "Er is een probleem opgetreden bij het starten van het proces.",
  t_process: "Proces", // VSPR // 
  t_deactiveCustomer: "Klant Deactiveren",
  t_activeCustomer: "Klant Activeren",
  t_assigneManager: "Manager Toewijzen",
  t_conclude: "Afsluiten",
  t_addingNote: "Notitie Toevoegen",
  t_deleteCallRecord: "Oproeprecord Verwijderen",
  t_matchingColumn: "Te Matchen Kolom", // components - VSPR // 
  t_pleaseFillTheAllNecessaryAreas: "Vul alstublieft alle verplichte velden in.",
  t_thereWasAProblemWhileAssigning: "Er is een probleem opgetreden bij het toewijzen.",
  t_doesNotMatchAnyCustomers: "komt niet overeen met klanten.",
  t_matchingColumnMustBeTheSameInTheExcelColumn: "De te matchen kolomnaam moet overeenkomen met de kolomnaam in de Excel-bestand.",
  t_AProblemOccurredwhileProcessing: "Er is een probleem opgetreden tijdens het verwerken.",
  t_customersHaveBeenSuccessfullyUpdated: "klanten zijn succesvol bijgewerkt.",
  t_customersHaveBeenSuccessfullyDeleted: "Klant succesvol verwijderd.",
  t_processSuccess: "Proces Succesvol",
  t_assigne: "Toewijzen", // AssignManager // 
  t_manager: "Manager",
  t_thereWasAProblemWhileAssigningManager: "Er is een probleem opgetreden bij het toewijzen van een manager.",
  t_managerHaveSuccessfullyBeenAssigned: "Manager succesvol toegewezen.",
  t_deactiveCustomersHaveSuccessfullyBeenDone: "sluitingen succesvol uitgevoerd.", // CloseCustomer // 
  t_VSPRCloseCustomer: "Sluit Klant",
  t_VSPROpenCustomer: "Open Klant",
  t_aProblemOccurredWhileConcluding: "Er is een probleem opgetreden tijdens het afsluiten.",
  t_customersHaveSuccessfullyBeenConcluded: "klanten zijn succesvol afgesloten.",
  t_thereWasAProblemActivatingCustomers: "Er is een probleem opgetreden bij het activeren van klanten.",
  t_customersHaveBeenSuccessfullyActivated: "klanten zijn succesvol geactiveerd.",
  t_deleteCallRecordings: "Oproeprecords Verwijderen",
  t_fileHasBeenImportedSuccessfully: "Bestand succesvol geïmporteerd.",
  t_makeAnAnnouncement: "Maak een Aankondiging", // Chat // 
  t_sendASouffle: "Verstuur Souffle",
  t_startChat: "Start Chat",
  t_open: "Openen",
  t_conversations: "Gesprekken",
  t_announcementHistory: "Aankondigingsgeschiedenis",
  t_totalCallTime: "Totale Oproeptijd",
  t_avgCallTime: "Gemiddelde Oproeptijd",
  t_avgAnswerTime: "Gemiddelde Antwoordtijd",
  t_avgHoldTime: "Gemiddelde Wachttijd",
  t_avgAcwTime: "Gemiddelde ACW Tijd",
  t_totalCallTimeDescrition: "KPI",
  t_avgCallTimeDescrition: "KPI",
  t_avgAnswerTimeDescrition: "KPI",
  t_avgHoldTimeDescrition: "KPI",
  t_avgAcwTimeDescrition: "KPI",
  t_noRecordOnQueue: "Geen oproepen in de wachtrij",
  t_callRecords: "Oproeprecords",
  t_secondLine: "Tweede Lijn",
  t_changeMode: "Wijzig Weergave",
  t_showNotConnected: "Niet Verbonden Weergeven",
  t_fullScreen: "Volledig Scherm",
  t_announcementPanel: "Aankondigingspaneel",
  t_yourMessage: "Uw Bericht",
  t_startAconversation: "Start een Gesprek",
  t_youGotAPrivateMessage: " heeft u een privébericht gestuurd.",
  t_startANewConversation: "Start een Nieuw Gesprek",
  t_updatePassword: "Wachtwoord Wijzigen", // Navbar // 
  t_PasswordCondition: "Uw wachtwoord moet minimaal 6 tekens bevatten, inclusief 1 letter en 1 cijfer.",
  t_lastAccountEvent: 'Laatste Accountactiviteit',
  t_events: "Gebeurtenissen",
  t_changePassword: 'Wijzig Wachtwoord',
  t_again: "Opnieuw",
  t_currentPassword: "Huidig Wachtwoord",
  t_newPassword: "Nieuw Wachtwoord",
  t_confirmPassword: "Bevestig Nieuw Wachtwoord",
  t_pleaseSelectfinishCode: "Selecteer alstublieft een resultaatcode",
  t_survey: "Enquête",
  t_passwordmustContainAtLeast1LetterAnd1Number: "Het wachtwoord moet minimaal 1 letter en 1 cijfer bevatten.",
  t_enteredpasswordsDoNotMatch: "De ingevoerde wachtwoorden komen niet overeen.",
  t_passwordMustBeAtLeast6CharactersLong: "Het wachtwoord moet minimaal 6 tekens lang zijn.",
  t_YourPasswordCannotBeTheSameAsYourOldPassword: "Uw nieuwe wachtwoord mag niet hetzelfde zijn als het oude wachtwoord.",
  t_PasswordHasBeenchangedSuccessfully: "Het wachtwoord is succesvol gewijzigd.",
  t_youHaveReconnectedToTheService: "U bent opnieuw verbonden met de service.",
  t_yourServiceConnectionHasBeenLost: "Uw serviceverbinding is verloren gegaan.",
  t_ACWNothingSelected: "Geen Actie Gekozen",
  t_ACWTimeOut: "ACW Tijd Afgelopen",
  t_rdialerconnectedtoagent: "Automatisch Gebeld Verbonden Oproep",
  t_rdialerabandon: "Automatisch Gebeld Afgebroken Oproep",
  t_color: "Kleur",
  t_view: "Weergave",
  t_allDays: "Alle Dagen",
  t_addEvent: "Evenement Toevoegen",
  t_eventName: "Evenement Naam",
  t_eventNote: "Evenement Notitie",
  t_eventDate: "Evenement Datum",
  t_eventTime: "Evenement Tijd",
  t_displayOnCRM: "Weergeven in CRM",
  t_monthly: "Maandelijks",
  t_weekly: "Wekelijks",
  t_daily: "Dagelijks",
  t_hourly: "Uurlijkse",
  t_mon: "Ma",
  t_tue: "Di",
  t_wed: "Wo",
  t_thu: "Do",
  t_fri: "Vr",
  t_sat: "Za",
  t_sun: "Zo",
  t_monSat: "Ma - Za",
  t_sunSat: "Zo - Za",
  t_monFri: "Ma - Vr",
  t_monWedFri: "Ma, Wo, Vr",
  t_eventhasBeenCreatedSuccessfully: "Evenement succesvol aangemaakt.",
  t_avaragequalitypoint: "Gemiddelde Kwaliteitsscore",
  t_liveDashboard: "Live Dashboard", // Menu // 
  t_mAlerts: 'Triggers',
  t_systemManagement: "Systeembeheer",
  t_operationManagement: "Operationeel Beheer",
  t_integrations: "Integraties",
  t_eMail: "E-Mail",
  t_qVoiceMail: "Voicemail",
  t_lastLogin: "Laatste Inlog",
  t_speechRule: 'Gespreksregel',
  t_addSpeechRule: 'Gespreksregel Toevoegen',
  t_lines: "Lijnen",
  t_routes: "Routes", // System Management ->  Main 
  t_vIVR: "Variabele IVR",
  t_backup: "Back-up",
  t_providers: "Providers",
  t_mVoice: "Spraak",
  t_reachedCalls: "Bereikte Oproepen",
  t_unreachedCalls: "Onbereikte Oproepen",
  t_missedCalls: "Gemiste Oproepen",
  t_outcomingCall: "Uitgaande Oproep",
  t_inboundCall: "Inkomende Oproep",
  t_incomingCallAvg: "Gemiddelde Inkomende Oproep",
  t_outgoingCallAvg: "Gemiddelde Uitgaande Oproep",
  t_show: "Tonen",
  t_entries: "records",
  t_addRecord: "Record Toevoegen",
  t_showing: "Weergegeven",
  t_title: "Titel",
  t_clear: "Wissen",
  t_perPage: "Per Pagina",
  t_uploadFileOnClick: "Klik hier om een bestand te uploaden",
  t_delete: "Verwijderen",
  t_noEditedRecord: "Geen te bewerken record gevonden.",
  t_noRecord: "Geen records om weer te geven",
  t_errorFetchData: "Er is een fout opgetreden bij het ophalen van gegevens.",
  t_backToList: "Terug naar de Lijst",
  t_deleteRecord: 'Record Verwijderen',
  t_updateRecord: 'Record Bijwerken',
  t_titles: 'Titels',
  t_searchPlaceHolder: 'Zoek op naam, klantnummer, productnummer, TCKN of telefoonnummer',
  t_customerId: 'Klant ID',
  t_breakStatus: 'Pauzestatus',
  t_targetStatus: 'Doel',
  t_mrAgentAssign: 'Agent Toewijzing',
  t_dataUpload: 'Gegevens Uploaden',
  t_customerList: 'Klantenlijst',
  t_customerListByPhone: 'Klantenlijst per Telefoon',
  t_customerListByProduct: 'Klantenlijst per Product',
  t_customerListByCSV: 'Klantenlijst CSV',
  t_vehicle: 'Voertuig',
  t_landRegistry: 'Kadaster',
  t_SSK: 'SSK',
  t_mernis: 'Mernis',
  t_gsmQuery: 'GSM Query',
  t_negotiation: 'Onderhandeling',
  t_products: 'Producten',
  t_phones: 'Telefoons',
  t_hourlyGraphic: 'Uurlijkse Grafiek',
  t_deleteFilter: 'Filter Verwijderen',
  t_addFilter: 'Filter Toevoegen',
  t_clearFilter: 'Filter Wissen',
  t_addNewRecord: 'Nieuw Record Toevoegen',
  t_selectableLines: "Selecteerbare Lijnen",
  t_selectedLines: "Gebruikte Lijnen",
  t_restartService: 'Weet u zeker dat u de service opnieuw wilt starten?',
  t_restartServiceSuccessfully: 'Service is succesvol opnieuw gestart.',
  t_id: 'ID',
  t_goal: 'Doel',
  t_goalManagementDescription: "Grafiekweergave",
  t_smscount: ' berichten worden verzonden. Bevestigt u dit?',
  t_call: 'Oproep',
  t_clearFilters: "Filters Wissen",  // CRM - Main.vue // 
  t_management: "Beheer",
  t_customerInformation: "Klantinformatie",
  t_customerUpdate: "Klantinformatie Bijwerken",
  t_extraInformation: "Extra Informatie",
  t_hide: "Verbergen",
  t_addAction: "Actie Toevoegen",
  t_addPhone: "Telefoon Toevoegen",
  t_addNote: "Notitie Toevoegen",
  t_landLine: "Vaste Lijn",
  t_addFinishCode: "Resultaatcode Toevoegen",
  t_addProductAction: "Productactie Toevoegen",
  t_actionDate2: "Actiedatum",
  t_installmentCount: "Aantal Termijnen",
  t_installmentAmount: "Termijnbedrag",
  t_relationTo: "Relatie Tot",
  t_isApproved: "Goedgekeurd",
  t_addData: "Gegevens Toevoegen",
  t_sendSMS: "SMS Verzenden",
  t_sendEmail: "E-mail Verzenden",
  t_filterTheResults: "Filter de Resultaten",
  t_filterBy: "Filter op",
  t_pleaseWriteSomethingToSeeYourOptions: "Schrijf iets om uw opties te zien.",
  t_columnVisibilityOptions: "Kolomweergaveopties",
  t_showHide: "(Weergeven/Verbergen)",
  t_agentAssign: 'Agent Toewijzen',
  t_assigmnentType: "Toewijzingstype",
  t_contactPool: 'Pool',
  t_sender: "Verzender",
  t_shuffle: "Schudden",
  t_assignForProductAgent: 'Toewijzen voor Productagent',
  t_progresingDate: "Verwerkingsdatum",
  t_allUnassignedCasesWillBeAssignedToSelectedAgentsAreYouSure: "Alle niet toegewezen gevallen worden toegewezen aan de geselecteerde agenten. Bevestigt u dit?",
  t_amountOfCasesWillBeUpdatedAreYouSure: "gevallen worden bijgewerkt. Bevestigt u dit?",
  t_casesCouldNotBeAssigned: "gevallen konden niet worden toegewezen.",
  t_customersHaveSuccessfullyBeenAssigned: "gevallen succesvol toegewezen.",
  t_error: "Fout",
  t_failed: "Fout Opgetreden",
  t_reportDetails: 'Rapport Details', // Reports.vue 
  t_summary: "Samenvatting",
  t_today: "Vandaag", // Reports.vue 
  t_yesterday: "Gisteren", // Reports.vue 
  t_last7Days: "Laatste 7 Dagen", // Reports.vue 
  t_last30Days: "Laatste 30 Dagen", // Reports.vue 
  t_betweenDates: "Tussen Data", // Reports.vue
  t_reports: 'Rapporten', //Main.vue
  t_reportType: 'Rapport Type',
  t_callDetails: "Oproep Details", // Report Names // 
  t_mrInboundCallDetails: "Inkomende Oproep Details",
  t_mrSL30Daily: "SL30 Dagelijks",
  t_mrSL30Monthly: "SL30 Maandelijks",
  t_mrSL30Hourly: "SL30 Uurlijks",
  t_mrAbandonedCalls: "Verlaten Oproepen",
  t_mrAfterWorkInboundCalls: "Inkomende Oproepen Buiten Werktijd",
  t_mrTotalCalls: "Totale Oproepen",
  t_extraTime: "Extra Tijd",
  t_giveExtraTime: "Extra Tijd Geven",
  t_time: "Tijd (00:00:00)",
  t_mrCallDurationDetails: "Oproep Duur Details",
  t_mrCallDurationSummary: "Oproep Duur Samenvatting",
  t_mrInboundCallDaily: "Dagelijkse Inkomende Oproepen",
  t_mrInboundCallHourly: "Uurlijks Inkomende Oproepen",
  t_surveyDetails: "Enquête Details",
  t_mrAgent: "Agent",
  t_mrAgentActivity: "Agent Activiteit",
  t_mrAgentCallCountsDaily: "Agent Oproep Aantallen Dagelijks",
  t_mrAgentCallCountsByDate: "Agent Oproep Aantallen per Datum",
  t_mrAgentWork: "Agent Werk",
  t_mrAgentCalls: "Agent Oproepen",
  t_mrAgentDaily: "Dagelijkse Agent Oproepen",
  t_mrAgentHourly: "Uurlijks Agent Oproepen",
  t_mrAgentCalendar: "Agent Kalender Details",
  t_mrIvnDialerCallAnalyze: "Campagne Analyse",
  t_mrIvnDialerCallDetails: "Campagne Oproep Details",
  t_called: "Geraadpleegd", // Dashboard.vue 
  t_closed: "Gesloten",
  t_backedupFiles: "Geback-upte Bestanden", // Backup.vue 
  t_pleaseSelectTheFileToUpload: "Selecteer het bestand om te uploaden naar de database",
  t_createBackup: "Back-up Maken",
  t_exportToDatabase: "Exporteren naar Database",
  t_filter: "Filter", // GLOBAL // 
  t_settings: 'Instellingen',
  t_action: "Actie",
  t_note: "Notitie",
  t_column: "Kolom",
  t_value: "Waarde",
  t_operator: "Operator",
  t_users: "Gebruikers",
  t_agents: "Agenten",
  t_date: "Datum",
  t_agent: "Agent", // Home.vue 
  t_finishCode: "Resulta",
  t_direction: "Richting",
  t_defaultRoute: "Standaard Route",
  t_editSelected: "Geselecteerde Bewerken",
  t_crm: 'CRM',
  t_calendar: 'Kalender',
  t_dialer: "Automatische Oproep",
  t_goalManagement: 'Doelbeheer',
  t_line: 'Lijn',
  t_dialPlans: 'Belplannen',
  t_queues: 'Wachtrijen',
  t_projects: 'Projecten',
  t_statuses: 'Statussen',
  t_announce: 'Aankondiging',
  t_createScript: 'Gespreksscript Aanmaken',
  t_queueTimeOut: 'Wachtrij Tijdslimiet',
  t_detinationType: 'Bestemmingstype',
  t_detinationTarget: 'Bestemming',
  t_showInDashboard: 'Weergeven in Dashboard',
  t_reject: 'Afwijzen',
  t_internalCall: 'Intern Oproep',
  t_isProductFinishCode: 'Product Resultaatcode',
  t_queueGroup: 'Wachtrij Groep', //Home.vue 
  t_agentGroup: 'Agent Groep', //Home.vue 
  t_selectableQueues: "Selecteerbare Wachtrijen",
  t_selectedQueues: "Geselecteerde Wachtrijen",
  t_isHoldSound: 'Wachttijd Muziek',
  t_waitTime: 'Wachttijd',
  t_isReached: 'Telefoon Beantwoord',
  t_addCustomer: 'Klant Toevoegen',
  t_phonesUpdate: "Telefoons Bijwerken",
  t_phonesinfoUpdate: "Telefooninformatie Bijwerken",
  t_isAutoEmail: 'Automatisch E-mailen',
  t_emailTemplate: 'E-mail Sjabloon',
  t_account: 'Account',
  t_security: 'Beveiliging',
  t_performance: 'Prestaties',
  t_emailDelayAmount: 'Vertraging (minuten)',
  t_isAutoSMS: 'Automatisch SMS Verzenden',
  t_smsDelayAmount: 'Vertraging (minuten)',
  t_autoAnswer: 'Automatisch Beantwoorden',
  t_isUnassign: 'Toewijzing Verwijderen',
  t_isPassive: 'Passief Maken',
  t_setAttemptsByPhone: 'Pogingen Bepalen op Basis van Telefoongeschiedenis',
  t_recordingFile: 'Opname',
  t_callStrategy: 'Oproepstrategie',
  t_sortCriteria: 'Sorteercriteria',
  t_internationalCall: 'Internationale Oproep',
  t_dialerPrecall: 'Dialer Vooroproep',
  t_variable: 'Variabele',
  t_variableLength: 'Variabele Lengte',
  t_reportFiles: 'Rapportbestanden', // Main.vue 
  t_schedule: 'Schema',
  t_scheduler: 'Planner',
  t_schedulerTasks: 'Geplande Taken',
  t_schedulerCategory: 'Planner Categorie',
  t_reportCategory: 'Rapportcategorie',
  t_lastXDays: 'Laatste X Dagen',
  t_communicationType: 'Communicatietype',
  t_communicationSrc: 'Communicatiebron',
  t_communicationTitle: 'Titel',
  t_to: 'Ontvanger (To)',
  t_cc: 'Carbon Copy (CC)',
  t_youCanAddCommaBetweenEmailAddressesToaddMore: "Voeg komma's tussen e-mailadressen toe om meer te versturen",
  t_email: 'E-mail',
  t_mobileNotification: 'Mobiele Notificatie',
  t_callPlaced: 'Oproep Geplaatst',
  t_callWentToAgent: 'In Gesprek',
  t_callJoinedQueue: 'In Wachtrij',
  t_callPickedUp: 'Opgenomen',
  t_callRejected: 'Afgewezen',
  t_schedulerTime: 'Planningstijd',
  t_schedulerTimeString: 'Planningstijd Beschrijving',
  t_creator: 'Aanmaken Door',
  t_nextRunTime: 'Volgende Uitvoertijd',
  t_externalChat: 'Schriftelijk Kanaal',
  t_start: "Starten", // IvnDialer // 
  t_selectAll: "Alles Selecteren", // CRM - Main.vue - Home.vue // 
  t_clearAll: "Alles Wissen", // Main.vue 
  t_queue: "Wachtrij", // Home.vue - Reports.Vue 
  t_announcement: "Aankondiging", //ProfileQueueManagement - Home.vue // 
  t_duration: "Duur", //Statistics - Home.vue 
  t_productivity: "Productiviteit", //Statistics - Home.vue 
  t_status: "Status", // Home.vue 
  t_usedBreakTime: "Gebruikte Pauze", //Statistics - Home.vue 
  t_answered: "Beantwoord",  // Statistic.vue - Wallboard.vue - Home.vue 
  t_successfullCall: "Bereikt", //Statistics - Home.vue - Dialer.vue 
  t_unsuccessfullCall: "Niet Bereikt", //Statistics - Home.vue - Dialer.vue 
  t_export: "Exporteren", // Home.vue - List.vue - Reports.vue 
  t_total: "Totaal", // Dialer.vue 
  t_rate: "Percentage (%)", // Statistic.vue - Home.vue - Dialer.vue 
  t_send: "Verzenden", //SmsFilter 
  t_next: 'Volgende',
  t_previous: 'Vorige',
  t_classification: "Classificatie", // List.vue - ListAddNew.vue 
  t_available: "Beschikbaar", // Wallboard.vue - Home.vue / Dialer.vue 
  t_inCall: "In Gesprek", // Wallboard.vue - Home.vue 
  t_paused: "In Pauze",  // Wallboard.vue - Home.vue 
  t_profile: "Profiel",
  t_no: "Nee",
  t_yes: "Ja",
  t_report: 'Rapport',
  t_actions: "Acties", // All 
  t_cancel: "Annuleren", // All 
  t_save: "Opslaan", // All 
  t_category: "Categorie", // All 
  t_project: "Project", // All 
  t_close: "Sluiten", // All 
  t_name: "Naam", // All 
  t_sms: 'SMS', // All 
  t_general: 'Algemeen',
  t_loginPageImages: 'Inlogpagina Afbeeldingen',
  t_browse: 'Bladeren',
  t_thisMonth: 'Deze Maand',
  t_lastMonth: 'Vorige Maand',
  t_yourReportAddToReportQueue: 'Uw rapport is aan de rapportwachtrij toegevoegd',
  t_notification: 'Notificatie',
  t_notifications: 'Notificaties',
  t_pleaseSelectReport: 'Selecteer alstublieft een rapport',
  t_host: 'Host',
  t_subject: 'Onderwerp',
  t_isSendMailToCustomer: 'E-mail naar klant sturen',
  t_surveyCancel: 'Enquête Annuleren',
  t_surveyApprove: 'Enquête Goedkeuren',
  t_sendType: 'Verzendtype',
  t_info: 'Informatie',
  t_pleaseSelectTimeCondition: 'Selecteer alstublieft een tijdsvoorwaarde',
  t_crmWhiteColorInfo: 'Onaangeraakt',
  t_crmInfoColorInfo: 'Aangeraakt en Belbaar',
  t_crmWarningColorInfo: 'Aangeraakt en Onbelbaar',
  t_crmSecondaryColorInfo: 'Aangeraakt en Gesloten',
  t_crmDangerColorInfo: 'Onaangeraakt en Gesloten',
  t_nextCustomer: 'Volgende Klant',
  t_lineChangeInfo: 'Sla alstublieft uw campagnes opnieuw op als u de betreffende lijngroepen gebruikt.',
  t_deleteData: 'Data Verwijderen',
  t_closedByDailyAttempts: 'Gesloten door Dagelijkse Pogingen',
  t_closedByTotalAttempts: 'Gesloten door Totale Pogingen',
  t_resetScheduledCalls: 'Geplande Oproepen Resetten',
  t_resetDailyAttempts: 'Dagelijkse Pogingen Resetten',
  t_resetTotalAttempts: 'Totale Pogingen Resetten',
  t_detail: "Details",
  t_spokenCall: 'Gesproken',
  t_spokenCallRate: 'Gesproken%',
  t_nonSpokenCall: 'Niet Gesproken',
  t_nAttemptsCall: 'Geen Pogingen',
  t_attemptsRate: 'Pogingen%',
  t_reachRate: 'Bereik%',
  t_totalCallInfo: 'Totaal aantal oproepen',
  t_spokenCallInfo: 'Aantal gesproken oproepen door agent',
  t_spokenRateInfo: 'Verhouding van gesproken oproepen tot totale oproepen',
  t_noSpokenCallInfo: 'Aantal oproepen zonder gesprek',
  t_noAttemptsCallInfo: 'Oproepen zonder poging van operator',
  t_attemptsRateInfo: 'Percentage van oproepen met poging van operator',
  t_reachRateInfo: 'Verhouding van beantwoorde oproepen tot totale oproepen',
  t_username: 'Gebruikersnaam',
  t_examples: 'Voorbeelden',
  t_cron: 'Cron-taken',
  t_cron1: "Elke minuut",
  t_cron2: "Elke uur",
  t_cron3: "Elke dag om middernacht",
  t_cron4: "Elke dag om 17:00 uur",
  t_cron5: "Elke zondag om middernacht",
  t_cron6: "Elke eerste dag van de maand om middernacht",
  t_cron7: "Elke eerste dag van het jaar om middernacht",
  t_cron8: "Elke werkdag om middernacht",
  t_cron9: "Elke 15e van de maand om middernacht",
  t_cron10: "Elke eerste maandag van het jaar om middernacht",
  t_cron11: "Op de 15e van januari, juni en december om middernacht",
  t_cron12: "Eerste werkdag van de maand om middernacht",
  t_cron13: "Weekends om middernacht",
  t_alert: 'Alarm',
  t_lessThan: 'Kleiner dan',
  t_lessThanOrEqual: 'Kleiner dan of gelijk aan',
  t_moreThanOrEqual: 'Groter dan of gelijk aan',
  t_rConferenceEvent: 'Conferentie',
  t_rConferenceParticipants: 'Conferentie Deelnemers',
  t_rQualityFormNote: 'Kwaliteitsform Notitie',
  t_rInboundFailSL3059: 'Gemiste Oproepen binnen 30/59sec',
  t_rInboundFailSL60119: 'Gemiste Oproepen binnen 60/119sec',
  t_rInboundFailSL120179: 'Gemiste Oproepen binnen 120/179sec',
  t_rInboundFailSL180: 'Gemiste Oproepen boven 180sec',
  t_rIvrWaitLength: 'IVR Wachttijd',
  t_rExtra: 'Extra Informatie',
  t_rSender: 'Afzender',
  t_rMessage: 'Bericht',
  t_rEvaluateEvent: 'Evaluatie',
  t_rEvaluateTime: 'Evaluatie Tijd',
  t_rQualityFormName: 'Formuliernaam',
  t_callAnalyze: 'Oproepanalyse',
  t_rObjectionEvent: 'Bezwaar',
  t_rPunishEvent: 'Straf',
  t_rQualityPoint: 'Kwaliteitspunten',
  t_rMailSender: "Afzender",
  t_rSubject: "Onderwerp",
  t_rBody: "Berichtinhoud",
  t_rTo: 'Ontvanger (To)',
  t_rCC: 'Carbon Copy (Cc)',
  t_rState: "Status",
  t_rStartTime: "Starttijd",
  t_rEndTime: "Eindtijd",
  t_rProductivity: "Productiviteit",
  t_rRemoteNumber: "Telefoonnummer",
  t_rAgent: "Agent",
  t_rDirection: "Richting",
  t_rQueue: "Wachtrij",
  t_rVerdict: "Resultaatcode",
  t_rActionDate: "Actiedatum",
  t_rNote: "Notitie",
  t_rConnectedLength: "Gesprekstijd",
  t_rQueueWaitLength: "Wachttijd in Wachtrij",
  t_rHoldLength: "Holdtijd",
  t_rAcwLength: "ACW Tijd",
  t_rRecordingFile: "Bestandsnaam",
  t_rTerminateSide: "Opzegger",
  t_rRecordingFileName: "Opname",
  t_rLocalNumber: "Lijn",
  t_rLineName: "Lijnnaam",
  t_rClassification: "Classificatie",
  t_rCampaign: "Campagne",
  t_rInteractionID: "Oproep ID",
  t_rMüşteriNo: "Klantnummer",
  t_rAdıSoyadı: "Naam Achternaam",
  t_rIsCalledBack: "Terugbellen Geweest",
  t_rCalledBackedStartTime: "Terugbeltijd",
  t_rCalledBackedQueue: "Wachtrij bij Terugbellen",
  t_rUsername: "Agent",
  t_rInboundSuccess: "Beantwoorde Oproepen",
  t_rInboundTotal: "Totaal Inkomende Oproepen",
  t_rTotal: "Totaal",
  t_rLogin: "Inloggen",
  t_rLogout: "Uitloggen",
  t_rAvgCallTime: "Gemiddelde Gesprekstijd",
  t_rOnlineTime: "Ingelogde Tijd",
  t_rInCallTime: "Totale Gesprekstijd",
  t_rInboundSuccessSL: "Beantwoorde SL Oproepen",
  t_rInboundFail: "Gemiste Oproepen",
  t_rOutboundSuccess: "Succesvolle Uitgaande Oproepen",
  t_rOutboundFail: "Mislukte Uitgaande Oproepen",
  t_rTotalWaitTime: "Totale Wachttijd",
  t_rAvgWaitTime: "Gemiddelde Wachttijd",
  t_rMaxWaitTime: "Maximale Wachttijd",
  t_rAgentMissed: "Gemiste Oproepen door Agent",
  t_rInitMoment: "Datum",
  t_rCount: "Aantal",
  t_rDate: "Datum",
  t_rCustomerID: "Klantnummer",
  t_rProductID: "Productnummer",
  t_rInboundSuccessRate: "Beantwoordpercentage",
  t_rDialerSuccess: "Succesvolle Automatische Oproep",
  t_rDialerFail: "Mislukte Automatische Oproep",
  t_rDialerTotal: "Totaal Automatische Oproepen",
  t_rDialerTotalTalkTime: "Totale Gesprekstijd Automatische Oproep",
  t_rDialerTotalIVRTime: "Totale IVR-tijd Automatische Oproep",
  t_rDialerAvgIVRTime: "Gemiddelde IVR-tijd Automatische Oproep",
  t_rDialerAvgTalkTime: "Gemiddelde Gesprekstijd Automatische Oproep",
  t_rTotalAvgCallTime: "Gemiddelde Oproeptijd",
  t_rTotalCallTime: "Totale Oproeptijd",
  t_rTotalSuccessCallCount: "Aantal Succesvolle Oproepen",
  t_rTotalCallCount: "Totaal Aantal Oproepen",
  t_rDialerTotalCallTime: "Totale Oproeptijd Automatische Oproep",
  t_rDialerAvgCallTime: "Gemiddelde Oproeptijd Automatische Oproep",
  t_rTotalSuccess: "Totaal Geslaagd",
  t_rTotalTalkTime: "Totale Gesprekstijd",
  t_rTotalAvgTalkTime: "Gemiddelde Gesprekstijd",
  t_rTotalCustomer: "Totaal Aantal Klanten",
  t_rCallable: "Kan Gebeld Worden",
  t_rUnCallable: "Afgerond",
  t_rZeroAttempts: "Nog Niet Gebeld",
  t_rStartDate: "Startdatum",
  t_rAgentCount: "Aantal Agenten",
  t_rIvrMissed: "Gemiste IVR Oproepen",
  t_rInboundAgentTotal: "Totaal Inkomende Oproepen bij Agent",
  t_rInboundSuccessSLA30: "Beantwoorde Oproepen binnen 30 seconden",
  t_rInboundSuccessSL120: "Beantwoorde Oproepen binnen 120 seconden",
  t_rInboundSuccessSLA120: "Beantwoorde Oproepen boven 120 seconden",
  t_rInboundFailSL30: "Gemiste Oproepen binnen 30 seconden",
  t_rInboundFailSLA30: "Gemiste Oproepen boven 30 seconden",
  t_rInboundSuccessSLA30AvgWaitTime: "Gemiddelde Wachttijd voor Beantwoorde Oproepen na 30 seconden",
  t_rInboundSuccessSLA120AvgWaitTime: "Gemiddelde Wachttijd voor Beantwoorde Oproepen na 120 seconden",
  t_rAvgWaitTimeSLA30: "Gemiddelde Wachttijd voor Gemiste Oproepen na 30 seconden",
  t_rSuccessRate: "Succespercentage",
  t_rServiceLevel: "Serviceniveau",
  t_rTotalConnectedLength: "Totale Gesprekstijd",
  t_rAvgConnectedLength: "Gemiddelde Gesprekstijd",
  t_rTotalSuccessCount: "Aantal Succesvolle Gesprekken",
  t_rBaseRemoteNumber: "Telefoonnummer",
  t_rTotalCall: "Totaal Aantal Oproepen",
  t_rSuccessCall: "Succesvolle Oproepen",
  t_rFailCall: "Mislukte Oproepen / Gemiste Oproepen",
  t_rAMD: "Antwoordapparaat",
  t_rTotalCallLength: "Totale Gesprekstijd",
  t_rFirstCallTime: "Tijdstip Eerste Oproep",
  t_rLastCallTime: "Tijdstip Laatste Oproep",
  t_rAbandon: "Verlaten",
  t_rSaleCall: "Verkoop",
  t_rEventTime: "Tijdstip",
  t_rEvent: "Gebeurtenis",
  t_rPrevStatus: "Vorige Status",
  t_rPrevReason: "Vorige Reden",
  t_rPrevStatusLength: "Statusduur (seconden)",
  t_rInboundSL30: "SL30",
  t_rInboundAnswerRate: "Beantwoordpercentage",
  t_rInboundAvgCallTime: "Gemiddelde Gesprekstijd Inkomend",
  t_rOutboundAvgCallTime: "Gemiddelde Gesprekstijd Uitgaand",
  t_rInterval: "Uur",
  t_rInboundTotalCallTime: "Totale Gesprekstijd Inkomend",
  t_rOutboundTotalCallTime: "Totale Gesprekstijd Uitgaand",
  t_rDayName: "Dag",
  t_rInboundSuccessSL30: "Beantwoorde Oproepen binnen 30 seconden",
  t_rInboundSuccessSL30Rate: "Beantwoordpercentage binnen 30 seconden",
  t_rInboundFailSL10: "Gemiste Oproepen binnen 10 seconden",
  t_rInboundSuccessRateSL30: "Beantwoordpercentage SL30",
  t_rInboundFailRate: "Mislukte Oproepen Percentage",
  t_rInboundAvgTalkTime: "Gemiddelde Gesprekstijd Inkomend",
  t_rInboundTotalTalkTime: "Totale Gesprekstijd Inkomend",
  t_rInboundAvgAnswerTime: "Gemiddelde Beantwoordingstijd",
  t_rOutboundTotal: "Totale Uitgaande Oproepen",
  t_rOutboundAvgTalkTime: "Gemiddelde Gesprekstijd Uitgaand",
  t_rOutboundTotalTalkTime: "Totale Gesprekstijd Uitgaand",
  t_rCreateDate: "Aanmaakdatum",
  t_rAssignedAgent: "Toegewezen Agent",
  t_rLine: "Lijn",
  t_rSuccessCalls: "Succesvolle Oproepen",
  t_rFailCalls: "Mislukte Oproepen",
  t_rHour: "Uur",
  t_rAgentSentimentPositiveSegmentCount: "Aantal Positieve Segmenten van Agent",
  t_rAgentSentimentPositiveScore: "Positieve Score van Agent",
  t_rAgentSentimentPositiveTokenCount: "Aantal Positieve Tokens van Agent",
  t_rAgentSentimentNegativeSegmentCount: "Aantal Negatieve Segmenten van Agent",
  t_rAgentSentimentNegativeScore: "Negatieve Score van Agent",
  t_rAgentSentimentNegativeTokenCount: "Aantal Negatieve Tokens van Agent",
  t_rAgentSentimentTotalScore: "Totale Score van Agent",
  t_rAgentSentimentPositiveSegmentRate: "Positieve Segment Percentage van Agent(%)",
  t_rAgentSentimentNegativeSegmentRate: "Negatieve Segment Percentage van Agent(%)",
  t_rAgentSentimentNotrSegmentRate: "Neutrale Segment Percentage van Agent(%)",
  t_rCustomerSentimentPositiveSegmentCount: "Aantal Positieve Segmenten van Klant",
  t_rCustomerSentimentPositiveScore: "Positieve Score van Klant",
  t_rCustomerSentimentPositiveTokenCount: "Aantal Positieve Tokens van Klant",
  t_rCustomerSentimentNegativeSegmentCount: "Aantal Negatieve Segmenten van Klant",
  t_rCustomerSentimentNegativeScore: "Negatieve Score van Klant",
  t_rCustomerSentimentNegativeTokenCount: "Aantal Negatieve Tokens van Klant",
  t_rCustomerSentimentTotalScore: "Totale Score van Klant",
  t_rCustomerSentimentPositiveSegmentRate: "Positieve Segment Percentage van Klant(%)",
  t_rCustomerSentimentNegativeSegmentRate: "Negatieve Segment Percentage van Klant(%)",
  t_rCustomerSentimentNotrSegmentRate: "Neutrale Segment Percentage van Klant(%)",
  t_rTotalDuration: "Totale Gesprekstijd",
  t_rOverlappedSpeechRatio: "Overlap van Spraak (%)",
  t_rOverlappedSpeechDuration: "Overlap van Spraakduur (seconden)",
  t_rOverlappedSilenceRatio: "Stiltepercentage",
  t_rOverlappedSilenceDuration: "Stilteduur (seconden)",
  t_rAgentSpeechRatio: "Spraakpercentage van Agent",
  t_rAgentSpeechDuration: "Gesproken Tijd van Agent (seconden)",
  t_rAgentSpeakingRate: "Spreeksnelheid van Agent (Woorden per Minuut)",
  t_rAgentInterruptCount: "Onderbrekingen door Agent",
  t_rCustomerSpeechRatio: "Spraakpercentage van Klant",
  t_rCustomerSpeechDuration: "Gesproken Tijd van Klant (seconden)",
  t_rCustomerSpeakingRate: "Spreeksnelheid van Klant (Woorden per Minuut)",
  t_rCustomerInterruptCount: "Onderbrekingen door Klant",
  t_rListens: "Beluisterd",
  t_rTotalEvaluations: "Totaal Aantal Evaluaties",
  t_rAutoEvaluations: "Automatisch Beoordeeld",
  t_rEvaluations: "Handmatig Beoordeeld",
  t_rAvgAutoEvaluationsPoint: "Gemiddelde Punten van Automatische Evaluaties",
  t_rAvgEvaluationsPoint: "Gemiddelde Punten van Handmatige Evaluaties",
  t_rTotalCalls: "Totaal Aantal Oproepen",
  t_rOverlappedSilence: "Aantal Gesprekken met Tweezijdige Stilte",
  t_rOverlappedSilenceRateByTotalCalls: "Tweezijdige Stilte / Totale Oproepen(%)",
  t_rOverlappedSilenceRateBySuccessCalls: "Tweezijdige Stilte / Succesvolle Oproepen(%)",
  t_rClosedByAgentCount: "Gesloten door Agent",
  t_rClosedByCustomerCount: "Gesloten door Klant",
  t_rClosedByAgentRateByTotalCalls: "Gesloten door Agent / Totale Oproepen(%)",
  t_rClosedByCustomerRateByTotalCalls: "Gesloten door Klant / Totale Oproepen(%)",
  t_rForbiddenWords: "Gebruik van Verboden Woorden",
  t_rForbiddenWordsRateByTotalCalls: "Verboden Woorden / Totale Oproepen(%)",
  t_rForbiddenWordsRateBySuccessCalls: "Verboden Woorden / Succesvolle Oproepen(%)",
  t_rAgentNoSpeechCount: "Aantal Gesprekken Waarbij Klant Sprak en Agent Niet",
  t_rAgentNoSpeechRateByTotalCalls: "Gesprekken Waarbij Klant Sprak en Agent Niet / Totale Oproepen(%)",
  t_rAgentNoSpeechRateBySuccessCalls: "Gesprekken Waarbij Klant Sprak en Agent Niet / Succesvolle Oproepen(%)",
  t_rAvgAHT: "Gemiddelde AHT",
  t_rMaxAHT: "Maximale Gemiddelde AHT",
  t_rMinAHT: "Minimale Gemiddelde AHT",
  t_rDuration: "Gesprekstijd",
  t_rListeningDuration: "Luistertijd",
  t_rPlayCount: "Aantal Keer Afspelen",
  t_rPauseCount: "Aantal Keer Pauzeren",
  t_rIsMuted: "Is Dempen Geactiveerd?",
  t_rPlayBackChangeCount: "Aantal Keer Snelheid Wijzigen",
  t_rPlayBackStartSpeed: "Start Afspeelsnelheid",
  t_rPlayBackEndSpeed: "Eind Afspeelsnelheid",
  t_rManualForm: "Handmatig Formulier",
  t_rManualFormPoint: "Punten van Handmatig Formulier",
  t_rAutoForm: "Automatisch Formulier",
  t_rAutoFormPoint: "Punten van Automatisch Formulier",
  t_rQualityNote: "Kwaliteitsnotitie",
  t_abandonedCampaign: "Campagne voor Verlaten Oproepen",
  t_autoRecycle: "Automatische Herhaling",
  t_goalListByAgents: "Agent Doelenlijst",
  t_addGoal: "Doel Toevoegen",
  t_addCollection: "Verzameling Toevoegen",
  t_collections: "Verzamelingen",
  t_archivedMessages: "Gearchiveerde Berichten",
  t_archivetedMessages: "Gearchiveerde Berichten",
  t_rQualityDetails: "Kwaliteitsdetails",
  t_rInboundCallDetails: "Details Inkomende Oproepen",
  t_rCallTimeAndCounts: "Gesprekstijd en Aantallen",
  t_rCallDetails: "Oproepdetails",
  t_rClassificationDetails: "Classificatiedetails",
  t_rAgentPerformanceSummary: "Prestatieoverzicht Agent",
  t_rAgentPerformanceSummaryByShift: "Prestatieoverzicht Agent per Shift",
  t_rVerdictDetails: "Details Resultaatcode",
  t_rSmsHistory: "SMS Geschiedenis",
  t_rMailHistory: "E-mail Geschiedenis",
  t_rSecondLineDetails: "Details Tweede Lijn / Intern",
  t_rAbandonCalls: "Details Verlaten Oproepen",
  t_rMissedCalls: "Details Gemiste Oproepen",
  t_rAgentSummary: "Overzicht Agent",
  t_rQueueSL: "SL / Wachtrij",
  t_rOutOfWork: "Oproepen Buiten Werkuren",
  t_rQueueDaily: "Dagelijkse Aantallen Inkomende Oproepen",
  t_rAgentDaily: "Dagelijkse Aantallen Inkomende Oproepen",
  t_rQueueHourly: "Uurstatistieken Inkomende Oproepen",
  t_rAgentHourly: "Uurstatistieken Inkomende Oproepen",
  t_rVerdictByDate: "Aantallen Resultaatcodes per Datum",
  t_rVerdictByDaily: "Resultaatcodes per Dag",
  t_rVerdictByQueue: "Resultaatcodes per Wachtrij",
  t_rVerdictByAgent: "Resultaatcodes per Agent",
  t_rClassificationByDate: "Classificaties per Datum",
  t_rClassificationByDaily: "Classificaties per Dag",
  t_rClassificationByQueue: "Classificaties per Wachtrij",
  t_rClassificationByAgent: "Classificaties per Agent",
  t_rCallCountByDate: "Aantallen Oproepen per Datum",
  t_rDataSummaryByQueue: "Dataoverzicht per Wachtrij",
  t_rProductActionDetails: "Product Actie Details",
  t_rLoginLogout: "Inloggen / Uitloggen",
  t_rLocalNumberSL: "SL / Nummer",
  t_rCallTimeAndCountByDirection: "Gesprekstijd en Aantallen per Richting",
  t_rPozitifCallDetails: "Gespreksdetails / Pool",
  t_rActivity: "Agent Activiteit",
  t_rDailyCallValues: "Gesprekswaarden / Dag",
  t_callrecords: "Gespreksopnamen",
  t_rHourlyCallValues: "Gesprekswaarden / Uur",
  t_rAgentPerformanceDaily: "Agent Prestaties / Dag",
  t_rAgentPerformanceMonthly: "Agent Prestaties / Maand",
  t_rAgentPerformanceHourly: "Agent Prestaties / Uur",
  t_rMonthlyCallValues: "Gesprekswaarden / Maand",
  t_rCallPerformanceByDate: "Gespreksprestaties / Dag",
  t_rDataSummaryByCreateDate: "Dataoverzicht per Aanmaakdatum",
  t_rDataSummaryByAssignedAgent: "Dataoverzicht per Toegewezen Agent",
  t_rDataSummaryByDynamicFields: "Dataoverzicht per Dynamisch Veld",
  t_rLinePerformance: "Lijnprestaties",
  t_rInboundCallValuesByHourly: "Inkomende Gesprekswaarden / Uur",
  t_rSurveyResult: "Enquête Resultaten",
  t_rEmotionAnalysis: 'Emotieanalyse / Dag',
  t_rSpeechStatistics: 'Spreekstatistieken / Dag',
  t_analysis: "Analyse",
  t_rQualityByDate: 'Kwaliteit / Dag',
  t_rQualityByAgent: 'Kwaliteit / Agent',
  t_rCallStatisticsByDate: 'Oproepstatistieken / Dag',
  t_rListeningDetails: 'Luisterdetails',
  t_rProject: 'Project',
  t_rIVR: 'IVR',
  t_rInQueue: "In Wachtrij",
  t_rInProgress: "In Behandeling",
  t_rShiftStart: 'Shift Start',
  t_rShiftEnd: 'Shift Einde',
  t_rShiftDuration: 'Shiftduur',
  t_rMissingTime: 'Ontbrekende Tijd',
  t_rCalculatedShiftDuration: 'Berekenende Shiftduur',
  t_rCalculatedBreak: 'Berekenende Pauze',
  t_rTotalWorkDuration: 'Totale Werktijd',
  t_rLeave: 'Verlof',
  t_rBreakTime: 'Referentie Pauzetijd',
  t_rCalculatedWorkTime: 'Berekenende Werktijd',
  t_rWorkDuration: 'Werktijd',
  t_rTotalWorkDifference: 'Verschil in Werktijd',
  t_reportName: "Rapportnaam",
  t_dataCount: "Aantal Gegevens",
  t_expand: "Uitvouwen",
  t_shrink: "Inklappen",
  t_darkMode: "Donkere Modus",
  t_lightMode: "Lichte Modus",
  t_alertCategory: "Waarschuwingscategorie",
  t_targetCategory: "Doelcategorie",
  t_targetType: "Doeltype",
  t_none: "Geen",
  t_seeExampleTimePatterns: "Bekijk Voorbeeldtijden",
  t_apply: "Toepassen",
  t_callList: 'Toewijzingslijst',
  t_pool: 'Pool',
  t_all: 'Alle',
  t_logs: 'Logs',
  t_totalAttempts: 'Totale Pogingen',
  t_insertDate: 'Laatste Oproep',
  t_actionTime: 'Tijdstip Oproep',
  t_assignDate: 'Toewijzingsdatum',
  t_createDate: 'Uploaddatum',
  t_createTime: 'Uploadtijd',
  t_exAgent: 'Voormalige Agent',
  t_addExtraCustomerInfo: 'Extra Klantinformatie Toevoegen',
  t_primaryKey: 'Primaire Sleutel',
  t_secondaryKey: 'Secundaire Sleutel',
  t_qVoice: 'Stem',
  t_qVideo: 'Video',
  t_qText: 'Tekst',
  t_interactionWeight: 'Kanaalgewicht',
  t_records: 'Opnamen',
  t_qualityForm: 'Kwaliteitsformulier',
  t_keyWords: 'Sleutelwoorden',
  t_criterions: 'Criteria',
  t_required: "Vereist",
  t_isChatFinishCode: 'Tekstkanaal Resultaatcode',
  t_minWaitInQueue: 'Min. Wachttijd in Wachtrij (sec)',
  t_maxWaitInQueue: 'Max. Wachttijd in Wachtrij (sec)',
  t_minDuration: 'Min. Gesprekstijd (sec)',
  t_maxDuration: 'Max. Gesprekstijd (sec)',
  t_rIsEvaluated: 'Beoordeeld',
  t_rEvaluator: 'Beoordelaar',
  t_rEvaluatedStartTime: 'Starttijd Beoordeling',
  t_rEvaluatedEndTime: 'Eindtijd Beoordeling',
  t_rMinQualityPoint: 'Min. Kwaliteitspunt',
  t_rMaxQualityPoint: 'Max. Kwaliteitspunt',
  t_rQualityForm: 'Kwaliteitsformulier',
  t_rIsObjection: 'Bezwaar',
  t_multiple: 'Meerdere Selecties',
  t_question: 'Vraag',
  t_minValue: 'Min. Waarde',
  t_maxValue: 'Max. Waarde',
  t_minTrueCount: 'Min. Juist Aantal',
  t_ordered: 'Gesorteerd',
  t_minPoint: 'Min. Punt',
  t_maxPoint: 'Max. Punt',
  t_point: 'Punt',
  t_selected: 'Geselecteerd',
  t_punish: 'Straffen',
  t_pointRange: 'Puntenbereik',
  t_answer2: 'Antwoord',
  t_openInNewWindow: 'Open in Nieuw Venster',
  t_playbackRate: 'Afspelsnelheid',
  t_isMailSendToAgent: 'Stuur E-mail naar Agent',
  t_httpRequest: 'HTTP-verzoek',
  t_tts: 'TTS',
  t_stt: 'STT',
  t_beepTone: "Beepgeluid",
  t_model: 'Model',
  t_whatsapp: 'WhatsApp',
  t_readQR: 'Lees QR-code',
  t_url: 'URL',
  t_headers: 'Headers (JSON)',
  t_body: 'Body (JSON)',
  t_text: 'Tekst',
  t_useableVariables: 'Beschikbare Variabelen',
  t_remoteNumber: 'Telefoonnummer',
  t_hideVariableFromAgent: 'Verberg Variabele voor Agent',
  t_provider: 'Provider',
  t_speaker: 'Spreker',
  t_isInList: 'In Lijst',
  t_isNotInList: 'Niet in Lijst',
  t_isContains: 'Bevat',
  t_isNotContains: 'Bevat Niet',
  t_IsConvergent: 'Convergerend',
  t_IsNotConvrgent: 'Niet Convergerend',
  t_changeAllQueues: 'Alle Wachtrijen Wijzigen',
  t_remove: 'Verwijderen',
  t_answerToObject: 'Beantwoord Bezwaar',
  t_objectToForm: 'Bezwaarschrift',
  t_objections: 'Bezwaren',
  t_accept: 'Accepteer',
  t_rLastObjectingUser: 'Laatste Bezwaarmaker',
  t_evaluatedDaily: "Vandaag Beoordeeld",
  t_avgPointDaily: "Gemiddelde Punten Vandaag",
  t_evaluatedWeekly: "Deze Week Beoordeeld",
  t_avgPointWeekly: "Gemiddelde Punten Deze Week",
  t_evaluatedMonthly: "Deze Maand Beoordeeld",
  t_avgPointMonthly: "Gemiddelde Punten Deze Maand",
  t_standart: 'Standaard',
  t_advanced: 'Geavanceerd',
  t_quality: 'Kwaliteit',
  t_removeEvaluation: 'Beoordeling Verwijderen',
  t_openOnNewWindow: 'Open in Nieuw Tabblad',
  t_undefined: 'Ongedefinieerd',
  t_callTime: 'Gesprekstijd',
  t_serviceLevel: 'Serviceniveau',
  t_abandonRate: 'Percentage Verlaten Oproepen',
  t_callAnswerTime: 'Snelheid Beantwoorden Oproep',
  t_noRouteToDestination: 'Geen Route naar Bestemming',
  t_passwordSentToMailAdress: 'Uw wachtwoord is naar uw e-mailadres verzonden.',
  t_noEmailAdress: 'Uw account heeft geen e-mailadres. Neem contact op met uw beheerder.',
  t_forgotPassword: 'Wachtwoord Vergeten',
  t_saveRecord: 'Opname Opslaan',
  t_isHttps: 'HTTPS',
  t_callStatistics: 'Oproepstatistieken',
  t_addtCategory: 'Categorie Toevoegen',
  t_create: 'Aanmaken',
  t_questionCount: 'Aantal Vragen',
  t_minPts: 'Min. Punten',
  t_maxPts: 'Max. Punten',
  t_questionsAndAnswers: 'Vragen & Antwoorden',
  t_extensionRoutes: 'Uitbreidingsroutes',
  t_evaluatedRecords: 'Beoordeelde Opnames',
  t_alerts: 'Waarschuwingen',
  t_audioEncoding: "Audio Codering",
  t_gender: "Geslacht van Stem",
  t_token: "Token",
  t_showTCKN: "TCKN Tonen",
  t_webChat: "Web Chat",
  t_whatsapp: "WhatsApp",
  t_instagram: "Instagram",
  t_messenger: "Messenger",
  t_informAgent: 'Informeer Agent',
  t_autoCustomerCard: 'Automatisch Klantenkaart Openen',
  t_downloadCallRecordings: 'Gespreksopnamen Downloaden',
  t_outboundCallApproval: 'Uitgaande Oproep Goedkeuren',
  t_callHistoryDownloadRecording: 'Gespreksgeschiedenis Opname Downloaden',
  t_callHistoryListenRecording: 'Gespreksgeschiedenis Opname Beluisteren',
  t_dialerReopenTotalAttempts: 'Dialer Openen voor Totale Pogingen',
  t_dialerReopenDailyAttempts: 'Dialer Openen voor Dagelijkse Pogingen',
  t_dialerClearCallSchedules: 'Dialer Oproepschema Wissen',
  t_showAllFinishCodes: 'Alle Resultaatcodes Weergeven',
  t_finishcodesofcallsfortoday: "Resultaatcodes van Oproepen van Vandaag",
  t_updateLastFinishCode: 'Laatste Resultaatcode Bijwerken',
  t_showIVRInputs: 'IVR Invoer Weergeven',
  t_hidePhoneNumbers: 'Telefoonnummers Verbergen',
  t_addExtraData: 'Extra Gegevens Toevoegen',
  t_script: 'Script',
  t_crmCall: 'Oproep',
  t_crmAddCustomer: 'Klant Toevoegen',
  t_displayAllRecords: 'Alle Opnamen Weergeven',
  t_editCustomer: 'Klant Bewerken',
  t_crmlistenRecording: 'CRM Gespreksopname Beluisteren',
  t_showNoteDetails: 'Notitiedetails Weergeven',
  t_excelCustomer: 'Excel Klant',
  t_excelCustomerByPhone: 'Excel Telefoon',
  t_excelCustomerByProduct: 'Excel Product',
  t_showPhoneDetails: 'Telefoon Details Weergeven',
  t_justShowOwnCustomers: 'Toon Alleen Eigen Klanten',
  t_dontSendCustomMessage: 'Kan Berichtinhoud Niet Aanpassen',
  t_keywordCategories: "Sleutelwoordcategorieën",
  t_keyWord: "Sleutelwoord",
  t_minOccurrence: "Minimale Voorkomen",
  t_maxOccurrence: "Maximale Voorkomen",
  t_inFirstSeconds: "In de Eerste Seconden",
  t_inLastSeconds: "In de Laatste Seconden",
  t_logicCondition: "Logische Voorwaarde",
  t_reverse: "Omkeren",
  t_and: "En",
  t_or: "Of",
  t_customer: "Klant",
  t_oneOfBoth: "Een van Beide",
  t_bothOfThem: "Beide",
  t_liveDashboards: 'LIVE DASHBOARDS',
  t_tools: 'TOOLS',
  t_admin: 'ADMINISTRATOR',
  t_useDatesForEvaluateDate: 'Gebruik de datums als evaluatiedatum',
  t_menuLineGroups: 'Menu - Lijngroepen',
  t_menuDialPlans: 'Menu - Belplannen',
  t_menuIVR: 'Menu - IVR',
  t_menuAnnouncements: 'Menu - Aankondigingen',
  t_menuRoutes: 'Menu - Routes',
  t_menuTimeCondition: 'Menu - Tijdvoorwaarde',
  t_menuUsers: 'Menu - Gebruikers',
  t_menutExtensionRoutes: 'Menu - Uitbreidingsroutes',
  t_menuQueues: 'Menu - Wachtrijen',
  t_menuProjects: 'Menu - Projecten',
  t_menutStatuses: 'Menu - Statussen',
  t_menutFinishCodes: 'Menu - Resultaatcodes',
  t_menuBlackList: 'Menu - Zwarte Lijst',
  t_menuShifts: 'Menu - Shifts',
  t_menuProfiles: 'Menu - Profielen',
  t_menuRecordingExport: 'Menu - Opname Export',
  t_menuSms: 'Menu - SMS',
  t_menuReports: 'Menu - Rapporten',
  t_menuGoalManagement: 'Menu - Doelbeheer',
  t_menuDialer: 'Menu - Automatische Bellen',
  t_menuLiveDashboard: 'Menu - Live Monitor',
  t_menuPhoneBook: 'Menu - Telefoonboek',
  t_menuCalendar: 'Menu - Kalender',
  t_menuCrm: 'Menu - CRM',
  t_menuDashboard: 'Menu - Dashboard',
  t_menuEvaluatedRecords: 'Menu - Beoordeelde Opnamen',
  t_menuSystemManagement: 'Menu - Systeembeheer',
  t_menuOperationManagement: 'Menu - Operatiebeheer',
  t_menuIntegrations: 'Menu - Integraties',
  t_menuSettings: 'Menu - Instellingen',
  t_menuWallboard: 'Menu - Wallboard',
  t_menuDashboard: 'Menu - Dashboard',
  t_menuQueueGroup: 'Menu - Wachtrijgroep',
  t_menuvIVR: 'Menu - Variabele IVR',
  t_menuScheduler: 'Menu - Planner',
  t_menuExternalChat: 'Menu - Tekstkanaal',
  t_menuSurvey: 'Menu - Enquête',
  t_menuEMail: 'Menu - E-Mail',
  t_menuAlerts: 'Menu - Waarschuwingen',
  t_menuQuality: 'Menu - Kwaliteit',
  t_otherAgentList: 'Andere - Agent Lijst',
  t_othertAutoCustomerCard: 'Andere - Automatisch Klantenkaart Openen',
  t_otherDownloadCallRecordings: 'Andere - Gespreksopnamen Downloaden',
  t_otherOutboundCallApproval: 'Andere - Uitgaande Oproep Goedkeuren',
  t_otherCallHistoryDownloadRecording: 'Andere - Gespreksgeschiedenis Opname Downloaden',
  t_otherCallHistoryListenRecording: 'Andere - Gespreksgeschiedenis Opname Beluisteren',
  t_otherDialerReopenTotalAttempts: 'Andere - Dialer Openen voor Totale Pogingen',
  t_otherDialerReopenDailyAttempts: 'Andere - Dialer Openen voor Dagelijkse Pogingen',
  t_otherDialerClearCallSchedules: 'Andere - Dialer Oproepschema Wissen',
  t_otherShowAllFinishCodes: 'Other - Show All Finish Codes',
  t_otherUpdateLastFinishCode: 'Other - Update Last Finish Code',
  t_otherShowIVRInputs: 'Other - Show IVR Inputs',
  t_otherHidePhoneNumbers: 'Other - Hide Phone Numbers',
  t_ChatSouffle: 'Chat - Souffle',
  t_chatChat: 'Chat - Messaging',
  t_chatAnnounce: 'Chat - Announcement',
  t_crmFilters: 'CRM - Filters',
  t_crmColumns: 'CRM - Columns',
  t_crmManagement: 'CRM - Management',
  t_crmAddExtraData: 'CRM - Add Extra Data',
  t_crmScript: 'CRM - Script',
  t_crmProducts: 'CRM - Products',
  t_crmPhones: 'CRM - Phones',
  t_crmNotes: 'CRM - Notes',
  t_crmSms: 'CRM - SMS',
  t_crmSmsSending: 'CRM - SMS Sending',
  t_crmCrmCall: 'CRM - Call',
  t_crmCrmAddCustomer: 'CRM - Add Customer',
  t_crmDisplayAllRecords: 'CRM - Display All Records',
  t_crmEditCustomer: 'CRM - Edit Customer',
  t_crmCrmlistenRecording: 'CRM - Listen to Call Recording',
  t_crmShowNoteDetails: 'CRM - Show Note Details',
  t_crmExcelCustomer: 'CRM - Export Customer to Excel',
  t_crmExcelCustomerByPhone: 'CRM - Export Customer by Phone to Excel',
  t_crmShowPhoneDetails: 'CRM - Show Phone Details',
  t_rTransferType: "Transfer Type",
  t_rTransferTarget: "Transfer Target",
  t_acw: "ACW Time",
  t_rDialerConnectedToAgent: "Auto Dialer Connected to Agent",
  t_rDialerAbandon: "Auto Dialer Abandoned",
  t_rACWLength: "ACW Time",
  t_callAnalize: "Call Analysis",
  t_callFinishCode: "Call Finish Code",
  t_writtenChannelFinishCode: "Written Channel",
  t_productFinishCode: "Product",
  t_finishCodeType: "Finish Code Type",
  t_speechText: "Speech Text",
  t_reportGroup: "Grouping",
  t_pleaseSelect: "Please Select",
  t_awaitingInteractionsCount: "Awaiting",
  t_descawaitingInteractionsCount: "Total calls currently waiting in the selected queues",
  t_answeredCount: "Answered",
  t_descansweredCount: "Total answered calls in the selected queues",
  t_abandonedCount: "Abandoned",
  t_descabandonedCount: "Total abandoned calls in the selected queues",
  t_activeInteractionsCount: "Active Calls",
  t_descactiveInteractionsCount: "Total active calls in the selected queues",
  t_averageWaitTime: "Average Wait Time",
  t_descaverageWaitTime: "Average wait time for calls in the selected queues",
  t_callBackSuccess: "Successful Callback",
  t_desccallBackSuccess: "Number of successful callbacks made to abandoned calls",
  t_firstCallResolutionCount: "First Call Resolution",
  t_descfirstCallResolutionCount: "Number of calls resolved on the first attempt with a successful outcome",
  t_serviceLevel: "Service Level",
  t_descserviceLevel: "The ratio of calls answered within the service level time defined in the queue settings",
  t_sRLevel: "Success Rate",
  t_descsRLevel: "The ratio of calls completed with a successful outcome code",
  t_averageConnectedTime: "Average Call Duration",
  t_descaverageConnectedTime: "Average duration of all answered calls",
  t_abandonRate: "Abandon Rate",
  t_descabandonRate: "The ratio of abandoned calls to total calls in the selected queues",
  t_transferQueue: "Transfer Queue",
  t_rAvgQueueLength: "Average Queue Wait Time",
  t_crmlistenRecordingJustOwn: "Only Listen to Own Calls",
  t_deleteQualityForm: "Delete Quality Form",
  t_currentPasswordDoesNotMatch: "Current Password is Incorrect",
  t_confirmPasswordDoesNotMatch: "Confirmed Password Does Not Match",
  t_rRinging: "Ringing",
  t_rEnterIVR: "Entered IVR",
  t_ranswer: "Answered by IVR",
  t_rStartIvr: "IVR Started",
  t_rTimeConditionFail: "Time Condition Not Met",
  t_rTimeConditionSuccess: "Time Condition Met",
  t_rStartIvrMenu: "Menu",
  t_rMenuAttempt: "Attempt",
  t_rMenuAnnouncement: "Announcement",
  t_rDtmfResult: "DTMF Result",
  t_rTaskmenu: "Menu",
  t_rTasksurvey: "Survey Task",
  t_rStartPreSurveyTask: "Survey Started",
  t_rPreSurveyAttempt: "Attempt",
  t_rPreSurveyAnnouncement: "Survey Announcement",
  t_rPreSurveyResults: "Survey Result",
  t_rTaskcondition: "Condition",
  t_rConditionFail: "Condition Not Met",
  t_rTaskvariable: "Variable",
  t_rStartVariableTask: " ",
  t_rTaskannounce: "Announcement",
  t_rVariableAttempt: "Attempt",
  t_rVariableAnnouncement: "Variable Announcement",
  t_rTaskhttp: "HTTP Request Made",
  t_rVariableFailed: "Variable Error",
  t_rFailedTaskvariable: "Failed Variable Task",
  t_rMenuFailed: "Menu Error",
  t_rFailedTaskmenu: "Failed Menu Task",
  t_rConditionError: "Condition Error",
  t_rFailedTaskcondition: "Failed Condition Task",
  t_rTimeConditionError: "Time Condition Error",
  t_rIvrFailed: "IVR Terminated",
  t_rFailedInboundCall: "Call Terminated",
  t_rFinalized: "Completed",
  t_rJoinedQueue: "Joined Queue",
  t_rAssigned: "Assigned",
  t_rAccepted: "Accepted",
  t_rPickedUp: "Picked Up",
  t_rRemoteFailed: "Remote Number Error",
  t_rConditionSuccess: "Condition Met",
  t_rTaskannounce: "Announcement",
  t_rTaskqueue: "Queue",
  t_roriginate: "Originate",
  t_rcall_target: "Call Target",
  t_rnew_bridge: "New Channel",
  t_rbridge_created: "Channel Created",
  t_rcalls_bridged: "Channels Bridged",
  t_rcall_transferred: "Transferred",
  t_rUnassigned: "Unassigned",
  t_rAbandoned: "Abandoned",
  t_rRejected: "Rejected",
  t_rNoAnswer: "No Answer",
  t_rFailedTaskannounce: " ",
  t_rTransferAssigned: "Assigned for Transfer",
  t_rTransferAccepted: "Transfer Accepted",
  t_rnull: " ",
  t_rTaskivr: "IVR",
  t_rTasktransfer: "Transfer",
  t_rEndCall: "Call Ended",
  t_rFailedTaskhttp: "HTTP Task Error",
  t_rTotalLocal: "Total Local End",
  t_rInboundLocal: "Inbound Local End",
  t_rOutboundLocal: "Outbound Local End",
  t_rAvgDialingTime: "Average Dialing Time",
  t_rTotalInCallTime: "Total In-Call Time",
  t_rTotalStatusTime: "Total Status Time",
  t_rTotalHoldCount: "Total Hold Count",
  t_rTotalHoldTime: "Total Hold Time",
  t_rAvgHandleTime: "Average Handle Time",
  t_rLogoutCount: "Logout Count",
  t_rProductivityTime: "Productivity Time",
  t_rBreakCategoryTime: "Break Time",
  t_rAvgAlertTime: "Average Ring Time",
  t_addFile: "Add File",
  t_appointment: "Appointment",
  t_appointments: "Appointments",
  t_second: "Second",
  t_notAnsweringCallDesc2: "You have been in Not Answering state for 1 minute. Please switch to Available to continue.",
  t_notAnsweringCallDesc: "You did not answer the call, please switch to Available to continue.",
  t_notAnsweringCall: "Unanswered Call",
  t_timeFormat: "Time Format",
  t_callGroup: "Call Group",
  t_pickupGroup: "Pickup Group",
  t_fodlerType: "Folder Type",
  t_conferenceCallAccepted: "Call Connected",
  t_conferenceCallHangup: "Call Ended",
  t_conferenceCallDeclined: "Call Failed to Connect",
  t_conferenceCalling: "Calling",
  t_agentListDetails: "Agent List Details",
  t_rAgentGroup: "Agent Group",
  t_new: "New",
  t_recordsActivityLog: "Record Listening Log",
  t_isAutoForm: "Automatic Form",
  t_timestamp: 'Timestamp',
  t_verificationCode: "Verification Code",
  t_verificationCodeDesc: "Please enter the 6-digit verification code",
  t_verify: "Verify",
  t_phone: "Phone",
  t_downloadVoyceVoiceConverter: "Download Voyce Voice Converter",
  t_position: "Position",
  t_ivrBreakOut: "Queue Breakout",
  t_positionAndIvrBreakOut: "Position and Queue Breakout",
  t_ivrBreakOutTarget: "Queue Breakout Target",
  t_displayAllCalendar: "Show All Calendars",
  t_appointmentAssignmentDesc: "New appointments have been assigned to you. Please check your calendar.",
  t_appointmentAssignment: "Appointment Assignment",
  t_displayCalls: 'Call Monitoring',
  t_showUsedAreas: "Show Used Areas",
  t_ftp: "FTP",
  t_voiceExport: "Voice Export",
  t_putFtp: "Send to FTP",
  t_remotePath: "Remote Path",
  t_passive: 'Passive',
  t_passives: 'Passives',
  t_state: 'State',
  t_frequentlyAskedQuestions: 'Frequently Asked Questions',
  t_helpCenter: 'Help Center',
  t_contactUs: 'Contact Us',
  t_isSecure: 'Secure Connection',
  t_rejectUnauthorized: 'Reject Unauthorized',
  t_customMetaData: 'Custom Metadata',
  t_metaDataEndpoint: 'Metadata Endpoint',
  t_metaDataRemotePath: 'Metadata Remote Path',
  t_putAsFolder: 'Send as Folder',
  t_isDefault: 'Default',
  t_rSLDuration: 'SL Duration',
  t_twoFactorAuthentication: "Two-Factor Authentication",
  t_sentToPhoneNumber: "sent to your phone number",
  t_sentToEmailAdress: "sent to your email address",
  t_verifyYourIdentity: "verify your identity by entering the code",
  t_remainingTime: "Remaining Time",
  t_sec: "sec",
  t_donthaveAccess: "Don't have access to this account?",
  t_verificationCode: "Verification Code",
  t_sendAsMail: "Send via Email",
  t_sendAsSms: "Send via SMS",
  t_verify: "Verify",
  t_closeProduct: "Close Product",
  t_setPassiveWhenNoProduct: 'Set records without products to passive',
  t_sendHttpRequest: "HTTP",
  t_month2: "Month (No Leading Zero)",
  t_day2: "Day (No Leading Zero)",
  t_shifts: "Shifts",
  t_shift: "Shift",
  t_missingTime: 'Missing Time',
  t_isAnnualLeave: 'Annual Leave',
  t_paidLeave: 'Paid Leave',
  t_unpaidLeave: 'Unpaid Leave',
  t_isOwner: 'Self',
  t_owner: 'Owner',
  t_thirdParty: 'Third Party',
  t_breakTimeFraud: 'Break Overuse',
  t_dontControlLastTenDigit: 'Do not check last 10 digits from right',
  t_bank: 'Bank',
  t_dontChangeSurvey: 'Do not change survey status',
  t_finishCodeBreakdown: 'Finish Code Breakdown',
  t_displayPassiveRecords: 'Display Passive Records',
  t_newAssets: "New Asset",
  t_reached: "Reached",
  t_reachedDate: "Reached Date",
  t_dontGetNoQueueRecords: "Do not get records without a queue",
  t_agentHaveStaticQueueButDontHaveProjectPermission: 'The agent has a static queue but no project permission.',
  t_autoRecall: 'Auto Recall',
  t_autoRecallDay: 'Day Later',
  t_rDateName: "Day",
  t_rWeekOfMonth: "Week of the Month",
  t_rGivenBreakTime: "Break Right",
  t_rOfflineTime: "Offline Time",
  t_rMonth: "Month",
  t_rBreakTimeDifference: "Break Time Difference",
  t_copyFromTheEditor: "Copy from the Editor",
  t_pasteIntoTheEditor: "Paste into the Editor",
  t_duplicate: "Duplicate",
  t_identifier: "ID Number",
  t_undeafen: 'Unmute',
  t_deafen: 'Mute',
  t_canTransfer: 'Transferable',
  t_wordDistance: 'Word Distance',
  t_segmentDistance: 'Segment Distance',
  t_maxWordGroupCount: 'Max. Word Group',
  t_trendAnalyze: 'Trend Analysis',
  t_realTimeTranscript: 'Real-Time Transcript',
  t_snapshots: "Snapshots",
  t_snapshotDate: "Snapshot Date",
  t_firstCallDate: "First Call Date",
  t_lastCallDate: "Last Call Date",
  t_callCount: "Call Count",
  t_density: "Density",
  t_avarageDensity: "Average Density",
  t_displayDensityStatistics: 'Show Density Chart',
  t_hideFromAgent: 'Hide from Agent',
  t_customerNo: "Customer No",
  t_customerName: "Name Surname",
  t_customerIdentifier: "ID Number (TCKN)",
  t_reached: "Reached",
  t_reachedDate: "Reached Date",
  t_productNo: "Product No",
  t_artificialIntelligence: "Artificial Intelligence",
  t_speakers: "Speakers",
  t_oneTime: "One-Time",
  t_scheduled: "Scheduled",
  t_automatic: "Automatic",
  t_periodTime: "Period Time",
  t_prompt: "Prompt",
  t_aiCallResult: "AI Call Result",
  t_exceptions: "Exceptions"
}
