import tr from './tr_voyce.js'
import en from './en_voyce.js'
import fr from './fr_voyce.js'
import nl from './nl_voyce.js'
import de from './de_voyce.js'
const lang = {}

lang.default = "tr";
lang.tr = tr
lang.en = en
lang.fr = fr
lang.nl = nl
lang.de = de

// function _l(key){
//     let selectedLang = window.localStorage.getItem('language') || lang.default;
//     let path = key.split(".");
//     return access(lang[selectedLang],key) || path[path.length - 1];
// }

function _l(selectedLang) {
  return function (token) {
    return lang[selectedLang][token] || token;
  };
}

function selectLang(lang) {
  window.localStorage.setItem('language', lang);
  globalThis.v_navbar.lang = _l(lang);
  globalThis.LayoutContentRendererDefault.lang = _l(lang);
  window.location.reload();
}

const access = (object, path) => {
  const pathArray = Array.isArray(path) ? path : path.split(".");
  const lastIndex = pathArray.length - 1;
  return lastIndex > 0
    ? access(object, pathArray.slice(0, lastIndex))[pathArray[lastIndex]]
    : object[pathArray[0]];
}

export {
  lang, _l, selectLang, access
}
