export default {
  t_welcome: "Willkommen", //Login
  t_copyright: "Alle Rechte vorbehalten ©️ 2023 ver3.1.0",
  t_copy: "Kopieren",
  t_userLogin: "Benutzeranmeldung",
  t_userName: "Benutzername",
  t_password: "Passwort",
  t_login: "Anmelden",
  t_hello: "Hallo",
  t_usernameOrPasswordIsInvalid: "Benutzername oder Passwort ist ungültig.",
  t_successfullyLoggedIn: "Erfolgreich eingeloggt.",
  t_pleaseSelectQueueAndClassification: "Bitte Warteschlange und Klassifikation auswählen",
  t_PleaseSelectProjetAndQueues: "Bitte Projekt und Warteschlange auswählen",
  t_pleaseSingIn: "Bitte anmelden",
  t_rememberMe: 'Erinnere dich an mich',
  t_signIn: 'Einloggen',
  t_changeStatusAfterTheCall: "Status nach dem Anruf ändern", //LoyoutContentRedererDefault.vue
  t_remainingTime: "Verbleibende Zeit",
  t_continue: "Fortsetzen",
  t_hold: "Halten",
  t_microphoneOn: "Mikrofon Ein",
  t_microphoneOff: "Mikrofon Aus",
  t_inputList: "Eingabeliste",
  t_showInputs: "Eingaben anzeigen",
  t_chatPanel: "Chat-Panel",
  t_showDetail: "Details anzeigen",
  t_connected: "Verbunden", // Navbar.vue
  t_disconnected: "Nicht verbunden",
  t_dayMode: "Tagmodus",
  t_nightMode: "Nachtmodus",
  t_agentList: "Agentenliste",
  t_logOut: "Ausloggen", // AgentTargets
  t_collectionTargeting: "Zielsetzung",
  t_targeting: "Zielsetzung",
  t_dashboard: "Dashboard",
  t_search: "Suchen",
  t_add: "Hinzufügen",
  t_targetedMonth: "Zielmonat",
  t_confirm: "Bestätigen",
  t_areYouSureYouWantToDeleteTheSelectedRecord: "Sind Sie sicher, dass Sie den ausgewählten Datensatz löschen möchten?",
  t_areYouSureYouWantToUpdateTheSelectedRecords: "Sind Sie sicher, dass Sie die ausgewählten Datensätze aktualisieren möchten?",
  t_areYouSureYouWantToClearAll: "Sind Sie sicher, dass Sie alles löschen möchten?",
  t_selectTheAgent: "Agenten auswählen",
  t_collectedDate: "Erhebungsdatum",
  t_collectedTime: "Erhebungszeit",
  t_edit: "Bearbeiten",
  t_configuration: "KONFIGURATION",
  t_completed: "Abgeschlossen",
  t_remaining: "Verbleibend",
  t_compilationRate: "Abschlussrate",
  t_minute: "Minute",
  t_targetCompletion: "Zielerfüllung",
  t_hour: "Stunde",
  t_day: "Tag",
  t_week: "Woche",
  t_month: "Monat",
  t_year: "Jahr",
  t_weekday: "Arbeitstag",
  t_lastOneHour: "Letzte 1 Stunde",
  t_lastThreeHours: "Letzte 3 Stunden",
  t_twoDaysAgo: "Vor 2 Tagen",
  t_threeDaysAgo: "Vor 3 Tagen",
  t_fourDaysAgo: "Vor 4 Tagen",
  t_fiveDaysAgo: "Vor 5 Tagen",
  t_sixDaysAgo: "Vor 6 Tagen",
  t_period: "Zeitraum",
  t_thisWeek: "Diese Woche",
  t_lastWeek: "Letzte Woche",
  t_get: "Holen",
  t_saveFilters: "Filter speichern",
  t_target: "Ziel",
  t_announcements: "Ankündigungen", // Announcements
  t_announcementName: "Ankündigungsname",
  t_selectAudioFile: "Audiodatei auswählen",
  t_announcementList: "Ankündigungsliste",
  t_areYouSure: "Sind Sie sicher?",
  t_ok: "Okay",
  t_count: "Anzahl",
  t_CompletedAmount: "Abgeschlossene Menge",
  t_dailyTarget: "Tagesziel",
  t_monthlyTarget: "Monatsziel",
  t_dailyTimeline: "Täglicher Zeitplan",
  t_blackList: "Schwarze Liste", // BlackListManagement
  t_descriptions: "Beschreibungen",
  t_inbound: "Eingehend",
  t_outbound: "Ausgehend",
  t_number: "Nummer",
  t_callHistory: "Anrufverlauf", // CallHistory
  t_numberHistory: "Nummernverlauf",
  t_notes: "Notizen",
  t_oldNotes: "Alte Notizen",
  t_campaigns: "Kampagnen", // Campaigns
  t_campaign: "Kampagne",
  t_campaignName: "Kampagnenname",
  t_campaignList: "Kampagnenliste",
  t_campaignhasBeenDeletedSuccessfully: "Kampagne wurde erfolgreich gelöscht.",
  t_campaignhasBeenUpdatedSuccessfully: "Kampagne wurde erfolgreich aktualisiert.",
  t_campaignhasBeenCreatedSuccessfully: "Kampagne wurde erfolgreich erstellt.",
  t_customTabs: "Benutzerdefinierte Tabs", // CustomTabs
  t_address: "Adresse",
  t_landlinePrefix: "Festnetz-Präfix",
  t_fctPrefix: "FCT-Präfix",
  t_customTabList: "Liste der benutzerdefinierten Tabs",
  t_dataManagement: "Datenverwaltung", // DataUpload
  t_productManagement: "Produktverwaltung",
  t_scriptManagement: "Skriptverwaltung",
  t_exampleFile: "Beispiel-Excel",
  t_uploadFile: "Datei hochladen",
  t_SelectTheTitlesToUpdate: "Bitte die zu aktualisierenden Titel auswählen",
  t_setUnmatchedRecordsToPassive: "Nicht übereinstimmende Datensätze auf passiv setzen",
  t_setUnmatchedRecordsToUpdate: "Übereinstimmende Datensätze aktualisieren",
  t_setAllPreviousInstitutionDataToPassive: "Alle vorherigen Institutiondaten auf passiv setzen",
  t_updateMatchedRecords: "Übereinstimmende Datensätze aktualisieren",
  t_setAllRecordsOfProjectNameToPassive: "Alle Projektdaten auf passiv setzen",
  t_upload: "Hochladen",
  t_0customersHasBeenUpdatedSuccessfully: "0 Kunden erfolgreich hochgeladen.",
  t_customersHasBeenUpdatedSuccessfully: "Kunden erfolgreich hochgeladen.",
  t_customersCouldNotBeUploadedBecauseThereHasBeenAnError: "Es gab einen Fehler beim Hochladen der Kunden.",
  t_pleaseSelectAProject: "Bitte ein Projekt auswählen",
  t_dialPlan: "Wählplan", // DialPlan
  t_classifications: "Klassifikationen",
  t_simulation: "Simulation",
  t_check: "Prüfen",
  t_order: "Reihenfolge", //DIAL PLAN PRIORITY1
  t_callingPatterns: "Wählmuster",
  t_dialPattern: "Wählmuster",
  t_simulatedCallCouldBePlacedFrom: "Anruf könnte ausgehen von: ",
  t_couldNotPlaceSimulationCall: "Anruf konnte nicht simuliert werden!",
  t_totalAgents: "Gesamt", // Live Dashboard
  t_conference: "Konferenz",
  t_callsInQueue: "Warteschleife",
  t_abandonedCount: "Abgebrochen",
  t_hourlyAbandonedCount: "Stündlich abgebrochen",
  t_statusLength: "Statusdauer",
  t_massChangeStatuses: "Massenänderung Status",
  t_textColour: "Textfarbe",
  t_audioFile: "Audiodatei",
  t_type: "Typ",
  t_confetti: "Konfetti",
  t_champagne: "Champagner",
  t_emergency: "Notfall",
  t_blink: "Blinken",
  t_wallboardControl: "Wallboard-Steuerung",
  t_writtenSouffle: "Geschriebener Souffle",
  t_listen: "Hören",
  t_clap1: "Applaus1",
  t_clap2: "Applaus2",
  t_warning: "Warnung",
  t_changeAllStatuses: "Alle Status ändern",// Home.vue
  t_showWentHome: "Nach Hause gegangen anzeigen", // Home.vue
  t_hideWentHome: "Nach Hause gegangen verbergen", // Home.vue
  t_showCalledBack: "Zurückgerufen anzeigen",// Home.vue
  t_reakTimeFraud: "Pausenüberschreitung", // Home.vue
  t_finishCodeCategory: "Kategorie", // FinishCodeManagement
  t_categoryName: "Kategoriename",
  t_finishCodeCategorys: "Abschlusskategorien",
  t_finishCodeName: "Angezeigter Name",
  t_finishCodes: "Abschlusscodes",
  t_askForActionDate: "Datum anfragen",
  t_saveToCalendar: "Im Kalender speichern",
  t_contactCallableAfterwards: "Kontakt anrufbar",
  t_contactNumberCallableAfterwards: "Nummer anrufbar",
  t_countAsCallAttempt: "Als Anrufversuch zählen",
  t_lastFinishCode: "Letzter Abschlusscode",
  t_deleteContactNumber: "Nummer löschen",
  t_assignToAgent: "Agent zuweisen",
  t_inboundRoutes: "Eingehende Routen", // InboundRoutes
  t_standardRoute: "Standardroute",
  t_lineGroup: "Leitungsgruppe", // LineGroup
  t_lineGroupName: "Leitungsgruppenname",
  t_lineGroups: "Leitungsgruppen",
  t_addedLines: "Hinzugefügte Leitungen",
  t_lineList: "Leitungsliste", // Lines
  t_importLines: "Leitungen importieren",
  t_lineName: "Leitungsname",
  t_outboundCID: "Abgehend CID",
  t_transferCID: "Transfer CID",
  t_hide_ofline_users: "Offline-Nutzer verbergen",
  t_ip: "IP",
  t_transportType: "Transporttyp",
  t_type: "Typ",
  t_prefix: "Präfix",
  t_inboundCallLimit: "Eingehende Anrufbegrenzung",
  t_outboundCallLimit: "Ausgehende Anrufbegrenzung",
  t_port: "Port",
  t_codec: "Codec",
  t_fileUpload: "Datei hochladen",
  t_file: "Datei",
  t_selectTheFileYouWantToUpload: "Bitte Datei zum Hochladen auswählen",
  t_pleaseEnterTheLineGroupName: "Bitte den Leitungsgruppennamen eingeben.",
  t_youNeedToPickALineToBeAbleToCreateLineGroup: "Sie müssen mindestens eine Leitung auswählen, um eine Leitungsgruppe zu erstellen.",
  t_lineGroupHasBeenCreatedSuccessfully: "Leitungsgruppe wurde erfolgreich erstellt.",
  t_campaignManagement: "Kampagnenverwaltung", // IvnDialer
  t_campaignControl: "Anrufsteuerung",
  t_stop: "Stop",
  t_recycle: "Recyceln",
  t_calls: "Anrufe",
  t_basic: "Grundlegend",
  t_scheduledCalls: "Geplante Anrufe",
  t_contactList: "Kontaktliste",
  t_programming: "Programmierung",
  t_priority: "Priorität",
  t_maxCall: "Maximale Anrufe",
  t_callsPerAgent: "Anrufe pro Agent",
  t_statisticsPeriod: "Statistikzeitraum (Min)",
  t_callingMethod: "Anrufmethode",
  t_noAnswerTimeout: "Keine Antwort Timeout (Sek)",
  t_maxAbandonRate: "Maximale Abbruchrate (%)",
  t_dontConnectMachines: "Maschinenantworten nicht verbinden",
  t_sortCallList: "Anrufliste sortieren",
  t_maxCycles: "Maximale Zyklen",
  t_recallDelayMins: "Erneuter Anruf Verzögerung (Min)",
  t_afterCall: "Nach dem Anruf",
  t_afterCallStatus: "Status nach dem Anruf",
  t_maxAttempts: "Max. Versuche",
  t_callstatisticsfortoday: "Heutige Anrufstatistiken",
  t_statusdurationfortoday: "Heutige Statuszeiten",
  t_maximumRateOfCallsThatAreAbandonedFromTheQueue: "Maximale Rate der Anrufe, die aus der Warteschlange abgebrochen wurden",
  t_maximumAmountOfCallsThisCampaignCanStart: "Maximale Anzahl der Anrufe, die diese Kampagne starten kann",
  t_callsPerAvailableAgents: "Anrufe pro verfügbare Agenten",
  t_refreshRateOfThisCampaignsStatisticsGraphs: "Aktualisierungsrate der Statistikdiagramme dieser Kampagne",
  t_whichLineGroupToMakeCallsFrom: "Leitungsgruppe, aus der Anrufe für die Kampagne gemacht werden",
  t_maximumRingingTime: "Maximale Klingelzeit des ausgehenden Anrufs",
  t_amountOfRoundsToCallPeopleWhoHaventBeenContactedYet: "Anzahl der Zyklen, um Personen erneut anzurufen, die noch nicht kontaktiert wurden",
  t_minimumAmountOfMinutesToRecallAPhoneNumber: "Mindestanzahl an Minuten, bevor eine Telefonnummer erneut angerufen wird",
  t_maximumCallAmountPerPhoneNumberPerDay: "Maximale Anzahl der Anrufe pro Telefonnummer pro Tag",
  t_maximumCallAmountPerContactPerDay: "Maximale Anzahl der Anrufe pro Kontakt pro Tag",
  t_maximumCallAmountPerPhoneNumber: "Maximale Anzahl der Anrufe pro Telefonnummer insgesamt",
  t_maximumCallAmountPerContact: "Maximale Anzahl der Anrufe pro Kontakt insgesamt",
  t_doNotConnectMachineAnsweredCalls: "Anrufe, die von einer Maschine beantwortet wurden, nicht an Agenten weiterleiten",
  t_dailyMaxPhoneTouch: "Tägliches maximales Telefonkontaktlimit",
  t_dailyMaxContactTouch: "Tägliches maximales Kontaktlimit",
  t_totalMaxPhoneTouch: "Gesamtes maximales Telefonkontaktlimit",
  t_totalMaxContactTouch: "Gesamtes maximales Kontaktlimit",
  t_justCallMobilePhone: "Nur Mobiltelefone anrufen",
  t_abandoned: "Abgebrochen",
  t_timeCondition: "Zeitbedingung",
  t_transferIVR: "IVR-Übertragung",
  t_liveContacttIVR: "Live-Kontakt IVR",
  t_liveContact: "Live-Kontakt",
  t_noAvailableAgent: "Kein verfügbarer Agent", // Dialer.vue
  t_noAvailableAgentIVR: "Kein verfügbarer Agent IVR",
  t_rule: "Regel",
  t_campaignStatus: "Status",
  t_campaignPriority: "Priorität",
  t_campaignFilterCriteria: "Filterkriterien",
  t_campaignSortingCriteria: "Sortierkriterien",
  t_campaignRemainingCycles: "Verbleibende Zyklen",
  t_campaignCallPerAgent: "Anrufe pro Agent",
  t_campaignTotalCustomersToCall: "Gesamtzahl der anzurufenden Kunden",
  t_campaignTotalPhonesToCall: "Gesamtzahl der anzurufenden Telefone",
  t_campaignRemainingPhonesInCycle: "Verbleibende Telefone im Zyklus",
  t_campaignTotalCalled: "Gesamte angerufene",
  t_campaignTotalPhonesCalled: "Aktuell angerufene Telefone",
  t_campaignConnectedCall: "Verbundene Anrufe",
  t_campaignWaitingInQueue: "Wartende Anrufe",
  t_campaignAbandonRate: "Abbruchrate %",
  t_resultNoAnswer: "Keine Antwort (Cevapsız)",
  t_resultAbandon: "Abgebrochen (Kaçan)",
  t_resultBusy: "Besetzt (Meşgul)",
  t_resultCallRejected: "Anruf abgelehnt (Müşteri Red)",
  t_resultFaultyCall: "Fehlgeschlagener Anruf (Hatalı Arama)",
  t_resultNetworkOutOfOrder: "Netzwerkfehler (Ağ Hatası)",
  t_resultFateUnknown: "Schicksal unbekannt (Bilinmiyor)",
  t_resultConnectivityError: "Verbindungsfehler (Bağlantı Hatası)",
  t_resultFaultyNumber: "Fehlerhafte Nummer (Hatalı Numara)",
  t_finishCodeStatistics: "Ergebniscode-Verteilung",
  t_customersStatus: "Status",
  t_assigned_agent: "Agent",
  t_attempts: "Versuche",
  t_dial_plan: "Wählplan",
  t_phone_book: "Telefonbuch",
  t_ivr: "IVR",
  t_finish_code: "Ergebniscode",
  t_insert_date: "Anrufdatum",
  t_action_date: "Anrufdatum geplant",
  t_create_date: "Erstellungsdatum",
  t_ex_agent: "Ehemaliger Agent",
  t_assign_date: "Zuweisungsdatum",
  t_pleaseMakeASelectionForSorting: "Bitte wählen Sie eine Option zur Sortierung aus.",
  t_started: "Gestartet",
  t_stopped: "Gestoppt",
  t_recycling: "Wiederholt",
  t_isCompleted: "Abgeschlossen",
  t_settingsHasBeenSavedSuccessfully: "Einstellungen wurden erfolgreich gespeichert.",
  t_columns: "Spalten", //Main.vue
  t_row: "Zeile",
  t_sort: "Sortierung", //Main.vue
  t_algorithmicDialer: "Algorithmischer Dialer", // List.vue
  t_basicDialer: "Einfacher Dialer", // List.vue
  t_filterManagement: "Filterverwaltung", // IvnDialerFilter
  t_noFiltersForThisCampaign: "Keine Filter für diese Kampagne gefunden.", // IvnDialerFilter > t_fiterManagemt > t_value
  t_condition: "Bedingung",
  t_equal: "Gleich",
  t_notEqual: "Ungleich",
  t_lessThan: "Kleiner als",
  t_greaterThan: "Größer als",
  t_lessThanOrEqual: "Kleiner gleich",
  t_greaterThanOrEqual: "Größer gleich",
  t_columnName: "Spaltenname",
  t_columnNameStatus: "Status",
  t_columnNameAssignedAgent: "Zugewiesener Agent",
  t_columnNameAttempts: "Versuche",
  t_columnNameCampaign: "Kampagne",
  t_columnNameFinishCode: "Letzter Ergebniscode",
  t_columnNameInsertDate: "Anrufdatum",
  t_columnNameActionDate: "Geplantes Anrufdatum",
  t_columnNameCreateDate: "Erstellungsdatum",
  t_columnNameExAgent: "Ehemaliger Agent",
  t_filterHasBeenCreatedSuccessfully: "Filter wurde erfolgreich erstellt.",
  t_IVR: "IVR", // IvrManagement
  t_IVRName: "IVR-Name",
  t_IVRList: "IVR-Liste",
  t_DTMFdigit: "DTMF-Taste",
  t_announcementFile: "Ankündigungsdatei",
  t_DTMFAccept: "DTMF akzeptieren",
  t_extension: "Durchwahl",
  t_transfer: "Übertragen",
  t_language: "Sprache",
  t_timeOutLengthSec: "Zeitüberschreitung (Sek)",
  t_timeOutCount: "Anzahl der Zeitüberschreitungen",
  t_maxInvalidInputCount: "Maximale Anzahl fehlerhafter Eingaben",
  t_acceptDTMFExtension: "DTMF-Durchwahl akzeptieren",
  t_turkish: "Türkisch",
  t_english: "Englisch",
  t_arabic: "Arabisch",
  t_russian: "Russisch",
  t_hangup: "Auflegen",
  t_hangupCall: "Anruf beenden",
  t_incomingCall: "Eingehender Anruf",
  t_answer: "Antworten",
  t_menu: "Menü",
  t_matched: "Übereinstimmend:",
  t_unmatched: "Nicht übereinstimmend:",
  t_hasBeenDeletedSuccessfully: "Wurde erfolgreich gelöscht.",
  t_pleaseDoNotLeaveAnyBlankFields: "Bitte keine Felder leer lassen.",
  t_hasBeenUpdatedSuccessfully: "Wurde erfolgreich aktualisiert.",
  t_hasBeenCreatedSuccessfully: "Erfolgreich erstellt.",
  t_pleaseSelectTheBlackListDirection: "Bitte wählen Sie die Richtung der Blacklist.",
  t_phoneBook: "Telefonbuch", // PhoneBook
  t_description: "Beschreibung",
  t_profiles: "Profile", // ProfileQueueManagement
  t_profileName: "Profilname",
  t_affectedObjects: "Betroffene Objekte",
  t_permissions: "Berechtigungen",
  t_user: "Benutzer",
  t_reset: "Zurücksetzen",
  t_noPermissionsSelected: "Keine Berechtigungen ausgewählt.",
  t_profileList: "Profilliste",
  t_chat: "Chat",
  t_souffle: "Souffle",
  t_profileHasBeenDeletedSuccessfully: "Profil wurde erfolgreich gelöscht.",
  t_profileHasBeenUpdatedSuccessfully: "Profil wurde erfolgreich aktualisiert.",
  t_profileHasBeenCreatedSuccessfully: "Profil wurde erfolgreich erstellt.",
  t_is_visible_in_live_dasboard: "In der Live-Dashboard sichtbar",
  t_projectName: "Projektname", // Projects
  t_afterCallWork: "Nacharbeitszeit (Sek)",
  t_cameraRecord: "Kameraaufzeichnung",
  t_useableBreakTime: "Verfügbare Pausenzeit (Min)",
  t_breakTime: "Pausenzeit",
  t_surname: "Nachname",
  t_nameSurname: "Name und Nachname",
  t_projectList: "Projektliste",
  t_projectHasBeenDeletedSuccessfully: "Projekt wurde erfolgreich gelöscht.",
  t_projectHasBeenUpdatedSuccessfully: "Projekt wurde erfolgreich aktualisiert.",
  t_projectHasBeenCreatedSuccessfully: "Projekt wurde erfolgreich erstellt.",
  t_queueNumber: "Warteschlangennummer", // Queues - List.vue
  t_queueAgentTimeout: "Agent-Timeout (Sek)",
  t_queueAgentTimeoutPlaceholder: "Maximale Wartezeit des Anrufs bei einem Agenten",
  t_queueAnnounceFrequency: "Ankündigungsfrequenz (Sek)",
  t_timeBetweenAnnouncements: "Zeit zwischen den Ankündigungen",
  t_queueAnnouncePosition: "Positionsankündigung",
  t_queueMaxlen: "Maximale Länge",
  t_queuePenaltyMembersLimit: "Mitgliederlimit mit Strafe",
  t_queueRetry: "Wiederholen",
  t_queueMusicOnHold: "Wartemusik",
  t_queueName: "Warteschlangenname",
  t_queueCallingMode: "Anrufmodus",
  t_queueStrategy: "Warteschlangenstrategie",
  t_queueStrategyPlaceholder: "Verteilung der Anrufe zwischen den Agenten",
  t_queueWrapupTime: "Nachbearbeitungszeit (Sek)",
  t_queueWrapupTimePlaceholder: "Minimale Zeit ohne Anruf nach einem Anruf",
  t_queueAnnounceHoldTime: "Wartezeitankündigung",
  t_queueQueueHoldTime: "Automatische Wartezeit",
  t_queueMemberDelay: "Mitgliedsverzögerung",
  t_queuePeriodicAnnounceFrequency: "Periodische Ankündigungsfrequenz",
  t_queueServiceLevel: "Serviceniveau",
  t_queueWeight: "Gewichtung",
  t_queueList: "Warteschlangenliste",
  t_queueRrmemory: "Reihenfolge (Empfohlen)",
  t_queueRingall: "Alle gleichzeitig",
  t_queueLeastrecent: "Zuletzt angerufene zuerst",
  t_queueAgentWeight: "Agent-Priorität",
  t_queueFewestcalls: "Agenten mit den wenigsten Anrufen zuerst",
  t_queueRandom: "Zufällig",
  t_queueManual: "Manuell",
  t_queueDialer: "Dialer",
  t_serviceManagement: "Servicemanagement", // ServiceManagement
  t_dialerService: "Automatischer Anrufservice",
  t_restart: "Neu starten",
  t_callManager: "Anrufverwaltungsdienst",
  t_chatService: "Nachrichtendienst",
  t_smsSending: "SMS-Versand", //SMS//
  t_smsTemplate: "Vorlage",
  t_smsFilter: "Filter",
  t_filterName: "Filtername", // SmsFilter //
  t_noFilterHasBeenSelected: "Es wurde kein Filter ausgewählt.",
  t_filterList: "Filterliste",
  t_smsPageTitle: "Seitentitel", // SmsSender //
  t_sendFromVoyceCRM: "Aus dem System senden",
  t_sendFromExternalCRM: "Aus externer Quelle senden",
  t_sendFromCustomSms: "Benutzerdefinierte SMS senden",
  t_smsPattern: "Nachrichtenvorlage",
  t_preview: "Vorschau",
  t_exampleText: "Beispielnachricht",
  t_phoneNumbers: "Telefonnummern",
  t_enterPhoneNumbersSeparatedByCommas: "Geben Sie die Telefonnummern durch Kommas getrennt ein.",
  t_enterEmailsSeparatedByCommas: "Geben Sie E-Mail-Adressen durch Kommas getrennt ein.",
  t_pleaseEnterAtLeastOnePhoneNumber: "Bitte geben Sie mindestens eine Telefonnummer ein.",
  t_pleaseEnterAMessage: "Bitte geben Sie eine Nachricht ein.",
  t_messagesHaveBeenDeliveredSuccessfully: "Nachrichten wurden erfolgreich zugestellt.",
  t_thereWasAProblemDeliveringYourMessages: "Es gab ein Problem bei der Zustellung Ihrer Nachrichten.",
  t_displayName: "Anzeigename", // SmsSettings //
  t_senderNumber: "Absendernummer",
  t_accountCode: "Kontocode",
  t_apiKey: "API-Schlüssel",
  t_smsSettingList: "SMS-Einstellungenliste",
  t_pattern: "Vorlage",
  t_patternName: "Vorlagenname",
  t_smsText: "Nachrichtentext",
  t_patternList: "Vorlagenliste",
  t_callCenter: "Callcenter", // Statistics //
  t_statistics: "Statistiken",
  t_missedCall: "Verpasst",
  t_lunch: "Mittagessen",
  t_break: "Pause",
  t_AVGInboundLengt: "Durchschnittliche eingehende Anrufdauer",
  t_AVGOutboundLengt: "Durchschnittliche ausgehende Anrufdauer",
  t_totalInbound: "Gesamteingehende",
  t_totalOutbound: "Gesamtausgehende",
  t_remainingBreakTime: "Verbleibende Pausenzeit",
  t_statusTime: "Statuszeiten",
  t_totalActionCounts: "Gesamtanzahl der Aktionen",
  t_waitingInQueue: "Wartende in der Warteschlange",
  t_numberofwaitingininstantqueue: "Anzahl der aktuell wartenden in der Warteschlange",
  t_allcallrecordsfortoday: "Alle heutigen Anrufaufzeichnungen",
  t_collectionTarget: "Ziel",
  t_collectedMade: "Erreicht",
  t_workingDay: "Arbeitstag",
  t_present: "Anwesend",
  t_absent: "Abwesend",
  t_actionDate: "Aktionstermin",
  t_inCallOutbound: "Im Anruf (Ausgehend)",
  t_inCallInbound: "Im Anruf (Eingehend)",
  t_ringing: "Klingelt",
  t_noAnswer: "Keine Antwort",
  t_promiseToPay: "Zahlungsversprechen",
  t_refuseToPay: "Zahlungsverweigerung",
  t_messageLeft: "Nachricht hinterlassen",
  t_busy: "Besetzt",
  t_statusCategory: "Statuskategorie", // StatusManagement //
  t_statusCategorys: "Statuskategorien",
  t_statusName: "Statusname",
  t_categoryKey: "Kategorie-Schlüssel",
  t_categories: "Kategorien",
  t_changeable: "Änderbar",
  t_onlySPVCanChange: "Nur Supervisor kann ändern",
  t_active: "Aktiv",
  t_statusList: "Statusliste",
  t_online: "Online",
  t_offline: "Offline",
  t_other: "Andere",
  t_important: "Wichtig",
  t_apointment: "Termin",
  t_reminder: "Erinnerung",
  t_personal: "Persönlich",
  t_personaly: "Persönlich",
  t_timeConditionName: "Zeitbedingungsname", // TimeConditionManagement - List.vue
  t_startDay: "Starttag",
  t_startTime: "Startzeit",
  t_startingDayOfMonth: "Starttag des Monats",
  t_monthStart: "Monatsanfang",
  t_monthStartDay: "Starttag des Monats",
  t_monthEndDay: "Endtag des Monats",
  t_monthEnd: "Monatsende",
  t_endDay: "Endtag",
  t_endTime: "Endzeit",
  t_endingDayOfMonth: "Endtag des Monats",
  t_timeConditions: "Zeitbedingungen",
  t_monday: "Montag",
  t_tuesday: "Dienstag",
  t_wednesday: "Mittwoch",
  t_thursday: "Donnerstag",
  t_friday: "Freitag",
  t_saturday: "Samstag",
  t_sunday: "Sonntag",
  t_january: "Januar",
  t_february: "Februar",
  t_march: "März",
  t_april: "April",
  t_may: "Mai",
  t_june: "Juni",
  t_july: "Juli",
  t_august: "August",
  t_september: "September",
  t_october: "Oktober",
  t_november: "November",
  t_december: "Dezember",
  t_theBeginingConditionCanNotBeGraterThanEndingCondition: "Die Startbedingung darf nicht größer als die Endbedingung sein.",
  t_userInformations: "Benutzerinformationen",
  t_import: "Importieren", // UsersManagement //
  t_importUsers: "Benutzer importieren", // UsersManagement //
  t_importRecords: "Datensätze importieren", // UsersManagement //
  t_userList: "Benutzerliste",
  t_tenantName: "Mandantenname",
  t_recordingExport: "Tonaufzeichnung exportieren", // VoiceExport.vue
  t_recordingRemove: "Tonaufzeichnung entfernen", // VoiceExport.vue
  t_filters: "Filter",
  t_tags: "Tags",
  t_nameType: "Namensformat", // VoiceExport.vue
  t_youCanAddCommaBetweenEmailAddressesToAddMore: "Sie können mehrere E-Mail-Adressen durch Kommas trennen.",
  t_youCanAddCommaBetweenPhoneToAddMore: "Sie können mehrere Telefonnummern durch Kommas trennen.",
  t_startDate: "Startdatum",
  t_endDate: "Enddatum",
  t_namePattern: "Namensformat",
  t_targetMail: "Ziel-E-Mail",
  t_whenTheFilesAreReadyYouWillbeSentAnEMail: "Wenn die Dateien fertig sind, werden sie Ihnen per E-Mail zugesandt.",
  t_fileType: "Dateiformat",
  t_theProcessHasBeenStartedItWillBeSentByEmailWhenItIsCompleted: "Der Vorgang wurde gestartet und wird nach Abschluss per E-Mail gesendet.",
  t_thereWasAProblemStartingTheProcess: "Beim Start des Vorgangs ist ein Problem aufgetreten.",
  t_process: "Prozess", // VSPR //
  t_deactiveCustomer: "Kunden deaktivieren",
  t_activeCustomer: "Kunden aktivieren",
  t_assigneManager: "Manager zuweisen",
  t_conclude: "Abschließen",
  t_addingNote: "Notiz hinzufügen",
  t_deleteCallRecord: "Anrufaufzeichnung löschen",
  t_matchingColumn: "Übereinstimmende Spalte", // components - VSPR //
  t_pleaseFillTheAllNecessaryAreas: "Bitte füllen Sie alle erforderlichen Felder aus.",
  t_thereWasAProblemWhileAssigning: "Bei der Zuweisung ist ein Problem aufgetreten.",
  t_doesNotMatchAnyCustomers: "entspricht keinem Datensatz.",
  t_matchingColumnMustBeTheSameInTheExcelColumn: "Die übereinstimmende Spalte muss mit der Spaltenbezeichnung in der Excel-Datei übereinstimmen.",
  t_AProblemOccurredwhileProcessing: "Beim Verarbeiten ist ein Problem aufgetreten.",
  t_customersHaveBeenSuccessfullyUpdated: "Datensätze wurden erfolgreich aktualisiert.",
  t_customersHaveBeenSuccessfullyDeleted: "Datensätze wurden erfolgreich gelöscht.",
  t_processSuccess: "Prozess erfolgreich",
  t_assigne: "Zuweisen", // AssignManager //
  t_manager: "Manager",
  t_thereWasAProblemWhileAssigningManager: "Beim Zuweisen des Managers ist ein Problem aufgetreten.",
  t_managerHaveSuccessfullyBeenAssigned: "Manager wurde erfolgreich zugewiesen.",
  t_deactiveCustomersHaveSuccessfullyBeenDone: "Deaktivierung erfolgreich durchgeführt.", // CloseCustomer //
  t_VSPRCloseCustomer: "Kunde schließen",
  t_VSPROpenCustomer: "Daten öffnen",
  t_aProblemOccurredWhileConcluding: "Beim Abschließen ist ein Problem aufgetreten.",
  t_customersHaveSuccessfullyBeenConcluded: "Schließen erfolgreich durchgeführt.",
  t_thereWasAProblemActivatingCustomers: "Beim Aktivieren der Daten ist ein Problem aufgetreten.",
  t_customersHaveBeenSuccessfullyActivated: "Daten wurden erfolgreich aktiviert.",
  t_deleteCallRecordings: "Anrufaufzeichnungen löschen",
  t_fileHasBeenImportedSuccessfully: "Datei wurde erfolgreich importiert.",
  t_makeAnAnnouncement: "Ankündigung machen", // Chat //
  t_sendASouffle: "Souffle senden",
  t_startChat: "Gespräch beginnen",
  t_open: "Öffnen",
  t_conversations: "Gespräche",
  t_announcementHistory: "Ankündigungsverlauf",
  t_totalCallTime: "Gesamte Anrufzeit",
  t_avgCallTime: "Durchschnittliche Anrufdauer",
  t_avgAnswerTime: "Durchschnittliche Antwortzeit",
  t_avgHoldTime: "Durchschnittliche Haltezeit",
  t_avgAcwTime: "Durchschnittliche Nachbearbeitungszeit",
  t_totalCallTimeDescrition: "KPI",
  t_avgCallTimeDescrition: "KPI",
  t_avgAnswerTimeDescrition: "KPI",
  t_avgHoldTimeDescrition: "KPI",
  t_avgAcwTimeDescrition: "KPI",
  t_noRecordOnQueue: "Keine wartenden Anrufe in der Warteschlange",
  t_callRecords: "Anrufaufzeichnungen",
  t_secondLine: "Zweite Leitung",
  t_changeMode: "Ansicht ändern",
  t_showNotConnected: "Nicht verbundene anzeigen",
  t_fullScreen: "Vollbild",
  t_announcementPanel: "Ankündigungspanel",
  t_yourMessage: "Ihre Nachricht",
  t_startAconversation: "Gespräch beginnen",
  t_youGotAPrivateMessage: "hat Ihnen eine private Nachricht gesendet.",
  t_startANewConversation: "Ein neues Gespräch beginnen",
  t_updatePassword: "Passwort aktualisieren", // Navbar //
  t_PasswordCondition: "Ihr Passwort muss mindestens 6 Zeichen lang sein und 1 Buchstaben und 1 Zahl enthalten.",
  t_lastAccountEvent: "Letzte Kontobewegung",
  t_events: "Ereignisse",
  t_changePassword: "Passwort ändern",
  t_again: "Erneut",
  t_currentPassword: "Ihr aktuelles Passwort",
  t_newPassword: "Ihr neues Passwort",
  t_confirmPassword: "Bestätigen Sie Ihr neues Passwort",
  t_pleaseSelectfinishCode: "Bitte wählen Sie einen Ergebniscode",
  t_survey: "Umfrage",
  t_passwordmustContainAtLeast1LetterAnd1Number: "Das Passwort muss mindestens 1 Buchstaben und 1 Zahl enthalten.",
  t_enteredpasswordsDoNotMatch: "Die eingegebenen Passwörter stimmen nicht überein.",
  t_passwordMustBeAtLeast6CharactersLong: "Das Passwort muss mindestens 6 Zeichen lang sein.",
  t_YourPasswordCannotBeTheSameAsYourOldPassword: "Ihr Passwort darf nicht mit Ihrem alten Passwort übereinstimmen.",
  t_PasswordHasBeenchangedSuccessfully: "Das Passwort wurde erfolgreich geändert.",
  t_youHaveReconnectedToTheService: "Sie haben die Verbindung zum Service wiederhergestellt.",
  t_yourServiceConnectionHasBeenLost: "Ihre Serviceverbindung wurde unterbrochen.",
  t_ACWNothingSelected: "Keine Aktion ausgewählt",
  t_ACWTimeOut: "ACW Zeitüberschreitung",
  t_rdialerconnectedtoagent: "Automatischer Anruf - Verbundener Anruf",
  t_rdialerabandon: "Automatischer Anruf - Abgebrochener Anruf",
  t_color: "Farbe",
  t_view: "Ansicht",
  t_allDays: "Alle Tage",
  t_addEvent: "Ereignis hinzufügen",
  t_eventName: "Ereignisname",
  t_eventNote: "Ereignisnotiz",
  t_eventDate: "Ereignisdatum",
  t_eventTime: "Ereigniszeit",
  t_displayOnCRM: "Im CRM anzeigen",
  t_monthly: "Monatlich",
  t_weekly: "Wöchentlich",
  t_daily: "Täglich",
  t_hourly: "Stündlich",
  t_mon: "Mo",
  t_tue: "Di",
  t_wed: "Mi",
  t_thu: "Do",
  t_fri: "Fr",
  t_sat: "Sa",
  t_sun: "So",
  t_monSat: "Mo - Sa",
  t_sunSat: "So - Sa",
  t_monFri: "Mo - Fr",
  t_monWedFri: "Mo, Mi, Fr",
  t_eventhasBeenCreatedSuccessfully: "Das Ereignis wurde erfolgreich erstellt.",
  t_avaragequalitypoint: "Durchschnittliche Qualitätspunkte",
  t_liveDashboard: "Live-Dashboard", // Menu //
  t_mAlerts: 'Trigger',
  t_systemManagement: "Systemverwaltung",
  t_operationManagement: "Betriebsführung",
  t_integrations: "Integrationen",
  t_eMail: "E-Mail",
  t_qVoiceMail: "Sprachnachricht",
  t_lastLogin: "Letzte Anmeldung",
  t_speechRule: 'Sprechregel',
  t_addSpeechRule: 'Sprechregel hinzufügen',
  t_lines: "Leitungen",
  t_routes: "Routen", // System Management -> Main
  t_vIVR: "Variabler IVR",
  t_backup: "Sicherung",
  t_providers: "Anbieter",
  t_mVoice: "Stimme",
  t_reachedCalls: "Erreichte Anrufe",
  t_unreachedCalls: "Nicht erreichte Anrufe",
  t_missedCalls: "Verpasste Anrufe",
  t_outcomingCall: "Ausgehender Anruf",
  t_inboundCall: "Eingehender Anruf",
  t_incomingCallAvg: "Durchschnitt eingehender Anruf",
  t_outgoingCallAvg: "Durchschnitt ausgehender Anruf",
  t_show: "Anzeigen",
  t_entries: "Einträge",
  t_addRecord: "Eintrag hinzufügen",
  t_showing: "Angezeigt",
  t_title: "Titel",
  t_clear: "Löschen",
  t_perPage: "Pro Seite",
  t_uploadFileOnClick: "Klicken Sie hier, um eine Datei hochzuladen",
  t_delete: "Löschen",
  t_noEditedRecord: "Kein bearbeiteter Eintrag gefunden.",
  t_noRecord: "Keine Einträge vorhanden",
  t_errorFetchData: "Beim Abrufen der Daten ist ein Fehler aufgetreten.",
  t_backToList: "Zur Liste zurückkehren",
  t_deleteRecord: 'Eintrag löschen',
  t_updateRecord: 'Eintrag aktualisieren',
  t_titles: 'Titel',
  t_searchPlaceHolder: 'Suchen Sie nach Name, Kundennummer, Produktnummer, TC oder Telefon',
  t_customerId: 'Kunden-ID',
  t_breakStatus: 'Pausenstatus',
  t_targetStatus: 'Zielstatus',
  t_mrAgentAssign: 'Agentenzuordnung',
  t_dataUpload: 'Daten hochladen',
  t_customerList: 'Kundenliste',
  t_customerListByPhone: 'Kundenliste nach Telefon',
  t_customerListByProduct: 'Kundenliste nach Produkt',
  t_customerListByCSV: 'Kundenliste CSV',
  t_vehicle: 'Fahrzeug',
  t_landRegistry: 'Grundbuch',
  t_SSK: 'SSK',
  t_mernis: 'Mernis',
  t_gsmQuery: 'GSM-Abfrage',
  t_negotiation: 'Verhandlung',
  t_products: 'Produkte',
  t_phones: 'Telefone',
  t_hourlyGraphic: 'Stundengrafik',
  t_deleteFilter: 'Filter löschen',
  t_addFilter: 'Filter hinzufügen',
  t_clearFilter: 'Filter löschen',
  t_addNewRecord: 'Neuen Eintrag erstellen',
  t_selectableLines: "Wählbare Leitungen",
  t_selectedLines: "Verwendete Leitungen",
  t_restartService: 'Sind Sie sicher, dass Sie den Dienst neu starten möchten?',
  t_restartServiceSuccessfully: 'Dienst wurde erfolgreich neu gestartet.',
  t_id: 'Reihenfolge',
  t_goal: 'Ziel',
  t_goalManagementDescription: "Grafikansicht",
  t_smscount: 'Nachrichten werden gesendet. Sind Sie sicher?',
  t_call: 'Anrufen',
  t_clearFilters: "Filter löschen",  // CRM - Main.vue //
  t_management: "Verwaltung",
  t_customerInformation: "Kundeninformationen",
  t_customerUpdate: "Kundeninformationen aktualisieren",
  t_extraInformation: "Zusätzliche Informationen",
  t_hide: "Ausblenden",
  t_addAction: "Aktion hinzufügen",
  t_addPhone: "Telefon hinzufügen",
  t_addNote: "Notiz hinzufügen",
  t_landLine: "Festnetz",
  t_addFinishCode: "Ergebniscode hinzufügen",
  t_addProductAction: "Produktaktion hinzufügen",
  t_actionDate2: "Aktionstermin",
  t_installmentCount: "Ratenanzahl",
  t_installmentAmount: "Ratenbetrag",
  t_relationTo: "Beziehung zu",
  t_isApproved: "Genehmigt",
  t_addData: "Daten hinzufügen",
  t_sendSMS: "SMS senden",
  t_sendEmail: "E-Mail senden",
  t_filterTheResults: "Ergebnisse filtern",
  t_filterBy: "Filtern nach",
  t_pleaseWriteSomethingToSeeYourOptions: "Geben Sie etwas ein, um Ihre Optionen anzuzeigen.",
  t_columnVisibilityOptions: "Sichtbarkeitsoptionen für Spalten",
  t_showHide: "(Anzeigen/Verstecken)",
  t_agentAssign: 'Agenten zuweisen',
  t_assigmnentType: "Zuweisungstyp",
  t_contactPool: 'Kontaktpool',
  t_sender: "Absender",
  t_shuffle: "Mischen",
  t_assignForProductAgent: 'Zuweisung für Produktagenten',
  t_progresingDate: "Bearbeitungsdatum",
  t_allUnassignedCasesWillBeAssignedToSelectedAgentsAreYouSure: "Alle nicht zugewiesenen Fälle werden den ausgewählten Agenten zugewiesen. Sind Sie sicher?",
  t_amountOfCasesWillBeUpdatedAreYouSure: "Fälle werden aktualisiert. Sind Sie sicher?",
  t_casesCouldNotBeAssigned: "Bei Fällen gab es ein Problem mit der Zuweisung.",
  t_customersHaveSuccessfullyBeenAssigned: "Kunden wurden erfolgreich zugewiesen.",
  t_error: "Fehler",
  t_failed: "Fehlgeschlagen",
  t_reportDetails: 'Berichtsdetails', // Reports.vue
  t_summary: "Zusammenfassung",
  t_today: "Heute", // Reports.vue
  t_yesterday: "Gestern", // Reports.vue
  t_last7Days: "Letzte 7 Tage", // Reports.vue
  t_last30Days: "Letzte 30 Tage", // Reports.vue
  t_betweenDates: "Zwischen den Daten", // Reports.vue
  t_reports: 'Berichte', //Main.vue
  t_reportType: 'Berichtstyp',
  t_callDetails: "Anrufdetails", // Report Names // 
  t_mrInboundCallDetails: "Eingehende Anrufdetails",
  t_mrSL30Daily: "SL30 Täglich",
  t_mrSL30Monthly: "SL30 Monatlich",
  t_mrSL30Hourly: "SL30 Stündlich",
  t_mrAbandonedCalls: "Abgebrochene Anrufe",
  t_mrAfterWorkInboundCalls: "Außerhalb der Arbeitszeit eingehende Anrufe",
  t_mrTotalCalls: "Gesamtanzahl der Anrufe",
  t_extraTime: "Zusätzliche Zeit",
  t_giveExtraTime: "Zusätzliche Zeit gewähren",
  t_time: "Zeit (00:00:00)",
  t_mrCallDurationDetails: "Anrufdauer-Details",
  t_mrCallDurationSummary: "Zusammenfassung der Anrufdauer",
  t_mrInboundCallDaily: "Tägliche Verteilung der eingehenden Anrufe",
  t_mrInboundCallHourly: "Stündliche Verteilung der eingehenden Anrufe",
  t_surveyDetails: "Umfragedetails",
  t_mrAgent: "Agent",
  t_mrAgentActivity: "Agentenübersicht",
  t_mrAgentCallCountsDaily: "Agent tägliche Anrufanzahl",
  t_mrAgentCallCountsByDate: "Agent Anrufanzahl nach Datum",
  t_mrAgentWork: "Agentenarbeit",
  t_mrAgentCalls: "Agentenanrufe",
  t_mrAgentDaily: "Tägliche Verteilung der Agentenanrufe",
  t_mrAgentHourly: "Stündliche Verteilung der Agentenanrufe",
  t_mrAgentCalendar: "Kalenderdetails",
  t_mrIvnDialerCallAnalyze: "Kampagnenanalyse",
  t_mrIvnDialerCallDetails: "Kampagnen-Anrufdetails",
  t_called: "Durchgeführte Anrufe", // Dashboard.vue 
  t_closed: "Geschlossene Anrufe",
  t_backedupFiles: "Gesicherte Dateien", // Backup.vue 
  t_pleaseSelectTheFileToUpload: "Bitte wählen Sie die Datei zum Hochladen in die Datenbank aus",
  t_createBackup: "Backup erstellen",
  t_exportToDatabase: "In die Datenbank exportieren",
  t_filter: "Filter", // GLOBAL // 
  t_settings: 'Einstellungen',
  t_action: "Aktion",
  t_note: "Notiz",
  t_column: "Spalte",
  t_value: "Wert",
  t_operator: "Operator",
  t_users: "Benutzer",
  t_agents: "Agenten",
  t_date: "Datum",
  t_agent: "Agent", // Home.vue 
  t_finishCode: "Ergebniscode",
  t_direction: "Richtung",
  t_defaultRoute: "Standardroute",
  t_editSelected: "Ausgewählte bearbeiten",
  t_crm: 'CRM',
  t_calendar: 'Kalender',
  t_dialer: "Wählprogramm",
  t_goalManagement: 'Zielverwaltung',
  t_line: 'Leitung',
  t_dialPlans: 'Wählpläne',
  t_queues: 'Warteschlangen',
  t_projects: 'Projekte',
  t_statuses: 'Status',
  t_announce: 'Ankündigung',
  t_createScript: 'Skript erstellen',
  t_queueTimeOut: 'Warteschlangen-Timeout',
  t_detinationType: 'Zieltyp',
  t_detinationTarget: 'Ziel',
  t_showInDashboard: 'Im Dashboard anzeigen',
  t_reject: 'Ablehnen',
  t_internalCall: 'Intern',
  t_isProductFinishCode: 'Produkt-Ergebniscode',
  t_queueGroup: 'Warteschlangengruppe', //Home.vue 
  t_agentGroup: 'Agentengruppe', //Home.vue 
  t_selectableQueues: "Wählbare Warteschlangen",
  t_selectedQueues: "Ausgewählte Warteschlangen",
  t_isHoldSound: 'Wartemusik',
  t_waitTime: 'Wartezeit',
  t_isReached: 'Erreicht',
  t_addCustomer: 'Kunde hinzufügen',
  t_phonesUpdate: "Telefon bearbeiten",
  t_phonesinfoUpdate: "Telefoninformationen aktualisieren",
  t_isAutoEmail: 'E-Mail senden',
  t_emailTemplate: 'E-Mail-Vorlage',
  t_account: 'Konto',
  t_security: 'Sicherheit',
  t_performance: 'Leistung',
  t_emailDelayAmount: 'Verzögerung (Minuten)',
  t_isAutoSMS: 'SMS senden',
  t_smsDelayAmount: 'Verzögerung (Minuten)',
  t_autoAnswer: 'Automatisch beantworten',
  t_isUnassign: 'Zuweisung aufheben',
  t_isPassive: 'Deaktivieren',
  t_setAttemptsByPhone: 'Versuche basierend auf Telefonverlauf festlegen',
  t_recordingFile: 'Aufnahmedatei',
  t_callStrategy: 'Anrufstrategie',
  t_sortCriteria: 'Sortierkriterien',
  t_internationalCall: 'Internationaler Anruf',
  t_dialerPrecall: 'Dialer Precall',
  t_variable: 'Variable',
  t_variableLength: 'Variablenlänge',
  t_reportFiles: 'Berichtsdateien', // Main.vue 
  t_schedule: 'Planen',
  t_scheduler: 'Planer',
  t_schedulerTasks: 'Geplante Aufgaben',
  t_schedulerCategory: 'Planungskategorie',
  t_reportCategory: 'Berichtskategorie',
  t_lastXDays: 'Letzte X Tage',
  t_communicationType: 'Kommunikationstyp',
  t_communicationSrc: 'Kommunikationsquelle',
  t_communicationTitle: 'Titel',
  t_to: 'Empfänger (To)',
  t_cc: 'CC (Carbon Copy)',
  t_youCanAddCommaBetweenEmailAddressesToaddMore: "Sie können mehrere E-Mail-Adressen durch Kommas trennen.",
  t_email: 'E-Mail',
  t_mobileNotification: 'Mobile Benachrichtigung',
  t_callPlaced: 'Anruf getätigt',
  t_callWentToAgent: 'Mit Agenten verbunden',
  t_callJoinedQueue: 'In Warteschlange',
  t_callPickedUp: 'Beantwortet',
  t_callRejected: 'Abgelehnt',
  t_schedulerTime: 'Planungszeit',
  t_schedulerTimeString: 'Planungszeit Beschreibung',
  t_creator: 'Ersteller',
  t_nextRunTime: 'Nächste Laufzeit',
  t_externalChat: 'Externer Chat',
  t_start: "Starten", // IvnDialer // 
  t_selectAll: "Alle auswählen", // CRM - Main.vue - Home.vue // 
  t_clearAll: "Alle löschen", // Main.vue 
  t_queue: "Warteschlange", // Home.vue - Reports.Vue 
  t_announcement: "Ankündigung", //ProfileQueueManagement - Home.vue // 
  t_duration: "Dauer", //Statistics - Home.vue 
  t_productivity: "Produktivität", //Statistics - Home.vue 
  t_status: "Status", // Home.vue 
  t_usedBreakTime: "Verwendete Pausenzeit", //Statistics - Home.vue 
  t_answered: "Beantwortet",  // Statistic.vue - Wallboard.vue - Home.vue 
  t_successfullCall: "Erreicht", //Statistics - Home.vue - Dialer.vue 
  t_unsuccessfullCall: "Nicht erreicht", //Statistics - Home.vue - Dialer.vue 
  t_export: "Exportieren", // Home.vue - List.vue - Reports.vue 
  t_total: "Gesamt", // Dialer.vue 
  t_rate: "Rate (%)", // Statistic.vue - Home.vue - Dialer.vue 
  t_send: "Senden", //SmsFilter 
  t_next: 'Weiter',
  t_previous: 'Zurück',
  t_classification: "Klassifizierung", // List.vue - ListAddNew.vue 
  t_available: "Verfügbar", // Wallboard.vue - Home.vue / Dialer.vue 
  t_inCall: "Im Anruf", // Wallboard.vue - Home.vue 
  t_paused: "Pause",  // Wallboard.vue - Home.vue 
  t_profile: "Profil",
  t_no: "Nein",
  t_yes: "Ja",
  t_report: 'Bericht',
  t_actions: "Aktionen", // All 
  t_cancel: "Abbrechen", // All 
  t_save: "Speichern", // All 
  t_category: "Kategorie", // All 
  t_project: "Projekt", // All 
  t_close: "Schließen", // All 
  t_name: "Name", // All 
  t_sms: 'SMS', // All 
  t_general: 'Allgemein',
  t_loginPageImages: 'Login-Seitenbilder',
  t_browse: 'Durchsuchen',
  t_thisMonth: 'Diesen Monat',
  t_lastMonth: 'Letzter Monat',
  t_yourReportAddToReportQueue: 'Ihr Bericht wurde zur Berichtswarteschlange hinzugefügt',
  t_notification: 'Benachrichtigung',
  t_notifications: 'Benachrichtigungen',
  t_pleaseSelectReport: 'Bitte wählen Sie einen Bericht aus',
  t_host: 'Host',
  t_subject: 'Betreff',
  t_isSendMailToCustomer: 'E-Mail an den Kunden senden',
  t_surveyCancel: 'Umfrage abbrechen',
  t_surveyApprove: 'Umfrage genehmigen',
  t_sendType: 'Sendetyp',
  t_info: 'Information',
  t_pleaseSelectTimeCondition: 'Bitte wählen Sie eine Zeitbedingung aus',
  t_crmWhiteColorInfo: 'Unberührt',
  t_crmInfoColorInfo: 'Berührt und anrufbar',
  t_crmWarningColorInfo: 'Berührt und nicht anrufbar',
  t_crmSecondaryColorInfo: 'Berührt und geschlossen',
  t_crmDangerColorInfo: 'Unberührt und geschlossen',
  t_nextCustomer: 'Nächster Kunde',
  t_lineChangeInfo: 'Bitte speichern Sie Ihre Kampagnen, die diese Leitungsgruppe verwenden, erneut.',
  t_deleteData: 'Daten löschen',
  t_closedByDailyAttempts: 'Durch tägliche Versuche geschlossen',
  t_closedByTotalAttempts: 'Durch gesamte Versuche geschlossen',
  t_resetScheduledCalls: 'Geplante Anrufe zurücksetzen',
  t_resetDailyAttempts: 'Durch tägliche Versuche geschlossene Anrufe öffnen',
  t_resetTotalAttempts: 'Durch gesamte Versuche geschlossene Anrufe öffnen',
  t_detail: "Details",
  t_spokenCall: 'Gesprochener Anruf',
  t_spokenCallRate: 'Gesprächsrate%',
  t_nonSpokenCall: 'Nicht gesprochener Anruf',
  t_nAttemptsCall: 'Ohne Versuch',
  t_attemptsRate: 'Versuchsrate%',
  t_reachRate: 'Erreichbarkeitsrate%',
  t_totalCallInfo: 'Gesamtanzahl der getätigten Anrufe',
  t_spokenCallInfo: 'Anzahl der Anrufe, bei denen der Agent gesprochen hat',
  t_spokenRateInfo: 'Anteil der Anrufe mit Kontakt im Verhältnis zu den Gesprächen. Formel: Gesprächte / Gesamt - Ohne Versuch',
  t_noSpokenCallInfo: 'Anzahl der Anrufe mit Kontakt, bei denen nicht gesprochen wurde. Keine Antwort und Besetzte Anrufe',
  t_noAttemptsCallInfo: 'Anzahl der Anrufe, die an den Agenten weitergeleitet, aber ohne Kontakt abgeschlossen wurden',
  t_attemptsRateInfo: 'Verhältnis der Anrufe, die an den Agenten weitergeleitet und Kontakt hatten. Formel: Gesamt - Ohne Versuch / Gesamt',
  t_reachRateInfo: 'Erreichbarkeitsrate der angerufenen Nummern in der Warteschlange. Formel: Gespräche + Abgebrochene / Gesamt',
  t_username: 'Benutzername',
  t_examples: 'Beispiele',
  t_cron: 'Zeitplaner',
  t_cron1: "Jede Minute",
  t_cron2: "Jede Stunde",
  t_cron3: "Jeden Tag um Mitternacht",
  t_cron4: "Jeden Tag um 17:00 Uhr",
  t_cron5: "Jeden Sonntag um Mitternacht",
  t_cron6: "Am ersten Tag des Monats um Mitternacht",
  t_cron7: "Am ersten Tag des Jahres um Mitternacht",
  t_cron8: "Jeden Wochentag um Mitternacht",
  t_cron9: "Am 15. Tag jedes Monats um Mitternacht",
  t_cron10: "Am ersten Montag jedes Jahres um Mitternacht",
  t_cron11: "Am 15. Tag der Monate Januar, Juni und Dezember um Mitternacht",
  t_cron12: "Am ersten Werktag jedes Monats um Mitternacht",
  t_cron13: "An Wochenenden um Mitternacht",
  t_alert: 'Alarm',
  t_lessThan: 'Kleiner als',
  t_lessThanOrEqual: 'Kleiner oder gleich',
  t_moreThanOrEqual: 'Größer oder gleich',
  t_rConferenceEvent: 'Konferenz',
  t_rConferenceParticipants: 'Konferenzteilnehmer',
  t_rQualityFormNote: 'Formularnotiz',
  t_rInboundFailSL3059: 'Abgebrochene Anrufe in 30/59 Sekunden',
  t_rInboundFailSL60119: 'Abgebrochene Anrufe in 60/119 Sekunden',
  t_rInboundFailSL120179: 'Abgebrochene Anrufe in 120/179 Sekunden',
  t_rInboundFailSL180: 'Abgebrochene Anrufe nach 180 Sekunden',
  t_rIvrWaitLength: 'IVR-Wartezeit',
  t_rExtra: 'Zusätzliche Informationen',
  t_rSender: 'Absender',
  t_rMessage: 'Nachricht',
  t_rEvaluateEvent: 'Bewertung',
  t_rEvaluateTime: 'Bewertungszeit',
  t_rQualityFormName: 'Formularname',
  t_callAnalyze: 'Anrufanalyse',
  t_rObjectionEvent: 'Einwand',
  t_rPunishEvent: 'Strafe',
  t_rQualityPoint: 'Qualitätspunkt',
  t_rMailSender: "Absender",
  t_rSubject: "Betreff",
  t_rBody: "Beschreibung",
  t_rTo: 'Empfänger (To)',
  t_rCC: 'CC (Carbon Copy)',
  t_rState: "Status",
  t_rStartTime: "Startzeit",
  t_rEndTime: "Endzeit",
  t_rProductivity: "Produktivität",
  t_rRemoteNumber: "Telefonnummer",
  t_rAgent: "Agent",
  t_rDirection: "Richtung",
  t_rQueue: "Warteschlange",
  t_rVerdict: "Ergebniscode",
  t_rActionDate: "Aktionstermin",
  t_rNote: "Notiz",
  t_rConnectedLength: "Gesprächsdauer",
  t_rQueueWaitLength: "Wartezeit in der Warteschlange",
  t_rHoldLength: "Haltezeit",
  t_rAcwLength: "ACW-Dauer",
  t_rRecordingFile: "Dateiname",
  t_rTerminateSide: "Beendigung durch",
  t_rRecordingFileName: "Aufnahme",
  t_rLocalNumber: "Leitung",
  t_rLineName: "Leitungsname",
  t_rClassification: "Klassifizierung",
  t_rCampaign: "Kampagne",
  t_rInteractionID: "Anruf-ID",
  t_rMüşteriNo: "Kundennummer",
  t_rAdıSoyadı: "Name und Nachname",
  t_rIsCalledBack: "Wurde zurückgerufen",
  t_rCalledBackedStartTime: "Rückrufzeit",
  t_rCalledBackedQueue: "Rückruf-Warteschlange",
  t_rUsername: "Agent",
  t_rInboundSuccess: "Beantwortet",
  t_rInboundTotal: "Gesamteingehend",
  t_rTotal: "Gesamt",
  t_rLogin: "Anmelden",
  t_rLogout: "Abmelden",
  t_rAvgCallTime: "Durchschnittliche Gesprächszeit",
  t_rOnlineTime: "Online-Zeit",
  t_rInCallTime: "Gesamte Gesprächszeit",
  t_rInboundSuccessSL: "SL-Antwort",
  t_rInboundFail: "Verpasst",
  t_rOutboundSuccess: "Ausgehend erfolgreich",
  t_rOutboundFail: "Ausgehend fehlgeschlagen",
  t_rTotalWaitTime: "Gesamtwartezeit",
  t_rAvgWaitTime: "Durchschnittliche Wartezeit",
  t_rMaxWaitTime: "Maximale Wartezeit",
  t_rAgentMissed: "Agent verpasst",
  t_rInitMoment: "Datum",
  t_rCount: "Anzahl",
  t_rDate: "Datum",
  t_rCustomerID: "Kundennummer",
  t_rProductID: "Produktnummer",
  t_rInboundSuccessRate: "Antwortquote",
  t_rDialerSuccess: "Automatischer Anruf erfolgreich",
  t_rDialerFail: "Automatischer Anruf fehlgeschlagen",
  t_rDialerTotal: "Automatischer Anruf gesamt",
  t_rDialerTotalTalkTime: "Automatischer Anruf Gesprächszeit",
  t_rDialerTotalIVRTime: "Automatischer Anruf IVR-Gesamtzeit",
  t_rDialerAvgIVRTime: "Durchschnittliche IVR-Zeit bei automatischem Anruf",
  t_rDialerAvgTalkTime: "Durchschnittliche Gesprächszeit bei automatischem Anruf",
  t_rTotalAvgCallTime: "Durchschnittliche Anrufdauer",
  t_rTotalCallTime: "Gesamtgesprächszeit",
  t_rTotalSuccessCallCount: "Erfolgreiche Anrufe",
  t_rTotalCallCount: "Gesamtanzahl der Anrufe",
  t_rDialerTotalCallTime: "Automatischer Anruf Gesprächszeit",
  t_rDialerAvgCallTime: "Durchschnittliche Anrufdauer bei automatischem Anruf",
  t_rTotalSuccess: "Gesamt erfolgreich",
  t_rTotalTalkTime: "Gesamt Gesprächszeit",
  t_rTotalAvgTalkTime: "Durchschnittliche Gesprächszeit",
  t_rTotalCustomer: "Gesamtkunden",
  t_rCallable: "Anrufbar",
  t_rUnCallable: "Nicht anrufbar",
  t_rZeroAttempts: "Nicht angerufen",
  t_rStartDate: "Startdatum",
  t_rAgentCount: "Anzahl der Agenten",
  t_rIvrMissed: "Verpasste Anrufe in IVR",
  t_rInboundAgentTotal: "Gesamte eingehende Anrufe an den Agenten",
  t_rInboundSuccessSLA30: "Erfolgreich beantwortet innerhalb von 30 Sekunden",
  t_rInboundSuccessSL120: "Erfolgreich beantwortet innerhalb von 120 Sekunden",
  t_rInboundSuccessSLA120: "Erfolgreich beantwortet nach 120 Sekunden",
  t_rInboundFailSL30: "Verpasst innerhalb von 30 Sekunden",
  t_rInboundFailSLA30: "Verpasst nach 30 Sekunden",
  t_rInboundSuccessSLA30AvgWaitTime: "Durchschnittliche Wartezeit für Anrufe, die nach 30 Sekunden beantwortet wurden",
  t_rInboundSuccessSLA120AvgWaitTime: "Durchschnittliche Wartezeit für Anrufe, die nach 120 Sekunden beantwortet wurden",
  t_rAvgWaitTimeSLA30: "Durchschnittliche Wartezeit für Anrufe, die nach 30 Sekunden verpasst wurden",
  t_rSuccessRate: "Erfolgsquote",
  t_rServiceLevel: "Service-Level",
  t_rTotalConnectedLength: "Gesamte Gesprächsdauer",
  t_rAvgConnectedLength: "Durchschnittliche Gesprächsdauer",
  t_rTotalSuccessCount: "Erfolgreiche Gesprächszählung",
  t_rBaseRemoteNumber: "Telefonnummer",
  t_rTotalCall: "Gesamtanzahl der Anrufe",
  t_rSuccessCall: "Erfolgreiche Anrufe",
  t_rFailCall: "Fehlgeschlagene/Verpasste Anrufe",
  t_rAMD: "Anrufbeantworter",
  t_rTotalCallLength: "Gesamtgesprächsdauer",
  t_rFirstCallTime: "Erste Anrufzeit",
  t_rLastCallTime: "Letzte Anrufzeit",
  t_rAbandon: "Verlassen",
  t_rSaleCall: "Verkaufsgespräch",
  t_rEventTime: "Ereigniszeit",
  t_rEvent: "Ereignis",
  t_rPrevStatus: "Vorheriger Status",
  t_rPrevReason: "Vorheriger Grund",
  t_rPrevStatusLength: "Dauer (Sekunden)",
  t_rInboundSL30: "SL30",
  t_rInboundAnswerRate: "Antwortquote",
  t_rInboundAvgCallTime: "Durchschnittliche Gesprächsdauer für eingehende Anrufe",
  t_rOutboundAvgCallTime: "Durchschnittliche Gesprächsdauer für ausgehende Anrufe",
  t_rInterval: "Intervall",
  t_rInboundTotalCallTime: "Gesamtgesprächszeit für eingehende Anrufe",
  t_rOutboundTotalCallTime: "Gesamtgesprächszeit für ausgehende Anrufe",
  t_rDayName: "Tag",
  t_rInboundSuccessSL30: "Erfolgreich beantwortet innerhalb von 30 Sekunden",
  t_rInboundSuccessSL30Rate: "Antwortquote innerhalb von 30 Sekunden",
  t_rInboundFailSL10: "Verpasst innerhalb von 10 Sekunden",
  t_rInboundSuccessRateSL30: "Antwortquote SL30",
  t_rInboundFailRate: "Fehlerrate für verpasste Anrufe",
  t_rInboundAvgTalkTime: "Durchschnittliche Gesprächszeit für eingehende Anrufe",
  t_rInboundTotalTalkTime: "Gesamtgesprächszeit für eingehende Anrufe",
  t_rInboundAvgAnswerTime: "Durchschnittliche Antwortzeit",
  t_rOutboundTotal: "Gesamtausgehende Anrufe",
  t_rOutboundAvgTalkTime: "Durchschnittliche Gesprächsdauer für ausgehende Anrufe",
  t_rOutboundTotalTalkTime: "Gesamtgesprächsdauer für ausgehende Anrufe",
  t_rCreateDate: "Erstellungsdatum",
  t_rAssignedAgent: "Zugewiesener Agent",
  t_rLine: "Leitung",
  t_rSuccessCalls: "Erfolgreiche Anrufe",
  t_rFailCalls: "Fehlgeschlagene Anrufe",
  t_rHour: "Stunde",
  t_rAgentSentimentPositiveSegmentCount: "Positive Segmentanzahl des Agenten",
  t_rAgentSentimentPositiveScore: "Positiver Score des Agenten",
  t_rAgentSentimentPositiveTokenCount: "Positive Token-Anzahl des Agenten",
  t_rAgentSentimentNegativeSegmentCount: "Negative Segmentanzahl des Agenten",
  t_rAgentSentimentNegativeScore: "Negativer Score des Agenten",
  t_rAgentSentimentNegativeTokenCount: "Negative Token-Anzahl des Agenten",
  t_rAgentSentimentTotalScore: "Gesamt-Sentiment-Score des Agenten",
  t_rAgentSentimentPositiveSegmentRate: "Positives Segmentverhältnis des Agenten (%)",
  t_rAgentSentimentNegativeSegmentRate: "Negatives Segmentverhältnis des Agenten (%)",
  t_rAgentSentimentNotrSegmentRate: "Neutral-Segmentverhältnis des Agenten (%)",
  t_rCustomerSentimentPositiveSegmentCount: "Positive Segmentanzahl des Kunden",
  t_rCustomerSentimentPositiveScore: "Positiver Score des Kunden",
  t_rCustomerSentimentPositiveTokenCount: "Positive Token-Anzahl des Kunden",
  t_rCustomerSentimentNegativeSegmentCount: "Negative Segmentanzahl des Kunden",
  t_rCustomerSentimentNegativeScore: "Negativer Score des Kunden",
  t_rCustomerSentimentNegativeTokenCount: "Negative Token-Anzahl des Kunden",
  t_rCustomerSentimentTotalScore: "Gesamt-Sentiment-Score des Kunden",
  t_rCustomerSentimentPositiveSegmentRate: "Positives Segmentverhältnis des Kunden (%)",
  t_rCustomerSentimentNegativeSegmentRate: "Negatives Segmentverhältnis des Kunden (%)",
  t_rCustomerSentimentNotrSegmentRate: "Neutral-Segmentverhältnis des Kunden (%)",
  t_rTotalDuration: "Gesamte Gesprächsdauer",
  t_rOverlappedSpeechRatio: "Überlappungsquote",
  t_rOverlappedSpeechDuration: "Überlappungsdauer (Sekunden)",
  t_rOverlappedSilenceRatio: "Schweigequote",
  t_rOverlappedSilenceDuration: "Schweigezeit (Sekunden)",
  t_rAgentSpeechRatio: "Sprechquote des Agenten",
  t_rAgentSpeechDuration: "Gesprächsdauer des Agenten (Sekunden)",
  t_rAgentSpeakingRate: "Sprechrate des Agenten (Wörter pro Minute)",
  t_rAgentInterruptCount: "Anzahl der Unterbrechungen durch den Agenten",
  t_rCustomerSpeechRatio: "Sprechquote des Kunden",
  t_rCustomerSpeechDuration: "Gesprächsdauer des Kunden (Sekunden)",
  t_rCustomerSpeakingRate: "Sprechrate des Kunden (Wörter pro Minute)",
  t_rCustomerInterruptCount: "Anzahl der Unterbrechungen durch den Kunden",
  t_rListens: "Angehört",
  t_rTotalEvaluations: "Gesamtanzahl der Bewertungen",
  t_rAutoEvaluations: "Automatische Bewertungen",
  t_rEvaluations: "Manuelle Bewertungen",
  t_rAvgAutoEvaluationsPoint: "Durchschnittspunktzahl bei automatischen Bewertungen",
  t_rAvgEvaluationsPoint: "Durchschnittspunktzahl bei manuellen Bewertungen",
  t_rTotalCalls: "Gesamtanzahl der Anrufe",
  t_rOverlappedSilence: "Überlappte Schweigenanzahl",
  t_rOverlappedSilenceRateByTotalCalls: "Überlappte Schweigenanzahl pro Gesamtanzahl der Anrufe (%)",
  t_rOverlappedSilenceRateBySuccessCalls: "Überlappte Schweigenanzahl pro erfolgreiche Anrufe (%)",
  t_rClosedByAgentCount: "Vom Agenten beendete Anrufe",
  t_rClosedByCustomerCount: "Vom Kunden beendete Anrufe",
  t_rClosedByAgentRateByTotalCalls: "Vom Agenten beendete Anrufe (%)",
  t_rClosedByCustomerRateByTotalCalls: "Vom Kunden beendete Anrufe (%)",
  t_rForbiddenWords: "Verbotene Wörter",
  t_rForbiddenWordsRateByTotalCalls: "Verbotene Wörter pro Gesamtanzahl der Anrufe (%)",
  t_rForbiddenWordsRateBySuccessCalls: "Verbotene Wörter pro erfolgreiche Anrufe (%)",
  t_rAgentNoSpeechCount: "Anzahl der Anrufe, bei denen nur der Kunde sprach",
  t_rAgentNoSpeechRateByTotalCalls: "Anzahl der Anrufe, bei denen nur der Kunde sprach, pro Gesamtanzahl der Anrufe (%)",
  t_rAgentNoSpeechRateBySuccessCalls: "Anzahl der Anrufe, bei denen nur der Kunde sprach, pro erfolgreiche Anrufe (%)",
  t_rAvgAHT: "Durchschnittliche AHT",
  t_rMaxAHT: "Maximale AHT",
  t_rMinAHT: "Minimale AHT",
  t_rDuration: "Dauer",
  t_rListeningDuration: "Abhördauer",
  t_rPlayCount: "Abspielanzahl",
  t_rPauseCount: "Pausenanzahl",
  t_rIsMuted: "Wurde stummgeschaltet?",
  t_rPlayBackChangeCount: "Anzahl der Wiedergabegeschwindigkeitsänderungen",
  t_rPlayBackStartSpeed: "Startgeschwindigkeit",
  t_rPlayBackEndSpeed: "Endgeschwindigkeit",
  t_rManualForm: "Manuelles Formular",
  t_rManualFormPoint: "Punkte für manuelles Formular",
  t_rAutoForm: "Automatisches Formular",
  t_rAutoFormPoint: "Punkte für automatisches Formular",
  t_rQualityNote: "Qualitätsnotiz",
  t_abandonedCampaign: "Abgebrochene Kampagne",
  t_autoRecycle: "Automatische Wiederverwertung",
  t_goalListByAgents: "Ziel-Liste nach Agenten",
  t_addGoal: "Ziel hinzufügen",
  t_addCollection: "Sammlung hinzufügen",
  t_collections: "Sammlungen",
  t_archivedMessages: "Archivierte Nachrichten",
  t_archivetedMessages: "Archivierte Nachrichten",
  t_rQualityDetails: "Qualitätsdetails",
  t_rInboundCallDetails: "Details zu eingehenden Anrufen",
  t_rCallTimeAndCounts: "Gesprächszeit und -anzahl",
  t_rCallDetails: "Anrufdetails",
  t_rClassificationDetails: "Klassifizierungsdetails",
  t_rAgentPerformanceSummary: "Zusammenfassung der Agentenleistung",
  t_rAgentPerformanceSummaryByShift: "Zusammenfassung der Agentenleistung nach Schicht",
  t_rVerdictDetails: "Ergebniscodedetails",
  t_rSmsHistory: "SMS-Verlauf",
  t_rMailHistory: "Mail-Verlauf",
  t_rSecondLineDetails: "Details zur zweiten Leitung/Erweiterung",
  t_rAbandonCalls: "Details zu abgebrochenen Anrufen",
  t_rMissedCalls: "Details zu verpassten Anrufen",
  t_rAgentSummary: "Zusammenfassung der Agenten",
  t_rQueueSL: "Warteschlangen-SL",
  t_rOutOfWork: "Anrufe außerhalb der Arbeitszeit",
  t_rQueueDaily: "Tägliche Anrufanzahl pro Warteschlange",
  t_rAgentDaily: "Tägliche Anrufanzahl pro Agenten",
  t_rQueueHourly: "Stündliche Anrufanzahl pro Warteschlange",
  t_rAgentHourly: "Stündliche Anrufanzahl pro Agenten",
  t_rVerdictByDate: "Ergebniscode nach Datum",
  t_rVerdictByDaily: "Ergebniscode nach Tag",
  t_rVerdictByQueue: "Ergebniscode nach Warteschlange",
  t_rVerdictByAgent: "Ergebniscode nach Agent",
  t_rClassificationByDate: "Klassifizierung nach Datum",
  t_rClassificationByDaily: "Klassifizierung nach Tag",
  t_rClassificationByQueue: "Klassifizierung nach Warteschlange",
  t_rClassificationByAgent: "Klassifizierung nach Agent",
  t_rCallCountByDate: "Anrufanzahl nach Datum",
  t_rDataSummaryByQueue: "Datenzusammenfassung nach Warteschlange",
  t_rProductActionDetails: "Produktaktionsdetails",
  t_rLoginLogout: "Login/Logout",
  t_rLocalNumberSL: "SL nach Nummer",
  t_rCallTimeAndCountByDirection: "Gesprächszeit und -anzahl nach Richtung",
  t_rPozitifCallDetails: "Positiv-Call-Details",
  t_rActivity: "Agentenaktivität",
  t_rDailyCallValues: "Tägliche Anrufwerte",
  t_callrecords: "Anrufaufzeichnungen",
  t_rHourlyCallValues: "Stündliche Anrufwerte",
  t_rAgentPerformanceDaily: "Tägliche Agentenleistung",
  t_rAgentPerformanceMonthly: "Monatliche Agentenleistung",
  t_rAgentPerformanceHourly: "Stündliche Agentenleistung",
  t_rMonthlyCallValues: "Monatliche Anrufwerte",
  t_rCallPerformanceByDate: "Anrufleistung nach Datum",
  t_rDataSummaryByCreateDate: "Datenzusammenfassung nach Erstellungsdatum",
  t_rDataSummaryByAssignedAgent: "Datenzusammenfassung nach zugewiesenem Agenten",
  t_rDataSummaryByDynamicFields: "Datenzusammenfassung nach dynamischen Feldern",
  t_rLinePerformance: "Leitungsleistung",
  t_rInboundCallValuesByHourly: "Stündliche Werte eingehender Anrufe",
  t_rSurveyResult: "Umfrageergebnis",
  t_rEmotionAnalysis: 'Emotionsanalyse / Tag',
  t_rSpeechStatistics: 'Sprachstatistiken / Tag',
  t_analysis: "Analyse",
  t_rQualityByDate: 'Qualität / Tag',
  t_rQualityByAgent: 'Qualität / Agent',
  t_rCallStatisticsByDate: 'Anrufstatistiken / Tag',
  t_rListeningDetails: 'Hördetails',
  t_rProject: 'Projekt',
  t_rIVR: 'IVR',
  t_rInQueue: "In Warteschlange",
  t_rInProgress: "In Bearbeitung",
  t_rShiftStart: 'Schichtbeginn',
  t_rShiftEnd: 'Schichtende',
  t_rShiftDuration: 'Schichtdauer',
  t_rMissingTime: 'Fehlzeit',
  t_rCalculatedShiftDuration: 'Berechnete Schichtdauer',
  t_rCalculatedBreak: 'Berechnete Pausenzeit',
  t_rTotalWorkDuration: 'Gesamtarbeitsdauer',
  t_rLeave: 'Urlaub',
  t_rBreakTime: 'Referenz-Pausenzeit',
  t_rCalculatedWorkTime: 'Berechnete Arbeitszeit',
  t_rWorkDuration: 'Arbeitsdauer',
  t_rTotalWorkDifference: 'Arbeitszeitdifferenz',
  t_reportName: "Berichtsname",
  t_dataCount: "Datenmenge",
  t_expand: "Erweitern",
  t_shrink: "Verkleinern",
  t_darkMode: "Dunkelmodus",
  t_lightMode: "Hellmodus",
  t_alertCategory: "Warnkategorie",
  t_targetCategory: "Zielkategorie",
  t_targetType: "Zieltyp",
  t_none: "Keine",
  t_seeExampleTimePatterns: "Beispielzeitmuster ansehen",
  t_apply: "Anwenden",
  t_callList: 'Zuweisungsliste',
  t_pool: 'Pool',
  t_all: 'Alle',
  t_logs: 'Logs',
  t_totalAttempts: 'Gesamtversuche',
  t_insertDate: 'Letzter Anruf',
  t_actionTime: 'Zu kontaktierende Zeit',
  t_assignDate: 'Zuweisungsdatum',
  t_createDate: 'Erstellungsdatum',
  t_createTime: 'Erstellungszeit',
  t_exAgent: 'Ehemaliger Agent',
  t_addExtraCustomerInfo: 'Zusätzliche Kundeninformationen hinzufügen',
  t_primaryKey: 'Primärer Schlüssel',
  t_secondaryKey: 'Sekundärer Schlüssel',
  t_qVoice: 'Sprachkanal',
  t_qVideo: 'Videokanal',
  t_qText: 'Textkanal',
  t_interactionWeight: 'Kanalgewichtung',
  t_records: 'Aufzeichnungen',
  t_qualityForm: 'Qualitätsformular',
  t_keyWords: 'Schlüsselwörter',
  t_criterions: 'Kriterien',
  t_required: "Erforderlich",
  t_isChatFinishCode: 'Ergebniscode für schriftlichen Kanal',
  t_minWaitInQueue: 'Minimale Wartezeit in der Warteschlange (Sek.)',
  t_maxWaitInQueue: 'Maximale Wartezeit in der Warteschlange (Sek.)',
  t_minDuration: 'Minimale Gesprächsdauer (Sek.)',
  t_maxDuration: 'Maximale Gesprächsdauer (Sek.)',
  t_rIsEvaluated: 'Bewertet',
  t_rEvaluator: 'Bewertender',
  t_rEvaluatedStartTime: 'Bewertungsbeginn',
  t_rEvaluatedEndTime: 'Bewertungsende',
  t_rMinQualityPoint: 'Min. Qualitätspunktzahl',
  t_rMaxQualityPoint: 'Max. Qualitätspunktzahl',
  t_rQualityForm: 'Qualitätsformular',
  t_rIsObjection: 'Einspruch',
  t_multiple: 'Mehrfachauswahl',
  t_question: 'Frage',
  t_minValue: 'Mindestwert',
  t_maxValue: 'Maximalwert',
  t_minTrueCount: 'Minimale Anzahl richtiger Antworten',
  t_ordered: 'Geordnet',
  t_minPoint: 'Min. Punkte',
  t_maxPoint: 'Max. Punkte',
  t_point: 'Punkte',
  t_selected: 'Ausgewählt',
  t_punish: 'Bestrafen',
  t_pointRange: 'Punktebereich',
  t_answer2: 'Antwort',
  t_openInNewWindow: 'In neuem Fenster öffnen',
  t_playbackRate: 'Wiedergabegeschwindigkeit',
  t_isMailSendToAgent: 'E-Mail an den Agenten senden',
  t_httpRequest: 'HTTP-Anfrage',
  t_tts: 'TTS',
  t_stt: 'STT',
  t_beepTone: "Beep-Ton",
  t_model: 'Modell',
  t_whatsapp: 'WhatsApp',
  t_readQR: 'QR-Code lesen',
  t_url: 'URL',
  t_headers: 'Header (JSON)',
  t_body: 'Body (JSON)',
  t_text: 'Text',
  t_useableVariables: 'Verwendbare Variablen',
  t_remoteNumber: 'Telefonnummer',
  t_hideVariableFromAgent: 'Variable vor Agent verbergen',
  t_provider: 'Anbieter',
  t_speaker: 'Sprecher',
  t_isInList: 'In Liste enthalten',
  t_isNotInList: 'Nicht in Liste enthalten',
  t_isContains: 'Enthält',
  t_isNotContains: 'Enthält nicht',
  t_IsConvergent: 'Kongruenz vorhanden',
  t_IsNotConvrgent: 'Keine Kongruenz vorhanden',
  t_changeAllQueues: 'Alle Warteschlangen ändern',
  t_remove: 'Entfernen',
  t_answerToObject: 'Einspruch beantworten',
  t_objectToForm: 'Einspruch einreichen',
  t_objections: 'Einsprüche',
  t_accept: 'Akzeptieren',
  t_rLastObjectingUser: 'Letzter Einspruch durch',
  t_evaluatedDaily: "Heute bewertet",
  t_avgPointDaily: "Täglicher Durchschnittspunktwert",
  t_evaluatedWeekly: "Diese Woche bewertet",
  t_avgPointWeekly: "Wöchentlicher Durchschnittspunktwert",
  t_evaluatedMonthly: "Diesen Monat bewertet",
  t_avgPointMonthly: "Monatlicher Durchschnittspunktwert",
  t_standart: 'Standard',
  t_advanced: 'Erweitert',
  t_quality: 'Qualität',
  t_removeEvaluation: 'Bewertung entfernen',
  t_openOnNewWindow: 'In neuem Fenster öffnen',
  t_undefined: 'Unbestimmt',
  t_callTime: 'Gesprächszeit',
  t_serviceLevel: 'Service-Level',
  t_abandonRate: 'Verlassrate',
  t_callAnswerTime: 'Anrufantwortzeit',
  t_noRouteToDestination: 'Keine Route zum Ziel',
  t_passwordSentToMailAdress: 'Ihr Passwort wurde an Ihre E-Mail-Adresse gesendet.',
  t_noEmailAdress: 'Ihr Konto hat keine E-Mail-Adresse. Bitte wenden Sie sich an Ihren Administrator.',
  t_forgotPassword: 'Passwort vergessen',
  t_saveRecord: 'Audioaufzeichnung speichern',
  t_isHttps: 'HTTPS',
  t_callStatistics: 'Anrufstatistiken',
  t_addtCategory: 'Neue Kategorie hinzufügen',
  t_create: 'Erstellen',
  t_questionCount: 'Anzahl der Fragen',
  t_minPts: 'Min. Punkte',
  t_maxPts: 'Max. Punkte',
  t_questionsAndAnswers: 'Fragen & Antworten',
  t_extensionRoutes: 'Erweiterungsrouten',
  t_evaluatedRecords: 'Bewertete Aufzeichnungen',
  t_alerts: 'Warnungen',
  t_audioEncoding: "Audio-Encoding",
  t_gender: "Geschlecht der Stimme",
  t_token: "Token",
  t_showTCKN: "TCKN anzeigen",
  t_webChat: "Web-Chat",
  t_whatsapp: "WhatsApp",
  t_instagram: "Instagram",
  t_messenger: "Messenger",
  t_informAgent: 'Agenten informieren',
  t_autoCustomerCard: 'Automatische Kundekarte öffnen',
  t_downloadCallRecordings: 'Anrufaufzeichnungen herunterladen',
  t_outboundCallApproval: 'Anrufgenehmigung',
  t_callHistoryDownloadRecording: 'Anrufverlauf - Aufzeichnung herunterladen',
  t_callHistoryListenRecording: 'Anrufverlauf - Aufzeichnung anhören',
  t_dialerReopenTotalAttempts: 'Dialer Gesamtversuche',
  t_dialerReopenDailyAttempts: 'Dialer tägliche Versuche',
  t_dialerClearCallSchedules: 'Dialer - Nicht anstehende Anrufe löschen',
  t_showAllFinishCodes: 'Alle Ergebniscodes anzeigen',
  t_finishcodesofcallsfortoday: "Ergebniscodes für heutige Anrufe",
  t_updateLastFinishCode: 'Letzten Ergebniscode aktualisieren',
  t_showIVRInputs: 'IVR-Eingaben anzeigen',
  t_hidePhoneNumbers: 'Telefonnummern ausblenden',
  t_addExtraData: 'Zusätzliche Daten hinzufügen',
  t_script: 'Skript',
  t_crmCall: 'Anruf',
  t_crmAddCustomer: 'Kunde hinzufügen',
  t_displayAllRecords: 'Alle Aufzeichnungen anzeigen',
  t_editCustomer: 'Kunde bearbeiten',
  t_crmlistenRecording: 'CRM-Aufzeichnung anhören',
  t_showNoteDetails: 'Notizdetails anzeigen',
  t_excelCustomer: 'Excel-Kunden',
  t_excelCustomerByPhone: 'Excel - Telefon',
  t_excelCustomerByProduct: 'Excel - Produkt',
  t_showPhoneDetails: 'Telefondetails anzeigen',
  t_justShowOwnCustomers: 'Nur eigene Kunden anzeigen',
  t_dontSendCustomMessage: 'Keine benutzerdefinierte Nachricht senden',
  t_keywordCategories: "Schlüsselwortkategorien",
  t_keyWord: "Schlüsselwort",
  t_minOccurrence: "Minimales Auftreten",
  t_maxOccurrence: "Maximales Auftreten",
  t_inFirstSeconds: "In den ersten Sekunden",
  t_inLastSeconds: "In den letzten Sekunden",
  t_logicCondition: "Logikbedingung",
  t_reverse: "Umkehren",
  t_and: "Und",
  t_or: "Oder",
  t_customer: "Kunde",
  t_oneOfBoth: "Eines von beiden",
  t_bothOfThem: "Beide",
  t_liveDashboards: 'LIVE-DASHBOARDS',
  t_tools: 'WERKZEUGE',
  t_admin: 'ADMINISTRATOR',
  t_useDatesForEvaluateDate: 'Datumsfilter als Bewertungsdatum verwenden',
  t_menuLineGroups: 'Menü - Leitungsgruppen',
  t_menuDialPlans: 'Menü - Wahlpläne',
  t_menuIVR: 'Menü - IVR',
  t_menuAnnouncements: 'Menü - Ankündigungen',
  t_menuRoutes: 'Menü - Routen',
  t_menuTimeCondition: 'Menü - Zeitbedingung',
  t_menuUsers: 'Menü - Benutzer',
  t_menutExtensionRoutes: 'Menü - Erweiterungsrouten',
  t_menuQueues: 'Menü - Warteschlangen',
  t_menuProjects: 'Menü - Projekte',
  t_menutStatuses: 'Menü - Status',
  t_menutFinishCodes: 'Menü - Ergebniscodes',
  t_menuBlackList: 'Menü - Schwarze Liste',
  t_menuShifts: 'Menü - Schichten',
  t_menuProfiles: 'Menü - Profile',
  t_menuRecordingExport: 'Menü - Audioexport',
  t_menuSms: 'Menü - SMS',
  t_menuReports: 'Menü - Berichte',
  t_menuGoalManagement: 'Menü - Zielmanagement',
  t_menuDialer: 'Menü - Automatisches Wählen',
  t_menuLiveDashboard: 'Menü - Live-Dashboard',
  t_menuPhoneBook: 'Menü - Telefonbuch',
  t_menuCalendar: 'Menü - Kalender',
  t_menuCrm: 'Menü - CRM',
  t_menuDashboard: 'Menü - Dashboard',
  t_menuEvaluatedRecords: 'Menü - Bewertete Aufzeichnungen',
  t_menuSystemManagement: 'Menü - Systemverwaltung',
  t_menuOperationManagement: 'Menü - Betriebsmanagement',
  t_menuIntegrations: 'Menü - Integrationen',
  t_menuSettings: 'Menü - Einstellungen',
  t_menuWallboard: 'Menü - Wallboard',
  t_menuQueueGroup: 'Menü - Warteschlangengruppe',
  t_menuvIVR: 'Menü - Variables IVR',
  t_menuScheduler: 'Menü - Planer',
  t_menuExternalChat: 'Menü - Schriftlicher Kanal',
  t_menuSurvey: 'Menü - Umfrage',
  t_menuEMail: 'Menü - E-Mail',
  t_menuAlerts: 'Menü - Warnungen',
  t_menuQuality: 'Menü - Qualität',
  t_otherAgentList: 'Sonstiges - Agentenliste',
  t_othertAutoCustomerCard: 'Sonstiges - Automatisches Öffnen der Karte',
  t_otherDownloadCallRecordings: 'Sonstiges - Anrufaufzeichnungen herunterladen',
  t_otherOutboundCallApproval: 'Sonstiges - Anrufgenehmigung',
  t_otherCallHistoryDownloadRecording: 'Sonstiges - Anrufverlauf - Aufzeichnung herunterladen',
  t_otherCallHistoryListenRecording: 'Sonstiges - Anrufverlauf - Aufzeichnung anhören',
  t_otherDialerReopenTotalAttempts: 'Sonstiges - Wiedereröffnung nach Gesamtversuchen',
  t_otherDialerReopenDailyAttempts: 'Sonstiges - Wiedereröffnung nach täglichen Versuchen',
  t_otherDialerClearCallSchedules: 'Sonstiges - Nicht anstehende Anrufe löschen',
  t_otherShowAllFinishCodes: 'Sonstiges - Alle Ergebnis Codes anzeigen',
  t_otherUpdateLastFinishCode: 'Sonstiges - Letzten Ergebnis Code aktualisieren',
  t_otherShowIVRInputs: 'Sonstiges - IVR-Eingaben anzeigen',
  t_otherHidePhoneNumbers: 'Sonstiges - Telefonnummern ausblenden',
  t_ChatSouffle: 'Chat - Souffleur',
  t_chatChat: 'Chat - Nachrichten',
  t_chatAnnounce: 'Chat - Ankündigung',
  t_crmFilters: 'CRM - Filter',
  t_crmColumns: 'CRM - Spalten',
  t_crmManagement: 'CRM - Verwaltung',
  t_crmAddExtraData: 'CRM - Zusätzliche Daten hinzufügen',
  t_crmScript: 'CRM - Skript',
  t_crmProducts: 'CRM - Produkte',
  t_crmPhones: 'CRM - Telefone',
  t_crmNotes: 'CRM - Notizen',
  t_crmSms: 'CRM - SMS',
  t_crmSmsSending: 'CRM - SMS Versand',
  t_crmCrmCall: 'CRM - Anruf',
  t_crmCrmAddCustomer: 'CRM - Kunde hinzufügen',
  t_crmDisplayAllRecords: 'CRM - Alle Datensätze anzeigen',
  t_crmEditCustomer: 'CRM - Kunde bearbeiten',
  t_crmCrmlistenRecording: 'CRM - Aufzeichnung anhören',
  t_crmShowNoteDetails: 'CRM - Notizdetails anzeigen',
  t_crmExcelCustomer: 'CRM - Excel-Kundendaten',
  t_crmExcelCustomerByPhone: 'CRM - Excel-Telefondaten',
  t_crmShowPhoneDetails: 'CRM - Telefondetails anzeigen',
  t_rTransferType: "Übertragungsart",
  t_rTransferTarget: "Übertragungsziel",
  t_acw: "ACW-Dauer",
  t_rDialerConnectedToAgent: "Automatischer Anruf verbunden",
  t_rDialerAbandon: "Automatischer Anruf abgebrochen",
  t_rACWLength: "ACW-Dauer",
  t_callAnalize: "Anrufanalyse",
  t_callFinishCode: "Anruf",
  t_writtenChannelFinishCode: "Schriftlicher Kanal",
  t_productFinishCode: "Produkt",
  t_finishCodeType: "Ergebniscodetyp",
  t_speechText: "Gesprochenes Wort",
  t_reportGroup: "Gruppierung",
  t_pleaseSelect: "Bitte wählen",
  t_awaitingInteractionsCount: "Wartende",
  t_descawaitingInteractionsCount: "Gesamtzahl der momentan wartenden Anrufe in den ausgewählten Warteschlangen",
  t_answeredCount: "Beantwortet",
  t_descansweredCount: "Gesamtzahl der beantworteten Anrufe in den ausgewählten Warteschlangen",
  t_abandonedCount: "Abgebrochen",
  t_descabandonedCount: "Gesamtzahl der abgebrochenen Anrufe in den ausgewählten Warteschlangen",
  t_activeInteractionsCount: "Aktive Anrufe",
  t_descactiveInteractionsCount: "Gesamtzahl der aktiven Anrufe in den ausgewählten Warteschlangen",
  t_averageWaitTime: "Durchschnittliche Wartezeit",
  t_descaverageWaitTime: "Durchschnittliche Wartezeit der Anrufe in den ausgewählten Warteschlangen",
  t_callBackSuccess: "Erfolgreicher Rückruf",
  t_desccallBackSuccess: "Anzahl der Rückrufe auf verpasste Anrufe",
  t_firstCallResolutionCount: "Erstkontaktlösung",
  t_descfirstCallResolutionCount: "Anzahl der Anrufe, die beim ersten Kontakt erfolgreich abgeschlossen wurden",
  t_serviceLevel: "Service Level",
  t_descserviceLevel: "Verhältnis der beantworteten Anrufe innerhalb des in den Warteschlangeneinstellungen festgelegten Service Levels zu allen eingehenden Anrufen",
  t_sRLevel: "Erfolgsquote",
  t_descsRLevel: "Verhältnis der erfolgreich abgeschlossenen Anrufe zu allen Anrufen",
  t_averageConnectedTime: "Durchschnittliche Gesprächsdauer",
  t_descaverageConnectedTime: "Durchschnittliche Dauer der durchgeführten Anrufe",
  t_abandonRate: "Abbruchrate",
  t_descabandonRate: "Verhältnis der abgebrochenen Anrufe zu allen Anrufen in den ausgewählten Warteschlangen",
  t_transferQueue: "Warteschlange übertragen",
  t_rAvgQueueLength: "Durchschnittliche Warteschlangenzeit",
  t_crmlistenRecordingJustOwn: "Nur eigene Anrufe anhören",
  t_deleteQualityForm: "Qualitätsformular löschen",
  t_currentPasswordDoesNotMatch: "Aktuelles Passwort stimmt nicht überein",
  t_confirmPasswordDoesNotMatch: "Bestätigtes Passwort stimmt nicht überein",
  t_rRinging: "Klingeln",
  t_rEnterIVR: "In IVR geleitet",
  t_ranswer: "IVR hat geantwortet",
  t_rStartIvr: "IVR gestartet",
  t_rTimeConditionFail: "Zeitbedingung nicht erfüllt",
  t_rTimeConditionSuccess: "Zeitbedingung erfüllt",
  t_rStartIvrMenu: "Menü",
  t_rMenuAttempt: "Kontakt",
  t_rMenuAnnouncement: "Ansage",
  t_rDtmfResult: "DTMF-Ergebnis",
  t_rTaskmenu: "Menü",
  t_rTasksurvey: "Umfrageprozess",
  t_rStartPreSurveyTask: "Umfragebeginn",
  t_rPreSurveyAttempt: "Kontakt",
  t_rPreSurveyAnnouncement: "Umfrageansage",
  t_rPreSurveyResults: "Umfrageergebnisse",
  t_rTaskcondition: "Bedingung",
  t_rConditionFail: "Bedingung nicht erfüllt",
  t_rTaskvariable: "Variable",
  t_rStartVariableTask: " ",
  t_rTaskannounce: "Ansage",
  t_rVariableAttempt: "Kontakt",
  t_rVariableAnnouncement: "Variablenansage",
  t_rTaskhttp: "HTTP-Anfrage gesendet",
  t_rVariableFailed: "Variablenfehler",
  t_rFailedTaskvariable: "Fehler bei der Variablenverarbeitung",
  t_rMenuFailed: "Menüfehler",
  t_rFailedTaskmenu: "Fehler bei der Menüverarbeitung",
  t_rConditionError: "Bedingungsfehler",
  t_rFailedTaskcondition: "Fehler bei der Bedingungsverarbeitung",
  t_rTimeConditionError: "Zeitbedingungsfehler",
  t_rIvrFailed: "IVR beendet",
  t_rFailedInboundCall: "Anruf beendet",
  t_rFinalized: "Abgeschlossen",
  t_rJoinedQueue: "Warteschlange beigetreten",
  t_rAssigned: "Zugewiesen",
  t_rAccepted: "Akzeptiert",
  t_rPickedUp: "Abgenommen",
  t_rRemoteFailed: "Fehler bei der Gegenstelle",
  t_rConditionSuccess: "Bedingung erfüllt",
  t_rTaskannounce: "Ansage",
  t_rTaskqueue: "Warteschlange",
  t_roriginate: "Anruf",
  t_rcall_target: "Anrufziel",
  t_rnew_bridge: "Neuer Kanal",
  t_rbridge_created: "Kanal erstellt",
  t_rcalls_bridged: "Kanäle verbunden",
  t_rcall_transferred: "Übertragen",
  t_rUnassigned: "Zuweisung zurückgenommen",
  t_rAbandoned: "Abgebrochen",
  t_rRejected: "Abgelehnt",
  t_rNoAnswer: "Keine Antwort",
  t_rFailedTaskannounce: " ",
  t_rTransferAssigned: "Zur Übertragung zugewiesen",
  t_rTransferAccepted: "Übertragung akzeptiert",
  t_rnull: " ",
  t_rTaskivr: "IVR",
  t_rTasktransfer: "Übertragung",
  t_rEndCall: "Anruf beendet",
  t_rFailedTaskhttp: "HTTP-Fehler",
  t_rTotalLocal: "Gesamtanzahl lokaler Beendigungen",
  t_rInboundLocal: "Eingehende lokale Beendigungen",
  t_rOutboundLocal: "Ausgehende lokale Beendigungen",
  t_rAvgDialingTime: "Durchschnittliche Wählzeit",
  t_rTotalInCallTime: "Gesamte Anrufzeit",
  t_rTotalStatusTime: "Gesamte Statuszeit",
  t_rTotalHoldCount: "Gesamte Halteanzahl",
  t_rTotalHoldTime: "Gesamte Haltezeit",
  t_rAvgHandleTime: "Durchschnittliche Bearbeitungszeit",
  t_rLogoutCount: "Anzahl der Logouts",
  t_rProductivityTime: "Produktivitätszeit",
  t_rBreakCategoryTime: "Verwendete Pausenzeit",
  t_rAvgAlertTime: "Durchschnittliche Klingelzeit",
  t_addFile: "Datei hinzufügen",
  t_appointment: "Termin",
  t_appointments: "Termine",
  t_second: "Sekunde",
  t_notAnsweringCallDesc2: "Sie sind seit 1 Minute im Status 'Not Answering'. Bitte wechseln Sie zum Status 'Available', um fortzufahren.",
  t_notAnsweringCallDesc: "Sie haben den Anruf nicht beantwortet. Bitte wechseln Sie zum Status 'Available', um fortzufahren.",
  t_notAnsweringCall: "Nicht beantworteter Anruf",
  t_timeFormat: "Zeitformat",
  t_callGroup: "Anrufgruppe",
  t_pickupGroup: "Abholgruppe",
  t_fodlerType: "Ordnerformat",
  t_conferenceCallAccepted: "Konferenzanruf verbunden",
  t_conferenceCallHangup: "Konferenzanruf beendet",
  t_conferenceCallDeclined: "Konferenzanruf abgelehnt",
  t_conferenceCalling: "Konferenzanruf wird getätigt",
  t_agentListDetails: "Agentenlisten-Details",
  t_rAgentGroup: "Agentengruppe",
  t_new: "Neu",
  t_recordsActivityLog: "Aufzeichnungsprotokoll",
  t_isAutoForm: "Automatisches Formular",
  t_timestamp: "Zeitstempel",
  t_verificationCode: "Bestätigungscode",
  t_verificationCodeDesc: "Bitte geben Sie den 6-stelligen Bestätigungscode ein",
  t_verify: "Bestätigen",
  t_phone: "Telefon",
  t_downloadVoyceVoiceConverter: "Voyce Sprachkonverter herunterladen",
  t_position: "Position",
  t_ivrBreakOut: "IVR-Ausstieg",
  t_positionAndIvrBreakOut: "Position und IVR-Ausstieg",
  t_ivrBreakOutTarget: "IVR-Ausstiegsziel",
  t_displayAllCalendar: "Alle Kalender anzeigen",
  t_appointmentAssignmentDesc: "Neue Termine wurden Ihnen zugewiesen. Bitte überprüfen Sie Ihren Kalender.",
  t_appointmentAssignment: "Terminzuweisung",
  t_displayCalls: 'Anrufe anzeigen',
  t_showUsedAreas: "Verwendete Bereiche anzeigen",
  t_ftp: "FTP",
  t_voiceExport: "Sprachausgabe",
  t_putFtp: "An FTP senden",
  t_remotePath: "Remote-Dateipfad",
  t_passive: "Passiv",
  t_passives: "Passive",
  t_state: "Status",
  t_frequentlyAskedQuestions: "Häufig gestellte Fragen",
  t_helpCenter: "Hilfezentrum",
  t_contactUs: "Kontaktieren Sie uns",
  t_isSecure: "Sichere Verbindung",
  t_rejectUnauthorized: "Unbefugte ablehnen",
  t_customMetaData: "Benutzerdefinierte Metadaten",
  t_metaDataEndpoint: "Metadaten-Endpunkt",
  t_metaDataRemotePath: "Metadaten-Remote-Pfad",
  t_putAsFolder: "Als Ordner senden",
  t_isDefault: "Standard",
  t_rSLDuration: "SL-Dauer",
  t_twoFactorAuthentication: "Zwei-Faktor-Authentifizierung",
  t_sentToPhoneNumber: "an die Telefonnummer gesendet",
  t_sentToEmailAdress: "an die E-Mail-Adresse gesendet",
  t_verifyYourIdentity: "Verifizieren Sie Ihre Identität, indem Sie den Bestätigungscode eingeben",
  t_remainingTime: "Verbleibende Zeit",
  t_sec: "Sek",
  t_donthaveAccess: "Haben Sie keinen Zugriff auf dieses Konto?",
  t_verificationCode: "Bestätigungscode",
  t_sendAsMail: "als E-Mail senden",
  t_sendAsSms: "als SMS senden",
  t_verify: "Verifizieren",
  t_closeProduct: "Produkt schließen",
  t_setPassiveWhenNoProduct: 'Aufzeichnungen ohne Produkt inaktiv setzen',
  t_sendHttpRequest: "HTTP-Anfrage senden",
  t_month2: "Monat (ohne Null)",
  t_day2: "Tag (ohne Null)",
  t_shifts: "Schichten",
  t_shift: "Schicht",
  t_missingTime: 'Fehlende Zeit',
  t_isAnnualLeave: 'Jahresurlaub',
  t_paidLeave: 'Bezahlter Urlaub',
  t_unpaidLeave: 'Unbezahlter Urlaub',
  t_isOwner: 'Eigen',
  t_owner: 'Eigentümer',
  t_thirdParty: 'Dritte Partei',
  t_breakTimeFraud: 'Pausenüberschreitung',
  t_dontControlLastTenDigit: 'Die letzten 10 Ziffern nicht prüfen',
  t_bank: 'Bank',
  t_dontChangeSurvey: 'Umfragestatus nicht ändern',
  t_finishCodeBreakdown: 'Ergebnis Code Aufschlüsselung',
  t_displayPassiveRecords: 'Passive Aufzeichnungen anzeigen',
  t_newAssets: "Neue Vermögenswerte",
  t_reached: "Erreicht",
  t_reachedDate: "Erreicht am",
  t_dontGetNoQueueRecords: "Aufzeichnungen ohne Warteschlange nicht abrufen",
  t_agentHaveStaticQueueButDontHaveProjectPermission: 'Der Agent hat eine statische Warteschlange, aber keine Berechtigung für das Projekt.',
  t_autoRecall: 'Automatischer Rückruf',
  t_autoRecallDay: 'Tage danach',
  t_rDateName: "Tag",
  t_rWeekOfMonth: "Woche des Monats",
  t_rGivenBreakTime: "Gewährte Pausenzeit",
  t_rOfflineTime: "Offline-Zeit",
  t_rMonth: "Monat",
  t_rBreakTimeDifference: "Pausenzeitdifferenz",
  t_copyFromTheEditor: "Vom Editor kopieren",
  t_pasteIntoTheEditor: "In den Editor einfügen",
  t_duplicate: "Duplizieren",
  t_identifier: "Identifikationsnummer",
  t_undeafen: 'Ton wiederherstellen',
  t_deafen: 'Ton stummschalten',
  t_canTransfer: 'Übertragbar',
  t_wordDistance: 'Wortabstand',
  t_segmentDistance: 'Segmentabstand',
  t_maxWordGroupCount: 'Max. Wortgruppe',
  t_trendAnalyze: 'Trendanalyse',
  t_realTimeTranscript: 'Echtzeit-Transkript',
  t_snapshots: "Snapshots",
  t_snapshotDate: "Snapshot-Datum",
  t_firstCallDate: "Erster Anruf",
  t_lastCallDate: "Letzter Anruf",
  t_callCount: "Anrufanzahl",
  t_density: "Dichte",
  t_avarageDensity: "Durchschnittliche Dichte",
  t_displayDensityStatistics: 'Dichte-Statistik anzeigen',
  t_hideFromAgent: 'Vor dem Agenten verbergen',
  t_customerNo: "Kundennummer",
  t_customerName: "Name und Nachname",
  t_customerIdentifier: "Identifikationsnummer",
  t_reached: "Erreicht",
  t_reachedDate: "Erreicht am",
  t_productNo: "Produktnummer",
  t_artificialIntelligence: "Künstliche Intelligenz",
  t_speakers: "Sprecher",
  t_oneTime: "Einmalig",
  t_scheduled: "Geplant",
  t_automatic: "Automatisch",
  t_periodTime: "Zeitraum",
  t_prompt: "Eingabeaufforderung",
  t_aiCallResult: "AI-Anrufergebnis",
  t_exceptions: "Ausnahmen",
}
